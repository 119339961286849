/* global Sentry */

import FetchError from 'fetch-error';

export function jsonOrThrow(response: Response): Promise<any> {
  if (response.status >= 400) {
    throw new FetchError(response.status, response.statusText, { response });
  }

  return response.json();
}

export function handleError(message: string, err: Error) {
  console.error(err);
  // @ts-ignore TS2304
  if (Sentry) {
    // @ts-ignore TS2304
    Sentry.captureException(err);
  }
}
