import { FunctionComponent } from 'react';

interface PartialScreenLoaderProps {
  loadingText?: string;
}

const PartialScreenLoader: FunctionComponent<PartialScreenLoaderProps> = ({ loadingText }) => (
  <div className="partial-screen-loader">
    <div className="partial-screen-loader__inner">
      {loadingText && <p>{loadingText}</p>}
      <div className="ball-pulse">
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);

export default PartialScreenLoader;
