import { FunctionComponent } from 'react';
import cx from 'classnames';
import { SpotlightHeaderViewModel } from '../../models/SpotlightHeaderViewModel';
import SpotlightHeader from './SpotlightHeader';
import Text from '../atoms/text/Text';
import { asEditable } from '../../helpers/sitecore';
import ImageHtml from '../atoms/imageHtml/ImageHtml';

const SpotlightHeaderCountry: FunctionComponent<SpotlightHeaderViewModel> = props => {
  const { componentTitle, componentSubTitle, componentShortText, relatedTourleaders } = props;
  const hasTourleaders = relatedTourleaders && relatedTourleaders.length > 0;

  return (
    <SpotlightHeader {...props} className="countryarea">
      <div className={cx('headercountryinner', hasTourleaders && 'headercountryinner--withtourleaders')}>
        <Text {...componentTitle} />
        <h2 dangerouslySetInnerHTML={asEditable(componentSubTitle)} />
        <p className="headercountryinner__text" dangerouslySetInnerHTML={asEditable(componentShortText)} />
        {hasTourleaders && relatedTourleaders && (
          <div className="countryguides">
            {relatedTourleaders.map(tourleader => {
              return (
                <div className="countryguide" key={tourleader.id}>
                  {tourleader.componentImageHtml && <ImageHtml {...tourleader.componentImageHtml} />}
                  <p className="countryguide__name">{tourleader.componentTitle}</p>
                  <p className="countryguide__role">{tourleader.role}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </SpotlightHeader>
  );
};

export default SpotlightHeaderCountry;
