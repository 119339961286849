import { FunctionComponent } from 'react';
import { FieldRenderProps } from 'react-final-form';
import FormItem from './atoms/FormItem';
import ValidationError from './atoms/ValidationError';

export interface TextAreaProps extends FieldRenderProps<string> {
  label?: string;
  className?: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
}

const TextArea: FunctionComponent<TextAreaProps> = ({ input, meta, ...rest }) => (
  <FormItem meta={meta} {...rest}>
    {rest.label && (
      <label htmlFor={input.name} className={rest.required ? 'required' : ''}>
        {rest.label}:
      </label>
    )}
    <div className="form-item-value">
      <textarea {...input} required={rest.required} rows={10} cols={80} placeholder={rest.placeholder} />
    </div>
    <ValidationError meta={meta} />
  </FormItem>
);

export default TextArea;
