import { FunctionComponent } from 'react';
import { WidgetViewModel } from '../../models/WidgetViewModel';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import Text from '../atoms/text/Text';

const ArticleWidget: FunctionComponent<WidgetViewModel> = ({
  componentImage,
  componentTitle,
  componentText,
  componentLink,
}) => {
  return (
    <div className="articleblock">
      <a href={componentLink ? componentLink.url : ''} target={componentLink ? componentLink.target : ''}>
        {componentImage && (
          <div className="articleimg">
            <ImageHtml {...componentImage} class="article-scaled-image" />
          </div>
        )}
        <Text {...componentTitle} className="heading" />
        <div
          className="articletext"
          dangerouslySetInnerHTML={{
            __html: componentText ? componentText : '',
          }}
        />
      </a>
    </div>
  );
};

export default ArticleWidget;
