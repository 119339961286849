import { FunctionComponent } from 'react';
import { withTypes } from 'react-final-form';
import createDecorator from 'final-form-focus';
import SubmitButton from '../atoms/form/SubmitButton';
import ErrorMessage from '../atoms/message/ErrorMessage';
import { submitForm } from '../../helpers/form';
import { CustomerLogoutInputModel } from '../../models/CustomerLogoutInputModel';
import { CustomerLogoutViewModel } from '../../models/CustomerLogoutViewModel';

const decorators = [createDecorator()];

const { Form } = withTypes<CustomerLogoutInputModel>();

const initialValues: CustomerLogoutInputModel = {};

const CustomerLogout: FunctionComponent<CustomerLogoutViewModel> = (props: CustomerLogoutViewModel) => (
  <Form
    initialValues={initialValues}
    onSubmit={submitForm(props, initialValues)}
    decorators={decorators}
    subscription={{
      pristine: true,
      invalid: true,
      submitFailed: true,
      submitError: true,
      submitErrors: true,
      submitSucceeded: true,
      submitting: true,
    }}
    render={({ handleSubmit, pristine, invalid, submitFailed, submitError, submitting }) => {
      return (
        <form onSubmit={handleSubmit} className="customerlogout" noValidate={true}>
          {!submitting && invalid && submitFailed && <ErrorMessage>{props.submitErrorMessage}</ErrorMessage>}
          {!submitting && submitError && <ErrorMessage scrollIntoView={true}>{submitError}</ErrorMessage>}
          <SubmitButton disabled={pristine || invalid} buttonText={props.buttonText} />
        </form>
      );
    }}
  />
);

export default CustomerLogout;
