import { Component, MouseEvent } from 'react';
import cx from 'classnames';
import { MinisiteHomepageViewModel } from '../../models/MinisiteHomepageViewModel';
import SearchResult from '../searchResult/SearchResult';
import Frame from '../frame/Frame';
import { Placeholder } from '../structure/Placeholder';

enum MinisiteHomepageTab {
  Holidays = 'holidays',
  BuildYourOwn = 'buildyourown',
}

enum TabState {
  Unloaded = 'unloaded',
  Active = 'active',
  Hidden = 'hidden',
  Disabled = 'disabled',
}

interface MinisiteHomepageState {
  hasTabs: boolean;
  tabstate: { [tabname in MinisiteHomepageTab]: TabState };
}

class MinisiteHomepage extends Component<MinisiteHomepageViewModel, MinisiteHomepageState> {
  constructor(props: MinisiteHomepageViewModel) {
    super(props);

    const hasHolidays =
      (props.sellableProducts && props.sellableProducts.length > 0) ||
      (props.placeholders && props.placeholders.length > 0);

    this.state = {
      hasTabs: !!hasHolidays,
      tabstate: {
        holidays: hasHolidays ? TabState.Active : TabState.Disabled,
        buildyourown: hasHolidays ? TabState.Unloaded : TabState.Active,
      },
    };
  }

  clickTab = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const href = event.currentTarget.href;
    const nextTab = href.substr(href.indexOf('#') + 1) as MinisiteHomepageTab;
    this.setState(({ tabstate }) => {
      const newTabState = {
        ...tabstate,
      };

      for (const tab in newTabState) {
        if (Object.prototype.hasOwnProperty.call(newTabState, tab)) {
          if (tab === nextTab) {
            newTabState[tab] = TabState.Active;
          } else if (newTabState[tab] === TabState.Active) {
            newTabState[tab] = TabState.Hidden;
          }
        }
      }

      return {
        tabstate: newTabState,
      };
    });
  };

  render() {
    const { sellableProducts, frame, placeholders } = this.props;
    const { hasTabs, tabstate } = this.state;

    return (
      <div className="minisite__homepage">
        {hasTabs && (
          <nav className="minisite__navigation">
            <a
              className={cx(
                tabstate[MinisiteHomepageTab.Holidays] === TabState.Active && 'minisite__tablink--active',
                'minisite__tablink'
              )}
              href={`#${MinisiteHomepageTab.Holidays}`}
              onClick={this.clickTab}
              role="tab"
            >
              Reizen uit de folder
            </a>
            <a
              className={cx(
                tabstate[MinisiteHomepageTab.BuildYourOwn] === TabState.Active && 'minisite__tablink--active',
                'minisite__tablink'
              )}
              href={`#${MinisiteHomepageTab.BuildYourOwn}`}
              onClick={this.clickTab}
              role="tab"
            >
              Camperreizen op maat
            </a>
          </nav>
        )}

        {tabstate[MinisiteHomepageTab.Holidays] !== TabState.Disabled &&
          tabstate[MinisiteHomepageTab.Holidays] !== TabState.Unloaded && (
            <div
              id={MinisiteHomepageTab.Holidays}
              className={cx(
                'minisite__tab',
                tabstate[MinisiteHomepageTab.Holidays] === TabState.Active
                  ? 'minisite__tab--active'
                  : 'minisite__tab--hidden'
              )}
              aria-hidden={tabstate[MinisiteHomepageTab.Holidays] !== TabState.Active}
              role="tabpanel"
            >
              {sellableProducts && sellableProducts.map(product => <SearchResult key={product.id} {...product} />)}
              {placeholders && <Placeholder placeholders={placeholders} placeholderIndex={0} />}
            </div>
          )}

        {tabstate[MinisiteHomepageTab.BuildYourOwn] !== TabState.Disabled &&
          tabstate[MinisiteHomepageTab.BuildYourOwn] !== TabState.Unloaded && (
            <div
              id={MinisiteHomepageTab.BuildYourOwn}
              className={cx(
                'minisite__tab',
                tabstate[MinisiteHomepageTab.BuildYourOwn] === TabState.Active
                  ? 'minisite__tab--active'
                  : 'minisite__tab--hidden'
              )}
              aria-hidden={tabstate[MinisiteHomepageTab.BuildYourOwn] !== TabState.Active}
              role="tabpanel"
            >
              <Frame {...frame} />
            </div>
          )}
      </div>
    );
  }
}

export default MinisiteHomepage;
