import { FunctionComponent } from 'react';
import { HtmlBlockViewModel } from '../../models/HtmlBlockViewModel';
import { asEditable } from '../../helpers/sitecore';

const HtmlBlock: FunctionComponent<HtmlBlockViewModel> = ({ html, isInEditMode }) =>
  isInEditMode ? (
    <div className="edit-mode-text">
      <p>Bewerk dit component via Sitecore.</p>
    </div>
  ) : (
    <div dangerouslySetInnerHTML={asEditable(html)} />
  );

export default HtmlBlock;
