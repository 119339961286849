import { Component } from 'react';
import Range from 'rc-slider';
import { RangeFacetViewModel } from '../../../../models/RangeFacetViewModel';
import { ActiveFilter } from '../../../holidayResultContainer/HolidayResultContainer';

interface RangeSliderProps extends RangeFacetViewModel {
  rangeSliderChanged?: (activeFilter: ActiveFilter) => void;
  activeValues?: string;
}

interface RangeSliderState {
  value: number[];
}

export default class RangeSlider extends Component<RangeSliderProps, RangeSliderState> {
  constructor(props: RangeSliderProps) {
    super(props);

    this.state = {
      value: this.getRangeValues(props.activeValues),
    };

    this.onAfterChange = this.onAfterChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps: RangeSliderProps) {
    if (nextProps.activeValues !== this.props.activeValues) {
      this.setState({
        value: this.getRangeValues(nextProps.activeValues),
      });
    }
  }

  onAfterChange(value: number[] | number) {
    if (!Array.isArray(value)) {
      return;
    }
    if (!this.props.rangeSliderChanged || !this.props.id) {
      return;
    }

    this.props.rangeSliderChanged({
      facetId: this.props.id,
      activeValueId: `${value[0]}-range-${value[1]}`,
    });
  }

  onChange(value: number[] | number) {
    if (Array.isArray(value)) {
      this.setState({
        value,
      });
    }
  }

  getRangeValues(activeValues: string | undefined) {
    let finalActiveValues = [this.props.lowestValue as number, this.props.highestValue as number];

    if (this.props.selectedLowestValue && this.props.selectedHighestValue) {
      finalActiveValues = [this.props.selectedLowestValue, this.props.selectedHighestValue];
    }

    if (!activeValues) {
      return finalActiveValues;
    }

    let activeValuesParsed: string[] | number[] = activeValues.split('-range-');

    if (activeValuesParsed.length > 0) {
      activeValuesParsed = activeValuesParsed.map(value => parseInt(value, 10));

      if (activeValuesParsed[0]) {
        finalActiveValues[0] = activeValuesParsed[0];
      }

      if (activeValuesParsed[1]) {
        finalActiveValues[1] = activeValuesParsed[1];
      }
    }

    return finalActiveValues;
  }

  render() {
    const { title, lowestValue, highestValue, id } = this.props;
    const activeValues = this.getRangeValues(this.props.activeValues);

    let activeSuffix = '';
    let activePrefix = '';
    let classValue = '';

    if (id === 'price') {
      activePrefix = '€';
      classValue = this.state.value[1] < 1000 ? 'small-price' : '';
    }

    if (id === 'duration') {
      activeSuffix = 'dagen';
      classValue = this.state.value[1] >= 10 ? 'small' : 'extra-small';
    }

    return (
      <div className="range-slider">
        <div className="filtertoggle noarrow">{title}</div>

        <div className="range-slider__wrap">
          <Range
            range={true}
            allowCross={false}
            min={lowestValue}
            max={highestValue}
            value={this.state.value}
            defaultValue={activeValues}
            onChange={this.onChange}
            onAfterChange={this.onAfterChange}
          />
        </div>

        <div className="minmax">
          <div>
            <p className="prefix">Van {activePrefix}</p>
            <div className={`pricemin ${classValue}`}>{this.state.value[0]}</div>
          </div>
          <div>
            <p className="prefix no-margin">Tot {activePrefix}</p>
            <div className={`pricemax ${classValue}`}>{this.state.value[1]}</div> {activeSuffix}
          </div>
        </div>
      </div>
    );
  }
}
