import { Component } from 'react';
import * as isEqual from 'lodash.isequal';
import { ContentSearchContainerViewModel } from '../../models/ContentSearchContainerViewModel';
import FilterContainer from '../filters/filterContainer/FilterContainer';
import MultiSelectFilter from '../filters/filterTypes/multiSelectFilter/MultiSelectFilter';
import Pagination from '../pagination/Pagination';
import { ActiveFilter } from '../holidayResultContainer/HolidayResultContainer';
import { ResultFacet } from '../../models/ResultFacet';
import SearchResult from '../searchResult/SearchResult';
import {
  buildActiveFiltersFromFacets,
  createQuerystring,
  getSearchParamsFromHistory,
  removeRedundantActiveFilters,
  SearchParams,
  setSearchParamsOnHistoryState,
} from '../../helpers/search';
import FullScreenLoader from '../atoms/loader/FullScreenLoader';
import SearchResultBlogPost from '../searchResult/SearchResultBlogPost';
import Sorter from '../sorter/Sorter';
import { SearchResultItemViewModel } from '../../models/SearchResultItemViewModel';
import SearchBox from '../atoms/form/SearchBox';
import { Placeholder } from '../structure/Placeholder';
import { handleError, jsonOrThrow } from '../../helpers/fetch';
import { ReactPlaceholder } from '../../models/ReactPlaceholder';

interface ContentSearchContainerState extends SearchParams {
  loading: boolean;
  countryFacet?: ResultFacet;
  areaFacet?: ResultFacet;
  categoryFacet?: ResultFacet;
  results: SearchResultItemViewModel[];
  url: string;
  totalResults: number;
  noResultsContent?: ReactPlaceholder[];
}

class ContentSearchContainer extends Component<ContentSearchContainerViewModel, ContentSearchContainerState> {
  loadingTimeoutId!: number;
  loadingTimeout = 200;

  constructor(props: ContentSearchContainerViewModel) {
    super(props);

    const facets: ResultFacet[] = [];
    if (props.countryFacet) {
      facets.push(props.countryFacet);
    }
    if (props.areaFacet) {
      facets.push(props.areaFacet);
    }
    if (props.categoryFacet) {
      facets.push(props.categoryFacet);
    }

    this.state = {
      loading: false,
      activeFilters: buildActiveFiltersFromFacets(facets, [], props.textSearchField && props.textSearchField.value),
      activePage: props.page,
      countryFacet: props.countryFacet,
      areaFacet: props.areaFacet,
      categoryFacet: props.categoryFacet,
      results: props.searchResults,
      url: props.url,
      totalResults: props.totalResults || 0,
      sort: props.sort,
      noResultsContent: props.noResultsContent,
    };

    this.handleActivePageChange = this.handleActivePageChange.bind(this);
    this.handleMultiSelectFilterChange = this.handleMultiSelectFilterChange.bind(this);
    this.handlePopstate = this.handlePopstate.bind(this);
    this.fetchResults = this.fetchResults.bind(this);
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handlePopstate);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopstate);
  }

  handlePopstate(e: PopStateEvent) {
    const searchParams = getSearchParamsFromHistory();
    this.setSearchState(
      {
        ...searchParams,
      },
      false
    );
  }

  showLoader() {
    this.setState({
      loading: true,
    });
  }

  fetchResults(addHistoryEntry: boolean) {
    const { activeFilters, activePage, sort } = this.state;

    const queryString = createQuerystring({
      currentPageId: this.props.currentPageId,
      activeFilters: activeFilters,
      activePage: activePage,
      sort: sort,
    });

    this.loadingTimeoutId = window.setTimeout(() => {
      this.showLoader();
    }, this.loadingTimeout);

    fetch(`/travelhome/api/contentsearch?${queryString}`)
      .then(jsonOrThrow)
      .then((results: ContentSearchContainerViewModel) => {
        clearTimeout(this.loadingTimeoutId);

        this.setState((prevState: ContentSearchContainerState) => {
          // If the result removes filters that are active, remove these
          const facets: ResultFacet[] = [];
          if (results.countryFacet) {
            facets.push(results.countryFacet);
          }
          if (results.areaFacet) {
            facets.push(results.areaFacet);
          }
          if (results.categoryFacet) {
            facets.push(results.categoryFacet);
          }

          const cleanedUpActiveFilters = removeRedundantActiveFilters(prevState.activeFilters, facets);

          if (!isEqual(results.url, prevState.url) && addHistoryEntry) {
            setSearchParamsOnHistoryState(
              {
                activeFilters: cleanedUpActiveFilters,
                activePage: prevState.activePage,
                sort: prevState.sort,
              },
              results.url
            );
          }

          return {
            url: results.url,
            results: results.searchResults,
            activeFilters: cleanedUpActiveFilters,
            loading: false,
            countryFacet: results.countryFacet,
            areaFacet: results.areaFacet,
            categoryFacet: results.categoryFacet,
            activePage: results.page,
            totalResults: results.totalResults || 0,
            noResultsContent: results.noResultsContent,
          };
        });
      })
      .catch(err => {
        handleError('Error while fetching results', err);

        this.setState({
          loading: false,
        });
      });
  }

  handleMultiSelectFilterChange(activeFilter: ActiveFilter) {
    let activeValues = this.state.activeFilters[activeFilter.facetId]
      ? this.state.activeFilters[activeFilter.facetId].split(',')
      : [];

    if (activeValues.find(activeValue => activeValue === activeFilter.activeValueId)) {
      activeValues = activeValues.filter(activeValue => activeValue !== activeFilter.activeValueId);
    } else {
      activeValues.push(activeFilter.activeValueId);
    }

    this.setSearchState(
      {
        activeFilters: {
          ...this.state.activeFilters,
          [activeFilter.facetId]: activeValues.join(','),
        },
        activePage: 1,
      },
      true
    );
  }

  handleActivePageChange(activePage: number) {
    if (activePage <= 0) {
      return;
    }

    this.setSearchState({ activePage }, true);
  }

  handleSortChange = (sortOption: string) => {
    this.setSearchState(
      {
        sort: sortOption,
        activePage: 1,
      },
      true
    );
  };

  handleFullTextSubmit = (value: string) => {
    this.setSearchState(
      {
        activeFilters: {
          ...this.state.activeFilters,
          t: value,
        },
        activePage: 1,
      },
      true
    );
  };

  setSearchState<K extends keyof ContentSearchContainerState>(
    newState: Pick<ContentSearchContainerState, K> | ContentSearchContainerState,
    addHistoryEntry: boolean
  ) {
    // @ts-ignore TS2345
    this.setState(newState, () => {
      this.fetchResults(addHistoryEntry);
    });
  }

  render() {
    const {
      activePage,
      countryFacet,
      areaFacet,
      categoryFacet,
      results,
      totalResults,
      loading,
      url,
      sort,
      activeFilters,
      noResultsContent,
    } = this.state;
    const { numberOfResultsPerPage, loadingText, sortOptions, textSearchField } = this.props;

    const showFilters = textSearchField || categoryFacet || areaFacet || countryFacet;

    return (
      <div className="boxed">
        {loading && <FullScreenLoader loadingText={loadingText} />}
        {showFilters && (
          <FilterContainer>
            {textSearchField && (
              <SearchBox
                placeholderText={textSearchField.placeholderText}
                onSubmit={this.handleFullTextSubmit}
                id="facetsearch"
                value={activeFilters.t}
                collapsible={false}
              />
            )}
            {categoryFacet && (
              <MultiSelectFilter
                {...categoryFacet}
                sortEmptyItemsLast={false}
                selectFilterChanged={this.handleMultiSelectFilterChange}
                disableExpansion={true}
              />
            )}
            {countryFacet && (
              <MultiSelectFilter
                {...countryFacet}
                sortEmptyItemsLast={false}
                selectFilterChanged={this.handleMultiSelectFilterChange}
                disableExpansion={true}
              />
            )}
            {areaFacet && (
              <MultiSelectFilter
                {...areaFacet}
                sortEmptyItemsLast={false}
                selectFilterChanged={this.handleMultiSelectFilterChange}
                disableExpansion={true}
              />
            )}
          </FilterContainer>
        )}

        <div className="results">
          <Pagination
            totalResults={totalResults}
            size={numberOfResultsPerPage}
            activePage={activePage}
            onActivePageChange={this.handleActivePageChange}
            url={url}
          />

          {sortOptions && <Sorter options={sortOptions} onSortChange={this.handleSortChange} activeOption={sort} />}

          {results.map(item => {
            if (item.blogPost) {
              return <SearchResultBlogPost {...item} key={item.id} />;
            }

            return <SearchResult {...item} key={item.id} />;
          })}

          <Pagination
            totalResults={totalResults}
            size={numberOfResultsPerPage}
            activePage={activePage}
            onActivePageChange={this.handleActivePageChange}
            url={url}
          />

          {/* The Travelhome-No-SearchResults-Content / Travelhome-No-Input-Content Placeholder */}
          {noResultsContent && (
            <div className="contentsearch__noresults">
              <Placeholder placeholders={noResultsContent} placeholderIndex={0} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ContentSearchContainer;
