import { FunctionComponent } from 'react';
import StickyBox from 'react-sticky-box';
import { PracticalInfoNavigationViewModel } from '../../models/PracticalInfoNavigationViewModel';
import PracticalInfoNavigationItem from './PracticalInfoNavigationItem';

const PracticalInfoNavigation: FunctionComponent<PracticalInfoNavigationViewModel> = ({
  componentTitle,
  items,
  activeItemId,
}) => {
  if (!items.length) {
    return null;
  }

  return (
    <StickyBox className="custom_cta sidenav" offsetTop={140}>
      <p className="heading">{componentTitle}</p>
      {items.map(item => (
        <PracticalInfoNavigationItem key={item.id} item={item} activeItemId={activeItemId} />
      ))}
    </StickyBox>
  );
};

export default PracticalInfoNavigation;
