import { FunctionComponent } from 'react';
import cx from 'classnames';
import IconSprite from '../../icons/IconSprite';

interface StarsProps {
  count: number;
  color?: string;
  className?: string;
}

const Stars: FunctionComponent<StarsProps> = ({ count, color, className }) => {
  let decimal = (count % 1) * 100;
  let whole = Math.trunc(count);
  if (whole >= 5) {
    decimal = 0;
    whole = 5;
  }
  const wholeList = Array.from(Array(whole).keys());
  let wholeRest: number;

  if (decimal >= 50) {
    wholeRest = 5 - (whole + 1);
  } else {
    wholeRest = 5 - whole;
  }

  const restList = Array.from(Array(wholeRest).keys());

  return (
    <div className={cx('item', 'star-rating', className)}>
      {wholeList.map((item, index) => {
        return (
          <div key={index} className={`star-rating__item star-rating__item--full${color || ''}`}>
            <IconSprite name="star" />
          </div>
        );
      })}
      {decimal >= 50 ? (
        <div className="star-rating__item star-rating__item--half">
          <IconSprite name="star" className={`star-rating__half-empty${color || ''} `} />
          <span className="star-rating__half-full__container">
            <IconSprite name="star" className={`star-rating__half-full${color || ''}`} />
          </span>
        </div>
      ) : null}
      {restList.map((item, index) => {
        return (
          <div key={index} className={`star-rating__item  m star-rating__item--empty${color || ''}`}>
            <IconSprite name="star" />
          </div>
        );
      })}
    </div>
  );
};

export default Stars;
