import { FunctionComponent } from 'react';
import { FooterViewModel } from '../../models/FooterViewModel';
import FooterColumn from './FooterColumn';
import { FooterColumn as FooterColumnModel } from '../../models/FooterColumn';

const Footer: FunctionComponent<FooterViewModel> = ({ footerColumns, payOff, colorCombination }: FooterViewModel) => (
  <div className={`footercontainer footercontainer__${colorCombination || 'theme-primary'}`}>
    {footerColumns && footerColumns.length > 0 && (
      <div className="footerrow">
        {footerColumns.map((footerColumn: FooterColumnModel) => (
          <FooterColumn {...footerColumn} key={footerColumn.id} />
        ))}
      </div>
    )}
    {payOff && (
      <div className="footerrow">
        <div className="footercol">
          <h2>{payOff}</h2>
        </div>
      </div>
    )}
  </div>
);

export default Footer;
