import { FunctionComponent } from 'react';
import { IntroTextViewModel } from '../../models/IntroTextViewModel';
import { asEditable } from '../../helpers/sitecore';
import Carousel from '../carousel/Carousel';
import Text from '../atoms/text/Text';

const IntroTextRouteSuggestion: FunctionComponent<IntroTextViewModel> = ({
  componentTitle,
  componentText,
  componentShortText,
  carousel,
}) => (
  <section>
    <div className="quote">
      <Text {...componentTitle} className="heading" />
      <div className="content" dangerouslySetInnerHTML={asEditable(componentText)} />

      {componentShortText && (
        <div className="details-content">
          <strong dangerouslySetInnerHTML={asEditable(componentShortText)} />
        </div>
      )}
    </div>
    {carousel && <Carousel {...carousel} />}
  </section>
);

export default IntroTextRouteSuggestion;
