import { Component } from 'react';
import GoogleMapReact, { Coords } from 'google-map-react';
import Marker from './marker/Marker';
import { MapHeaderViewModel } from '../../models/MapHeaderViewModel';
import { centerMap } from '../../helpers/map';
import { mapStyles } from '../mapBlock/style';

export default class MapHeader extends Component<MapHeaderViewModel> {
  constructor(props: MapHeaderViewModel) {
    super(props);

    this.handleGoogleApiLoaded = this.handleGoogleApiLoaded.bind(this);
  }

  getDefaultCenter(): Coords | undefined {
    const mapMarkers = this.props.mapMarkers;

    if (!mapMarkers || mapMarkers.length === 0) {
      return undefined;
    }

    return {
      lat: mapMarkers[0].latitude,
      lng: mapMarkers[0].longitude,
    };
  }

  handleGoogleApiLoaded({ map, maps }: { map: google.maps.Map; maps: google.maps.Map[] }) {
    if (!map) {
      return;
    }

    if (this.props.mapMarkers) {
      centerMap(map, this.props.mapMarkers);
    }

    const zoom = map.getZoom();
    if (zoom === undefined || zoom > 10) {
      map.setZoom(10);
    }
  }

  render() {
    const { mapMarkers, mapsApiKey } = this.props;

    return (
      <div className="spotlight-header-wrapper map">
        <section className="spotlight-header">
          <div className="spotlight-header__media-background">
            <GoogleMapReact
              bootstrapURLKeys={{ key: mapsApiKey || '', language: 'nl' }}
              defaultZoom={11}
              defaultCenter={this.getDefaultCenter()}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={this.handleGoogleApiLoaded}
              options={{ styles: mapStyles }}
            >
              {mapMarkers &&
                mapMarkers.map((mapMarker, index) => (
                  <Marker
                    key={mapMarker.id || index}
                    lat={mapMarker.latitude}
                    lng={mapMarker.longitude}
                    icon={mapMarker.icon || ''}
                    title={mapMarker.title}
                  />
                ))}
            </GoogleMapReact>
          </div>
        </section>
      </div>
    );
  }
}
