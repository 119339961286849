import { Component } from 'react';
import { ScrollButtonViewModel } from '../../models/ScrollButtonViewModel';
import { animateScroll as scroll } from 'react-scroll';
import * as throttle from 'lodash.throttle';

interface ScrollButtonState {
  show: boolean;
  initialized: boolean;
}

const scrollToTop = () => {
  scroll.scrollToTop({ smooth: true });
};

class ScrollButton extends Component<ScrollButtonViewModel, ScrollButtonState> {
  toggleButton = throttle(() => {
    if (this.state.initialized) {
      this.setState({
        show: window.scrollY > 800,
      });
    }
  }, 1000);

  constructor(props: ScrollButtonViewModel) {
    super(props);

    this.state = {
      show: false,
      initialized: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.toggleButton);
    this.setState({
      initialized: true,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleButton);
    this.setState({
      initialized: false,
    });
  }

  render() {
    if (this.props.isInEditMode) {
      return null;
    }

    return (
      <button
        id="back-to-top"
        className={this.state.show ? 'show' : ''}
        title={this.props.componentText}
        onClick={scrollToTop}
      >
        ▲
      </button>
    );
  }
}
export default ScrollButton;
