import { FunctionComponent } from 'react';
import { TripExtensionsViewModel } from '../../models/TripExtensionsViewModel';
import SearchResult from '../searchResult/SearchResult';

interface TripExtensionsProps extends TripExtensionsViewModel {}

const TripExtensions: FunctionComponent<TripExtensionsProps> = ({ tripExtensions, noResultsText }) => {
  if (!tripExtensions || tripExtensions.length === 0) {
    return (
      <section>
        <p>{noResultsText}</p>
      </section>
    );
  }

  return (
    <section>
      {tripExtensions.map(te => (
        <SearchResult key={te.id} {...te} headingTag="h2" />
      ))}
    </section>
  );
};

export default TripExtensions;
