import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { PopupViewModel } from '../../models/PopupViewModel';
import { createPortal } from 'react-dom';
import PopupInner from './PopupInner';

export interface PopupProps extends PopupViewModel {
  onClose: () => void;
}

let numberOfModalsActive = 0;

const PopupModal: FunctionComponent<PopupProps> = ({ title, text, onClose }) => {
  const [container, setContainer] = useState<HTMLDivElement | undefined>(undefined);
  const modalId = useRef<number>(0);

  // Create the portal div once and remove it when the modal gets destroyed.
  useEffect(() => {
    // Create an ID for the current modal.
    modalId.current = ++numberOfModalsActive;
    document.body.classList.add('haspopup');

    const el = document.createElement('div');
    document.body.appendChild(el);
    setContainer(el);

    return () => {
      numberOfModalsActive--;
      document.body.removeChild(el);

      if (numberOfModalsActive === 0) {
        document.body.classList.remove('haspopup');
      }
    };
  }, []);

  // Listen to keypresses.
  useEffect(() => {
    const handleEscKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        // Only close top modal.
        if (numberOfModalsActive === modalId.current) {
          onClose();

          // Don't propagate to other modals.
          e.stopImmediatePropagation();
        }
      }
    };
    document.addEventListener('keyup', handleEscKey);

    return () => {
      document.removeEventListener('keyup', handleEscKey);
    };
  });

  if (!container) {
    return null;
  }

  return createPortal(<PopupInner title={title} text={text} onClose={onClose} />, container);
};
export default PopupModal;
