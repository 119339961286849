import { Component, SyntheticEvent } from 'react';
import { ResultFacet } from '../../../../models/ResultFacet';
import { ActiveFilter } from '../../../holidayResultContainer/HolidayResultContainer';

interface SelectFilterProps extends ResultFacet {
  selectFilterChanged?: (activeFilter: ActiveFilter) => void;
  activeValue?: string;
}

export default class SelectFilter extends Component<SelectFilterProps> {
  constructor(props: SelectFilterProps) {
    super(props);

    this.changeHandler = this.changeHandler.bind(this);
  }

  changeHandler(event: SyntheticEvent<HTMLSelectElement>) {
    if (!this.props.selectFilterChanged) {
      return;
    }

    const select = event.target as HTMLSelectElement;

    this.props.selectFilterChanged({
      facetId: this.props.id,
      activeValueId: select.value,
    });
  }

  getActiveValue() {
    if (this.props.activeValue) {
      return this.props.activeValue;
    } else {
      const activeFilter = this.props.values.find(value => value && !!value.isSelected);
      if (activeFilter) {
        return activeFilter.id;
      } else {
        return '';
      }
    }
  }

  render() {
    const { title, values } = this.props;

    if (values.length < 1) {
      return null;
    }

    return (
      <div className="select-filter">
        <div className="filtertoggle noarrow">{title}</div>
        <div className="select-style">
          <select id="aantalpersonen" onChange={this.changeHandler} value={this.getActiveValue()}>
            <option />
            {values.map((value, key) => (
              <option key={key} value={value.id}>
                {value.title}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}
