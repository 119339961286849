import { FunctionComponent } from 'react';

import cx from 'classnames';
import { MenuViewModel } from '../../models/MenuViewModel';
import Link from '../atoms/link/Link';

interface SubMenuProps extends MenuViewModel {
  alignment?: string;
}

const SubMenu: FunctionComponent<SubMenuProps> = ({ items, backButtonLink, alignment }) => (
  <div className={cx('pagemenu', alignment, items.length >= 4 && 'pagemenu--wide')}>
    {backButtonLink && <Link {...backButtonLink} class="pagemenuitem pagemenuitem--nobackground" />}
    {items &&
      items.map(subMenuItem => {
        return (
          subMenuItem.url &&
          subMenuItem.title && (
            <Link
              url={subMenuItem.url}
              text={subMenuItem.title}
              target={subMenuItem.target}
              class={cx('pagemenuitem', subMenuItem.selected && 'pagemenuitem--active')}
              key={subMenuItem.id}
            />
          )
        );
      })}
  </div>
);

export default SubMenu;
