import { Component, PropsWithChildren } from 'react';
import { Placeholder } from './Placeholder';
import { ContainerViewModel } from '../../models/ContainerViewModel';
import { ContainerWidth } from '../../models/ContainerWidth';

class Container extends Component<PropsWithChildren<ContainerViewModel>> {
  public static defaultProps: Partial<ContainerViewModel> = {
    width: ContainerWidth.Full,
  };

  render() {
    const { children, placeholders, width } = this.props;

    const cssClass = width && width === 'Half' ? 'col-half' : 'col-full';

    return (
      <div className="boxed">
        <div className={cssClass}>
          <Placeholder children={children} placeholders={placeholders} placeholderIndex={0} />
        </div>
      </div>
    );
  }
}

export default Container;
