import { FunctionComponent } from 'react';
import { FooterItem as IFooterItem } from '../../models/FooterItem';
import Link from '../atoms/link/Link';
import { asEditable } from '../../helpers/sitecore';

const FooterItem: FunctionComponent<IFooterItem> = ({
  componentText,
  componentLink,
  navigationTitle,
  socialIcons,
  showCountryTitle,
  countryName,
}) => (
  <>
    {!componentText ? (
      <>
        {showCountryTitle && <p className="heading">{countryName}</p>}
        <Link
          url={componentLink ? componentLink.url : ''}
          target={componentLink ? componentLink.target : ''}
          text={navigationTitle}
        />
      </>
    ) : (
      <>
        {socialIcons && socialIcons.length > 0 && (
          <div className="footer-social-icons">
            {socialIcons.map((socialIcon, key) => (
              <Link
                key={key}
                url={socialIcon.componentLink ? socialIcon.componentLink.url : ''}
                target={socialIcon.componentLink ? socialIcon.componentLink.target : ''}
                class={socialIcon.iconClass}
              />
            ))}
          </div>
        )}
        <div className="footer-text" dangerouslySetInnerHTML={asEditable(componentText)} />
      </>
    )}
  </>
);

export default FooterItem;
