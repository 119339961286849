import { FunctionComponent, ReactNode } from 'react';
import cx from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import ValidationError from './atoms/ValidationError';
import FormItem from './atoms/FormItem';

export interface CheckBoxProps extends FieldRenderProps<string> {
  label?: ReactNode;
  className?: string;
  required?: boolean;
}

const CheckBox: FunctionComponent<CheckBoxProps> = ({ input, meta, ...rest }) => (
  <FormItem meta={meta} {...rest}>
    <label
      className={cx('form-item', rest.className, meta.error ? 'form-item-error' : '', rest.required ? 'required' : '')}
    >
      <input {...input} required={rest.required} type="checkbox" name={input.name} />
      {rest.label}
      <ValidationError meta={meta} />
    </label>
  </FormItem>
);

export default CheckBox;
