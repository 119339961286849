export function loadMinisite() {
  // Adds the mst query parameter when a link is clicked.
  if (typeof window !== 'undefined' && 'mst' in window) {
    const mst: string = (window as any).mst;

    document.addEventListener('click', (e: MouseEvent) => {
      let target = e.target as HTMLElement | null;
      while (target && target.tagName !== 'A') {
        target = target.parentElement;
      }

      if (target && target instanceof HTMLAnchorElement) {
        let href = target.href;
        if (!href || href.indexOf('/') !== 0 || href.indexOf('//') === 0 || href === '/') {
          return;
        }

        let hash = '';
        const hashPos = href.indexOf('#');
        if (hashPos !== -1) {
          hash = href.substr(hashPos);
          href = href.substr(0, hashPos);
        }

        if (href.indexOf('?') === -1) {
          href = href + '?mst=' + mst;
        } else {
          href = href + '&mst=' + mst;
        }

        target.href = href + hash;
      }
    });
  }

  // Resize parent frame.
  if (typeof window !== 'undefined' && window.parent !== window && typeof window.parent.postMessage === 'function') {
    let previousSubmittedHeight: number | null = null;
    let inited = false;

    const init = function () {
      if (!inited) {
        window.parent.postMessage(JSON.stringify({ op: 'onLoad' }), '*');
        inited = true;
      }
      resizeOpener();
    };

    const resizeOpener = function () {
      const body = document.body;

      const height: number = Math.max(body.scrollHeight, body.offsetHeight, body.clientHeight);

      // Only change if there is a difference.
      if (height !== previousSubmittedHeight) {
        previousSubmittedHeight = height;
        window.parent.postMessage(JSON.stringify({ op: 'setHeight', height: height }), '*');
      }

      window.setTimeout(resizeOpener, 500);
    };

    window.addEventListener('DOMContentLoaded', init);
    window.addEventListener('load', init);
    if (document.readyState === 'complete') {
      init();
    }
  }
}

loadMinisite();
