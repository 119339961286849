import { FunctionComponent } from 'react';
import cx from 'classnames';
import { SpotlightHeaderViewModel } from '../../models/SpotlightHeaderViewModel';
import SpotlightHeader from './SpotlightHeader';
import Text from '../atoms/text/Text';
import Button from '../atoms/button/Button';

const SpotlightHeaderButtons: FunctionComponent<SpotlightHeaderViewModel> = props => {
  const {
    componentTitle,
    componentLink,
    showButton,
    buttonText,
    colorCombination,
    secondButtonText,
    secondComponentLink,
    showSecondButton,
    secondColorCombination,
  } = props;

  return (
    <SpotlightHeader {...props} className="buttons">
      <div className="spotlight-buttons_container">
        {componentTitle && (
          <div className="spotlight-buttons_title">
            <Text className="heading" {...componentTitle} />
          </div>
        )}
        <div className="spotlight-buttons">
          {componentLink && showButton && (
            <Button {...componentLink} class={cx('cta_button', `cta_button-${colorCombination}`)} text={buttonText} />
          )}
          {secondComponentLink && showSecondButton && (
            <Button
              {...secondComponentLink}
              class={cx('cta_button', `cta_button-${secondColorCombination}`)}
              text={secondButtonText}
            />
          )}
        </div>
      </div>
    </SpotlightHeader>
  );
};

export default SpotlightHeaderButtons;
