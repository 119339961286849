import { FunctionComponent, ReactNode } from 'react';
import { FieldRenderProps } from 'react-final-form';
import ValidationError from './atoms/ValidationError';
import FormItem from './atoms/FormItem';

export interface DropdownProps extends FieldRenderProps<string> {
  label?: ReactNode;
  options?: { [key: string]: string };
  required?: boolean;
}

const Dropdown: FunctionComponent<DropdownProps> = ({ input, meta, ...rest }) => {
  if (!rest.options) {
    return null;
  }

  const options = rest.options;

  return (
    <FormItem meta={meta} {...rest} className="form-dropdown">
      {rest.label && <label className={rest.required ? 'required' : ''}>{rest.label}:</label>}
      <div className="form-item-value select-style">
        <select {...input} required={rest.required}>
          {Object.keys(options).map(id => (
            <option key={id} value={id}>
              {options[id]}
            </option>
          ))}
        </select>
      </div>
      <ValidationError meta={meta} />
    </FormItem>
  );
};

export default Dropdown;
