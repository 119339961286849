import { Component, MouseEvent } from 'react';
import { FormSpy } from 'react-final-form';
import { BlogEditInputModel } from '../../../models/BlogEditInputModel';
import { FormApi } from 'final-form';

interface BlogAutoSaveProps {
  values: BlogEditInputModel;
  submitSucceeded: boolean;
  debounce: number;
  cacheKey: string;
  form: FormApi<BlogEditInputModel>;
  dirty: boolean;
}

interface BlogAutoSaveState {
  cachedPostInformation?: BlogEditInputModel;
}

class BlogAutoSave extends Component<BlogAutoSaveProps, BlogAutoSaveState> {
  timeout?: number;

  state: BlogAutoSaveState = {};

  UNSAFE_componentWillReceiveProps(nextProps: BlogAutoSaveProps) {
    // Clear saved state on submitSucceeded.
    if (nextProps.submitSucceeded && !this.props.submitSucceeded) {
      this.deleteCachedPost();
    }

    // Add a handler to save the state.
    if (nextProps.values !== this.props.values && nextProps.dirty) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }

      this.timeout = window.setTimeout(this.save, this.props.debounce);
    }
  }

  componentDidMount(): void {
    // Try to load from session.
    if ('localStorage' in window) {
      const { cacheKey } = this.props;
      const localStorageItem = window.localStorage.getItem(cacheKey);
      if (localStorageItem) {
        const cached = JSON.parse(localStorageItem) as BlogEditInputModel;
        this.setState(() => ({
          cachedPostInformation: cached,
        }));
      }
    }
  }

  save = () => {
    if ('localStorage' in window) {
      const { author, subject, componentText } = this.props.values;
      window.localStorage.setItem(this.props.cacheKey, JSON.stringify({ author, subject, componentText }));
    }
  };

  restoreCachedPost = (e: MouseEvent<HTMLAnchorElement>) => {
    const { form } = this.props;
    const { cachedPostInformation } = this.state;

    e.preventDefault();

    if (cachedPostInformation) {
      // Set form values.
      const newValues: BlogEditInputModel = Object.assign({}, this.props.values, cachedPostInformation);
      form.initialize(newValues);
    }

    // Remove local state.
    this.setState(() => ({
      cachedPostInformation: undefined,
    }));

    // Session state is kept to allow for additional refreshes.

    return false;
  };

  deleteCachedPost = (e?: MouseEvent<HTMLAnchorElement>) => {
    const { cacheKey } = this.props;

    if (e) {
      e.preventDefault();
    }

    // Clear session state.
    window.localStorage.removeItem(cacheKey);
  };

  render() {
    const { cachedPostInformation } = this.state;

    if (!cachedPostInformation) {
      return null;
    }

    return (
      <div className="blogautosave">
        <p>Er is een opgeslagen versie beschikbaar.</p>
        <p>
          <a href="/" onClick={this.restoreCachedPost} className="cta_button cta_button--small">
            Tekst herstellen
          </a>
          <a href="/" onClick={this.deleteCachedPost} className="cta_button cta_button--secondary cta_button--small">
            Opgeslagen versie verwijderen
          </a>
        </p>
      </div>
    );
  }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in debounce and save props nicely

const BlogAutoSaveHoc = props => (
  <FormSpy
    {...props}
    subscription={{ values: true, submitSucceeded: true, dirty: true, modified: true }}
    component={BlogAutoSave}
  />
);

export default BlogAutoSaveHoc;
