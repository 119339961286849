import { FunctionComponent } from 'react';
import { DestinationDataItem, FuelConsumptionSource, FuelType, VehicleDataItem } from './types';
import { asEditable } from '../../helpers/sitecore';

export interface FuelCalculatorResultProps {
  destination: DestinationDataItem;
  vehicle: VehicleDataItem;
  distance: number;
  colorCombination: string;
  disclaimer?: string;
}

const fuelTypeTitle = (fuelType: FuelType): string => {
  switch (fuelType) {
    case FuelType.Diesel:
      return 'Diesel';
    case FuelType.Electric:
      return 'Electiciteit';
    case FuelType.Gasoline:
    default:
      return 'Benzine';
  }
};

const getFuelPrice = (destination: DestinationDataItem, vehicle: VehicleDataItem): number | undefined => {
  switch (vehicle.fueltype) {
    case FuelType.Diesel:
      return destination.diesel;
    case FuelType.Gasoline:
      return destination.gasoline;
    default:
      return undefined;
  }
};

const fuelConsumptionSourceTitle = (fuelConsumptionSource: FuelConsumptionSource | undefined) => {
  switch (fuelConsumptionSource) {
    case FuelConsumptionSource.Length:
      return 'Dit verbruik is geschat op basis van de lengte.';
    case FuelConsumptionSource.Supplier:
      return 'Dit verbruik is opgegeven door de verhuurder.';
    default:
      return '';
  }
};

const calculateTotalFuelConsumption = (consumption: number, distance: number): string => {
  return ((consumption * distance) / 100).toFixed(0);
};

const calculateTotalFuelCosts = (fuelPrice: number, consumption: number, distance: number): string => {
  return (Math.round(fuelPrice * consumption * distance) / 100).toFixed(0);
};

const FuelCalculatorResult: FunctionComponent<FuelCalculatorResultProps> = ({
  destination,
  vehicle,
  distance,
  colorCombination,
  disclaimer,
}) => {
  const hasFuel = vehicle.fuelconsumptionsource !== FuelConsumptionSource.NoFuel;
  const hasConsumption = vehicle.fuelconsumptionlow !== undefined;
  const fuelPrice = getFuelPrice(destination, vehicle);

  return (
    <div
      className={`fuelcalculatorresult custom_cta actionwidget--${
        colorCombination ? colorCombination : 'theme-secondary'
      }`}
    >
      <p>
        Brandstofsoort: {fuelTypeTitle(vehicle.fueltype)}
        <br />
        {vehicle.length && (
          <>
            Lengte:{' '}
            {Array.isArray(vehicle.length) ? `${vehicle.length[0]} - ${vehicle.length[1]} cm` : `${vehicle.length} cm`}
          </>
        )}
      </p>
      {!hasFuel && <p>Dit voertuig gebruikt geen brandstof</p>}
      {hasFuel && !hasConsumption && <p>Van dit voertuig is het verbruik niet bekend.</p>}
      {hasFuel && hasConsumption && vehicle.fuelconsumptionlow && vehicle.fuelconsumptionhigh && (
        <>
          <p>
            {'Geschat verbruik: '}
            {vehicle.fuelconsumptionlow === vehicle.fuelconsumptionhigh
              ? vehicle.fuelconsumptionlow
              : `${vehicle.fuelconsumptionlow} - ${vehicle.fuelconsumptionhigh}`}
            {' L/100 km'}
            <br />
            {fuelConsumptionSourceTitle(vehicle.fuelconsumptionsource)}
            <br />
          </p>
          <p>
            Afstand: {distance} km
            <br />
            {'Geschat totaal verbruik: '}
            {vehicle.fuelconsumptionlow === vehicle.fuelconsumptionhigh
              ? calculateTotalFuelConsumption(vehicle.fuelconsumptionlow, distance)
              : `${calculateTotalFuelConsumption(
                  vehicle.fuelconsumptionlow,
                  distance
                )} - ${calculateTotalFuelConsumption(vehicle.fuelconsumptionhigh, distance)}`}{' '}
            liter
          </p>
        </>
      )}
      {hasFuel && hasConsumption && !fuelPrice && <p>Voor dit brandstoftype is geen prijs bekend.</p>}
      {hasFuel && hasConsumption && vehicle.fuelconsumptionlow && vehicle.fuelconsumptionhigh && fuelPrice && (
        <p>
          {fuelTypeTitle(vehicle.fueltype)}prijs: {fuelPrice} {destination.currency} / liter
          <br />
          {'Geschatte totale kosten: '}
          {vehicle.fuelconsumptionlow === vehicle.fuelconsumptionhigh
            ? calculateTotalFuelCosts(fuelPrice, vehicle.fuelconsumptionlow, distance)
            : `${calculateTotalFuelCosts(fuelPrice, vehicle.fuelconsumptionlow, distance)} - ${calculateTotalFuelCosts(
                fuelPrice,
                vehicle.fuelconsumptionhigh,
                distance
              )}`}{' '}
          {destination.currency}
        </p>
      )}

      {disclaimer && (
        <div className="fuelcalculatorresult__disclaimer" dangerouslySetInnerHTML={asEditable(disclaimer)} />
      )}
    </div>
  );
};

export default FuelCalculatorResult;
