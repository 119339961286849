import { FunctionComponent } from 'react';
import { asEditable } from '../../helpers/sitecore';
import { BlogPostComment } from '../../models/BlogPostComment';

const BlogComment: FunctionComponent<BlogPostComment> = ({ author, text }) => {
  const escapedText = text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\r?\n/g, '<br>\n');

  return (
    <div className="reaction">
      <em>{author}</em>
      <div dangerouslySetInnerHTML={asEditable(escapedText)} />
    </div>
  );
};

export default BlogComment;
