import { Component } from 'react';
import cx from 'classnames';
import { HighlightViewModel } from '../../models/HighlightViewModel';
import { VideoPlayerWrapper } from '../video/VideoPlayerWrapper';
import Link from '../atoms/link/Link';
import Stars from '../atoms/stars/Stars';
import { BreakPoints } from '../../constants/breakPoints';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import Text from '../atoms/text/Text';

const withSizes = require('react-sizes');

// The additional state isMobileOrTablet is there because
// otherwise the video is not displayed properly because
// the HTML differs between serverside and clientside.

export interface HighlightVideoState {
  playVideo: boolean;
  isMobileOrTablet: boolean;
}

interface HighlightModel extends HighlightViewModel {
  isMobileOrTablet: boolean;
}

class Highlight extends Component<HighlightModel, HighlightVideoState> {
  constructor(props: HighlightModel) {
    super(props);

    this.state = {
      playVideo: false,
      isMobileOrTablet: false,
    };
  }

  playVideo = () => {
    if (!this.state.isMobileOrTablet) {
      this.setState({ playVideo: true });
    }
  };

  pauseVideo = () => {
    if (!this.state.isMobileOrTablet) {
      this.setState({ playVideo: false });
    }
  };

  componentDidMount() {
    this.setState({
      isMobileOrTablet: this.props.isMobileOrTablet,
    });
  }

  componentDidUpdate(prevProps: HighlightModel) {
    if (prevProps.isMobileOrTablet !== this.props.isMobileOrTablet) {
      this.setState({
        isMobileOrTablet: this.props.isMobileOrTablet,
      });
    }
  }

  render() {
    const {
      url,
      videoId,
      componentTitle,
      componentSubTitle,
      componentImage,
      startingPrice,
      duration,
      numberOfTravellersText,
      relatedTourleader,
      rating,
      isInEditMode,
      editModeText,
      hoverText,
    } = this.props;
    const { isMobileOrTablet, playVideo } = this.state;

    return (
      <Link url={url}>
        <section
          onMouseEnter={() => (videoId ? this.playVideo() : undefined)}
          onMouseLeave={() => (videoId ? this.pauseVideo() : undefined)}
        >
          <div className={cx('productwidget', 'highlight', 'hasvideobackground', hoverText && 'hashovertext')}>
            {!isMobileOrTablet && videoId && !isInEditMode ? (
              <div className="videobackground">
                <VideoPlayerWrapper videoId={videoId} playing={playVideo} isInEditMode={isInEditMode} />
              </div>
            ) : componentImage ? (
              <ImageHtml {...componentImage} class="highlight-image" />
            ) : undefined}
            {rating !== undefined && rating > 0 && (
              <div className="topleft">
                <Stars count={rating} />
              </div>
            )}
            {isInEditMode ? (
              <p className="edit-mode-text">{editModeText}</p>
            ) : (
              <>
                {startingPrice && (
                  <div className="tipprice">
                    {startingPrice}
                    {numberOfTravellersText && <p>{numberOfTravellersText}</p>}
                  </div>
                )}
              </>
            )}
            <div className="tipbottom tipright">
              <span className="preheader">{componentSubTitle}</span>
              <Text {...componentTitle} className="heading" />
              {duration && <p>{duration}</p>}
            </div>

            {hoverText && <div className="tipbottom hovertext">{hoverText}</div>}

            {relatedTourleader && (
              <div className="tipbottom tipleft">
                <span className="tip">
                  {relatedTourleader.componentImageHtml && <ImageHtml {...relatedTourleader.componentImageHtml} />}
                  <br />
                  {relatedTourleader.componentText}
                </span>
              </div>
            )}
          </div>
        </section>
      </Link>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobileOrTablet: width < BreakPoints.screenMd || navigator.userAgent.indexOf('iPad') !== -1,
});

export default withSizes.withSizes(mapSizesToProps)(Highlight);
