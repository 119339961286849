export enum DistanceType {
  KmPerDay = 'kd',
  KmTotal = 'kt',
  MilePerDay = 'md',
  MileTotal = 'mt',
}

export enum PaneType {
  Vehicles = 'vehicles',
  Destination = 'destination',
  Result = 'result',
}

export enum PaneState {
  None = 'none',
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error',
}

export interface PaneData<T extends PaneDataItem> {
  items: T[];
}

export interface PaneDataItem {
  key: string;
  title: string;
  children?: PaneDataItem[];
}

export enum DestinationType {
  Continent = 'continent',
  Country = 'country',
  State = 'state',
}

export interface DestinationDataItem extends PaneDataItem {
  type: DestinationType;
  gasoline?: number;
  diesel?: number;
  currency: string;
}

export enum FuelType {
  Gasoline = 'gasoline',
  Diesel = 'diesel',
  Electric = 'electric',
}

export enum FuelConsumptionSource {
  Length = 'length',
  Supplier = 'supplier',
  NoFuel = 'nofuel',
}

export interface VehicleDataItem extends PaneDataItem {
  fueltype: FuelType;
  fuelconsumptionlow?: number;
  fuelconsumptionhigh?: number;
  fuelconsumptionsource?: FuelConsumptionSource;
  length: number | [number, number];
}
