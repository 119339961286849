import { ChangeEvent, Component } from 'react';
import withSizes from 'react-sizes';
import { SorterOptionViewModel } from '../../models/SorterOptionViewModel';
import { BreakPoints } from '../../constants/breakPoints';

interface SorterProps {
  options: SorterOptionViewModel[];
  onSortChange?: (sortOption: string) => void;
  activeOption?: string;
  isXs: boolean;
}

class Sorter extends Component<SorterProps> {
  constructor(props: SorterProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: ChangeEvent<HTMLSelectElement>) {
    if (!this.props.onSortChange) {
      return;
    }

    const select = event.currentTarget;

    this.props.onSortChange(select.value);
  }

  render() {
    const { options, activeOption, isXs } = this.props;

    return (
      <div className="sort">
        {isXs ? '⇅' : 'Sorteer op'}
        <div className="select-style">
          <select onChange={this.handleChange} value={activeOption}>
            {options.map(option => (
              <option value={option.key} key={option.key}>
                {option.title}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isXs: width < BreakPoints.screenXs,
});

export default withSizes(mapSizesToProps)(Sorter);
