import { FunctionComponent, MouseEvent } from 'react';
import cx from 'classnames';

export interface PagingProps {
  previousUrl?: string;
  nextUrl?: string;
  onNext?: () => void;
  onPrevious?: () => void;
  className?: string;
}

const makePreventDefault = function (
  handler: (() => void) | undefined
): ((e: MouseEvent<HTMLAnchorElement>) => void) | undefined {
  if (!handler) {
    return undefined;
  }

  return (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    handler();
  };
};

const Paging: FunctionComponent<PagingProps> = props => {
  return (
    <div className={cx('paging', props.className)}>
      {(props.previousUrl || props.onPrevious) && (
        <a href={props.previousUrl || '#'} onClick={makePreventDefault(props.onPrevious)}>
          &lt;
        </a>
      )}
      {(props.nextUrl || props.onNext) && (
        <a href={props.nextUrl || '#'} onClick={makePreventDefault(props.onNext)}>
          &gt;
        </a>
      )}
    </div>
  );
};

export default Paging;
