import { Component, PropsWithChildren } from 'react';
import * as isEqual from 'lodash.isequal';
import cx from 'classnames';
import FilterContainer from '../filters/filterContainer/FilterContainer';
import MultiSelectFilter from '../filters/filterTypes/multiSelectFilter/MultiSelectFilter';
import RangeSlider from '../filters/filterTypes/rangeSlider/RangeSlider';
import SelectFilter from '../filters/filterTypes/selectFilter/SelectFilter';
import { ProductSearchApiResultViewModel } from '../../models/ProductSearchApiResultViewModel';
import SearchResult from '../searchResult/SearchResult';
import FullScreenLoader from '../atoms/loader/FullScreenLoader';
import {
  setSearchParamsOnHistoryState,
  getSearchParamsFromHistory,
  removeRedundantActiveFilters,
  createQuerystring,
  SearchParams,
  buildActiveFiltersFromFacets,
} from '../../helpers/search';
import Pagination from '../pagination/Pagination';
import Sorter from '../sorter/Sorter';
import { ProductSearchResultsViewModel } from '../../models/ProductSearchResultsViewModel';
import { ResultFacet } from '../../models/ResultFacet';
import { RangeFacetViewModel } from '../../models/RangeFacetViewModel';
import { Placeholder } from '../structure/Placeholder';
import { handleError, jsonOrThrow } from '../../helpers/fetch';
import { ReactPlaceholder } from '../../models/ReactPlaceholder';

export type ActiveFilter = {
  facetId: string;
  activeValueId: string;
};

export type ActiveFilters = {
  [key: string]: string;
};

interface HolidayResultContainerState extends SearchParams {
  results: ProductSearchApiResultViewModel;
  reactPlaceholders?: ReactPlaceholder[];
  loading: boolean;
}

interface ExtendedProductSearchResultsViewModel extends ProductSearchResultsViewModel {
  placeholders?: ReactPlaceholder[];
}

export default class HolidayResultContainer extends Component<
  PropsWithChildren<ExtendedProductSearchResultsViewModel>,
  HolidayResultContainerState
> {
  loadingTimeoutId!: number;
  loadingTimeout = 25;

  constructor(props: ExtendedProductSearchResultsViewModel) {
    super(props);

    const results = props.results;

    this.state = {
      activeFilters: buildActiveFiltersFromFacets(results.facets, results.rangeFacets),
      results: { ...results },
      loading: false,
      activePage: props.results.page,
      reactPlaceholders: results.placeholders,
      sort: props.results.sort,
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleMultiSelectFilterChange = this.handleMultiSelectFilterChange.bind(this);
    this.handleActivePageChange = this.handleActivePageChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.fetchResults = this.fetchResults.bind(this);
    this.handlePopstate = this.handlePopstate.bind(this);
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handlePopstate);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopstate);
  }

  handlePopstate() {
    const searchParams = getSearchParamsFromHistory();
    this.setSearchState(
      {
        ...searchParams,
      },
      false
    );
  }

  showLoader() {
    this.setState({
      loading: true,
    });
  }

  fetchResults(addHistoryEntry: boolean) {
    const queryString = createQuerystring({
      currentPageId: this.props.currentPageId,
      activeFilters: this.state.activeFilters,
      activePage: this.state.activePage,
      sort: this.state.sort,
    });

    this.loadingTimeoutId = window.setTimeout(() => {
      this.showLoader();
    }, this.loadingTimeout);

    fetch(`/travelhome/api/productsearch?${queryString}`)
      .then(jsonOrThrow)
      .then(results => {
        clearTimeout(this.loadingTimeoutId);

        this.setState((prevState: HolidayResultContainerState) => {
          // If the result removes filters that are active, remove these
          const cleanedUpActiveFilters = removeRedundantActiveFilters(prevState.activeFilters, results.facets);

          if (!isEqual(results.url, prevState.results.url) && addHistoryEntry) {
            setSearchParamsOnHistoryState(
              {
                activeFilters: cleanedUpActiveFilters,
                activePage: prevState.activePage,
                sort: prevState.sort,
              },
              results.url
            );
          }

          const reactPlaceholders = results.placeholders;

          return {
            results,
            reactPlaceholders,
            activeFilters: cleanedUpActiveFilters,
            loading: false,
          };
        });
      })
      .catch(err => {
        handleError('Error while fetching results', err);

        this.setState({
          loading: false,
        });
      });
  }

  handleFilterChange(activeFilter: ActiveFilter) {
    this.setSearchState(
      {
        activeFilters: {
          ...this.state.activeFilters,
          [activeFilter.facetId]: activeFilter.activeValueId,
        },
        activePage: 1,
      },
      true
    );
  }

  handleMultiSelectFilterChange(activeFilter: ActiveFilter) {
    let activeValues = this.state.activeFilters[activeFilter.facetId]
      ? this.state.activeFilters[activeFilter.facetId].split(',')
      : [];

    if (activeValues.find(activeValue => activeValue === activeFilter.activeValueId)) {
      activeValues = activeValues.filter(activeValue => activeValue !== activeFilter.activeValueId);
    } else {
      activeValues.push(activeFilter.activeValueId);
    }

    this.setSearchState(
      {
        activeFilters: {
          ...this.state.activeFilters,
          [activeFilter.facetId]: activeValues.join(','),
        },
        activePage: 1,
      },
      true
    );
  }

  handleActivePageChange(activePage: number) {
    if (activePage <= 0) {
      return;
    }

    this.setSearchState({ activePage }, true);
  }

  handleSortChange(sortOption: string) {
    this.setSearchState(
      {
        sort: sortOption,
        activePage: 1,
      },
      true
    );
  }

  setSearchState<K extends keyof HolidayResultContainerState>(
    newState: Pick<HolidayResultContainerState, K> | HolidayResultContainerState,
    addHistoryEntry: boolean
  ) {
    // @ts-ignore TS2345
    this.setState(newState, () => {
      this.fetchResults(addHistoryEntry);
    });
  }

  render() {
    const { numberOfResultsPerPage, sortOptions, children, loadingText } = this.props;
    const { activeFilters, loading, activePage, sort, reactPlaceholders } = this.state;
    const { facets, rangeFacets, results, totalResults, url } = this.state.results;
    const countryFacetData = facets.find(facet => facet.id === 'country') as ResultFacet;
    const areaFacetData = facets.find(facet => facet.id === 'area') as ResultFacet;
    const departureFacetData = facets.find(facet => facet.id === 'departure') as ResultFacet;
    const durationRangeData = rangeFacets.find(rangeFacet => rangeFacet.id === 'duration') as RangeFacetViewModel;
    const personsFacetData = facets.find(facet => facet.id === 'persons') as ResultFacet;
    const priceRangeData = rangeFacets.find(rangeFacet => rangeFacet.id === 'price') as RangeFacetViewModel;
    const hasCountrySelection = countryFacetData && !!countryFacetData.values.find(v => v && !!v.isSelected);

    return (
      <div className="boxed holiday-results">
        {loading && <FullScreenLoader loadingText={loadingText} />}
        <FilterContainer>
          {countryFacetData && (
            <MultiSelectFilter
              {...countryFacetData}
              selectFilterChanged={this.handleMultiSelectFilterChange}
              activeValues={activeFilters[countryFacetData.id]}
              sortEmptyItemsLast={true}
              disableSelection={countryFacetData.values.length <= 1}
            />
          )}
          {areaFacetData && hasCountrySelection && (
            <MultiSelectFilter
              {...areaFacetData}
              selectFilterChanged={this.handleMultiSelectFilterChange}
              activeValues={activeFilters[areaFacetData.id]}
              sortEmptyItemsLast={false}
              disableSelection={areaFacetData.values.length <= 1}
              disableExpansion={true}
            />
          )}
          {departureFacetData && (
            <MultiSelectFilter
              {...departureFacetData}
              selectFilterChanged={this.handleMultiSelectFilterChange}
              activeValues={activeFilters[departureFacetData.id]}
              sortEmptyItemsLast={false}
            />
          )}
          {durationRangeData && (
            <RangeSlider
              {...durationRangeData}
              rangeSliderChanged={this.handleFilterChange}
              activeValues={activeFilters[durationRangeData.id as string]}
            />
          )}
          {personsFacetData && (
            <SelectFilter
              {...personsFacetData}
              selectFilterChanged={this.handleFilterChange}
              activeValue={activeFilters[personsFacetData.id as string]}
            />
          )}
          {priceRangeData && (
            <RangeSlider
              {...priceRangeData}
              rangeSliderChanged={this.handleFilterChange}
              activeValues={activeFilters[priceRangeData.id as string]}
            />
          )}
        </FilterContainer>
        <section className={cx('results', !totalResults && 'no-results')}>
          {totalResults > 0 && (
            <>
              <Pagination
                totalResults={totalResults}
                size={numberOfResultsPerPage}
                activePage={activePage}
                onActivePageChange={this.handleActivePageChange}
                url={url}
              />

              <Sorter options={sortOptions} onSortChange={this.handleSortChange} activeOption={sort} />

              {results.map(result => (
                <SearchResult key={result.id} {...result} type={undefined} />
              ))}

              <Pagination
                totalResults={totalResults}
                size={numberOfResultsPerPage}
                activePage={activePage}
                onActivePageChange={this.handleActivePageChange}
                url={url}
              />
            </>
          )}

          {/* The Travelhome-No-SearchResults-Content Placeholder */}
          <Placeholder children={children} placeholders={reactPlaceholders} placeholderIndex={0} />
        </section>
      </div>
    );
  }
}
