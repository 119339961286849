import { FunctionComponent } from 'react';
import cx from 'classnames';
import Link from '../atoms/link/Link';
import { PracticalInfoNavigationItemViewModel } from '../../models/PracticalInfoNavigationItemViewModel';
import { getPracticalInfoItem } from './utils';

interface PracticalInfoNavigationItemProps {
  item: PracticalInfoNavigationItemViewModel;
  activeItemId?: string;
}

interface RelatedParagraphsProps {
  isExpanded: boolean;
  items: PracticalInfoNavigationItemViewModel[];
  activeItemId?: string;
}

const RelatedParagraphs: FunctionComponent<RelatedParagraphsProps> = ({ items, isExpanded, activeItemId }) => (
  <ul className={`sidesubmenu${isExpanded ? ' expand-sidesubmenu' : ''}`}>
    {items.map(item => (
      <PracticalInfoNavigationItem key={item.id} item={item} activeItemId={activeItemId} />
    ))}
  </ul>
);

const PracticalInfoNavigationItem: FunctionComponent<PracticalInfoNavigationItemProps> = ({ item, activeItemId }) => {
  const { title, url, subItems } = item;

  const isExpanded =
    (subItems && activeItemId && (item.id === activeItemId || !!getPracticalInfoItem(subItems, activeItemId))) || false;

  return (
    <div>
      <Link text={title} url={url} class={cx(activeItemId === item.id && 'sidesubmenu__activelink')} />
      {subItems && subItems.length > 0 && (
        <RelatedParagraphs items={subItems} isExpanded={isExpanded} activeItemId={activeItemId} />
      )}
    </div>
  );
};

export default PracticalInfoNavigationItem;
