import { FunctionComponent, PropsWithChildren } from 'react';
import cx from 'classnames';
import { Placeholder } from './Placeholder';
import { ColumnContainerViewModel } from '../../models/ColumnContainerViewModel';

const defaultColumnSize = 'col1-2';

const ColumnContainer: FunctionComponent<PropsWithChildren<ColumnContainerViewModel>> = ({
  children,
  placeholders,
  grid,
  extraMarginTop,
}) => (
  <main>
    <div
      className={cx(
        'boxed',
        extraMarginTop && 'extra-margin-top',
        grid && grid.leftColumnFirstOnSmallScreens && 'boxed--firstcolumnfirst'
      )}
    >
      <div className={grid ? grid.leftColumnSize : defaultColumnSize}>
        <Placeholder children={children} placeholders={placeholders} placeholderIndex={0} />
      </div>
      <div className={grid ? grid.rightColumnSize : defaultColumnSize}>
        <Placeholder children={children} placeholders={placeholders} placeholderIndex={1} />
      </div>
    </div>
  </main>
);

export default ColumnContainer;
