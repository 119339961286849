import { Component } from 'react';
import * as withSizes from 'react-sizes';
import cx from 'classnames';
import { WidgetViewModel } from '../../models/WidgetViewModel';
import Button from '../atoms/button/Button';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import { BreakPoints } from '../../constants/breakPoints';
import { VideoPlayerWrapper } from '../video/VideoPlayerWrapper';
import { asEditable } from '../../helpers/sitecore';
import Text from '../atoms/text/Text';

interface TailoredWidgetProps extends WidgetViewModel {
  isMobileOrTablet: boolean;
}

interface TailoredWidgetState {
  isPlaying: boolean;
  mounted: boolean;
}

class TailoredWidget extends Component<TailoredWidgetProps, TailoredWidgetState> {
  state = {
    isPlaying: false,
    mounted: false,
  };

  /**
   * This forces a rerender on the client, otherwise the changes in HTML
   * are not detected.
   */
  componentDidMount(): void {
    this.setState({
      mounted: true,
    });
  }

  render() {
    const {
      isMobileOrTablet,
      isInEditMode,
      componentImage,
      componentTitle,
      componentText,
      componentLink,
      showButton,
      buttonText,
      textAlignment,
      colorCombination,
      videoId,
      setTextAtBottom,
    } = this.props;
    const { isPlaying } = this.state;

    return (
      <section
        onMouseEnter={() => (videoId ? this.setState(() => ({ isPlaying: true })) : undefined)}
        onMouseLeave={() => (videoId ? this.setState(() => ({ isPlaying: false })) : undefined)}
      >
        <div
          className={cx(
            'productwidget',
            'hasvideobackground',
            `productwidget--${colorCombination || 'theme-secondary'}`,
            'customwidget',
            `text--${textAlignment || 'left'}`,
            showButton && 'productwidget--withbutton'
          )}
        >
          {!isMobileOrTablet && videoId && !isInEditMode ? (
            <VideoPlayerWrapper
              className="videobackground"
              videoId={videoId}
              playing={isPlaying}
              isInEditMode={isInEditMode}
            />
          ) : (
            componentImage && <ImageHtml {...componentImage} />
          )}
          <Text {...componentTitle} className="heading" />
          <div
            className={cx(
              'tailored-content',
              setTextAtBottom && 'text--bottom',
              textAlignment && `text--${textAlignment}`
            )}
            dangerouslySetInnerHTML={asEditable(componentText)}
          />
          {showButton && (
            <Button
              text={buttonText}
              class="cta_button"
              url={componentLink ? componentLink.url : ''}
              target={componentLink ? componentLink.target : ''}
            />
          )}
        </div>
      </section>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobileOrTablet: width < BreakPoints.screenMd,
});

export default withSizes.withSizes(mapSizesToProps)(TailoredWidget);
