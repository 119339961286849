import { Component, createRef, RefObject } from 'react';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import { asEditable } from '../../helpers/sitecore';
import { SearchResultItemViewModel } from '../../models/SearchResultItemViewModel';

class SearchResultBlogPost extends Component<SearchResultItemViewModel> {
  private readonly searchResultAnchorRef!: RefObject<HTMLAnchorElement>;

  constructor(props: SearchResultItemViewModel) {
    super(props);
    this.searchResultAnchorRef = createRef<HTMLAnchorElement>();
    this.triggerSearchResultAnchor = this.triggerSearchResultAnchor.bind(this);
  }

  triggerSearchResultAnchor() {
    if (this.searchResultAnchorRef.current) {
      this.searchResultAnchorRef.current.click();
    }
  }

  render() {
    const { text, title, subTitle, type, image, buttonText, blogPost } = this.props;

    return (
      <div className="result result--blog">
        <div className="info" onClick={this.triggerSearchResultAnchor}>
          <div className="info__description" dangerouslySetInnerHTML={asEditable(text)} />
          {blogPost && (
            <div className="blog-info">
              <p dangerouslySetInnerHTML={asEditable(blogPost.mostRecentUpdateText)} />
              <p dangerouslySetInnerHTML={asEditable(blogPost.numberOfUpdatesText)} />
              <br />
            </div>
          )}
          {buttonText && <div className="cta_button">{buttonText}</div>}
        </div>

        <div className="journeywidget hasvideobackground">
          <div className="result-background">
            {image && image.html && <ImageHtml {...image} class="result-background__image" />}
          </div>
          <a className="result-content" href={`${blogPost ? blogPost.url : ''}`} ref={this.searchResultAnchorRef}>
            {type && <div className="topleft">{type && <span className="result-label">{type}</span>}</div>}

            <div className="tipbottom">
              {title && <h2 className="heading_blog">{title}</h2>}
              {blogPost && <p>{blogPost.date}</p>}
            </div>
            {subTitle && (
              <div className="tipbottom">
                <p className="tip_blog">{subTitle}</p>
              </div>
            )}
          </a>
        </div>
      </div>
    );
  }
}

export default SearchResultBlogPost;
