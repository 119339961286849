import { FunctionComponent } from 'react';
import { TableViewModel } from '../../models/TableViewModel';
import Table from '../table/Table';

const BlogEditTable: FunctionComponent<TableViewModel> = ({ ...props }) => (
  <div className="edit-blog-table">
    {props.componentTitle && <h2>{props.componentTitle}</h2>}
    <Table {...props} />
  </div>
);

export default BlogEditTable;
