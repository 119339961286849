import { FunctionComponent } from 'react';
import cx from 'classnames';
import { ImageHtml as ImageHtmlModel } from '../../../models/ImageHtml';

interface ImageHtmlProps extends ImageHtmlModel {
  class?: string;
  showCopyright?: boolean;
}

const ImageHtml: FunctionComponent<ImageHtmlProps> = ({ html, class: classValue, showCopyright, alt }) => {
  const props = {
    dangerouslySetInnerHTML: {
      __html: html || '',
    },
    className: cx(classValue, showCopyright && alt && 'withcopyright') || undefined,
    'data-copyright': alt || undefined,
  };

  return <div {...props} />;
};

export default ImageHtml;
