import { FunctionComponent } from 'react';
import { SpotlightHeaderViewModel } from '../../models/SpotlightHeaderViewModel';
import SpotlightHeader from './SpotlightHeader';
import TripWidgetTop from './widgets/TripWidgetTop';
import { asEditable } from '../../helpers/sitecore';
import ImageHtml from '../atoms/imageHtml/ImageHtml';

const TripWidgetBottom: FunctionComponent<SpotlightHeaderViewModel> = ({
  relatedThemes,
  price,
  priceDisclaimer,
  uniqueBuyingReasons,
  id,
}) => {
  const hasUniqueBuyingReasons = uniqueBuyingReasons && uniqueBuyingReasons.length > 0;

  if (!hasUniqueBuyingReasons && !price) {
    return null;
  }

  return (
    <div className="tripwidget-container">
      <div>
        {hasUniqueBuyingReasons && (
          <div className="unique-buying-reasons">
            <ul>
              {uniqueBuyingReasons &&
                uniqueBuyingReasons.map(reason => <li dangerouslySetInnerHTML={asEditable(reason)} key={reason} />)}
            </ul>
          </div>
        )}
        {price && (
          <div className="trip-info">
            {relatedThemes && (
              <div className="trip-themes">
                {relatedThemes.map(theme => {
                  const image = <ImageHtml {...theme.componentImageHtml} class="trip-themes-image" key={theme.id} />;

                  if (theme.url) {
                    return <a href={theme.url}>{image}</a>;
                  }

                  return image;
                })}
              </div>
            )}
            <div className="price">{price}</div>
            {priceDisclaimer && (
              <div
                className="price__pricedisclaimer htmlcontainer"
                dangerouslySetInnerHTML={asEditable(priceDisclaimer)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const SpotlightHeaderTrip: FunctionComponent<SpotlightHeaderViewModel> = props => (
  <SpotlightHeader {...props} className="trip">
    <TripWidgetTop {...props} />
    <TripWidgetBottom {...props} />
  </SpotlightHeader>
);

export default SpotlightHeaderTrip;
