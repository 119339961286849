import { Component, createRef, ReactNode, RefObject } from 'react';
import cx from 'classnames';

export interface MessageProps {
  scrollIntoView?: boolean;
  className?: string;
  children?: ReactNode;
}

class Message extends Component<MessageProps> {
  private readonly ref: RefObject<HTMLDivElement>;

  constructor(props: MessageProps) {
    super(props);
    this.ref = createRef<HTMLDivElement>();
  }

  componentDidMount(): void {
    if (this.props.scrollIntoView && this.ref.current) {
      this.ref.current.scrollIntoView(false);
    }
  }

  render() {
    return (
      <div ref={this.ref} className={cx('message', this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}

export default Message;
