import { Component } from 'react';
import cx from 'classnames';
import { getHashUrlOnly } from '../travelPartNavigation/TravelPartNavigation';
import { TravelPartsViewModel } from '../../models/TravelPartsViewModel';
import { Day } from './content/Day';
import Paging from '../atoms/paging/Paging';
const Scroll = require('react-scroll');
const Scroller = Scroll.scroller;

interface TravelPartContentState {
  inTransition: boolean;
  index: number;
  isFirstRender: boolean;
}

interface TravelPartContentProps extends TravelPartsViewModel {
  className?: string;
}

const isServerSide = typeof window === 'undefined';

export default class TravelPartContent extends Component<TravelPartContentProps, TravelPartContentState> {
  state = {
    inTransition: false,
    index: 0,
    isFirstRender: true,
  };

  setActiveDay = () => {
    const { travelParts } = this.props;
    const { isFirstRender } = this.state;
    const hashValue = getHashUrlOnly();
    const index = !hashValue ? 0 : travelParts.findIndex(day => day.id === hashValue);

    this.setState(
      {
        inTransition: true,
        index: index === -1 ? 0 : index,
        isFirstRender: false,
      },
      () => {
        this.setState({
          inTransition: false,
        });
      }
    );

    if (!isFirstRender) {
      Scroller.scrollTo('dayContent', {
        duration: 200,
        smooth: true,
        offset: -300,
      });
    }
  };

  componentDidMount() {
    this.setActiveDay();
    window.addEventListener('hashchange', this.setActiveDay);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.setActiveDay);
  }

  render() {
    const { index, inTransition } = this.state;
    const { travelParts, className } = this.props;

    // On server side: render all days.
    if (isServerSide) {
      return (
        <div className={cx('travelpartcontent', className)}>
          {travelParts.map(tp => (
            <Day key={tp.id} {...tp} dayTransition={false} />
          ))}
        </div>
      );
    }

    const travelPart = travelParts[index];

    // A section is rendered here in order to prevent react from reusing the divs client side
    // and rendering the selected day wrong.
    return (
      <section className={cx('travelpartcontent', className)}>
        <Paging
          key="paging"
          previousUrl={(index > 0 && travelParts[index - 1].componentLink.url) || undefined}
          nextUrl={(index < travelParts.length - 1 && travelParts[index + 1].componentLink.url) || undefined}
        />

        {<Day key="day" {...travelPart} dayTransition={inTransition} />}
      </section>
    );
  }
}
