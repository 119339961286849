import { ChangeEvent, Component, ReactNode } from 'react';
import cx from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import ValidationError from './atoms/ValidationError';
import FormItem from './atoms/FormItem';

export interface CheckBoxesProps<TItem> extends FieldRenderProps<string> {
  labelOf: (item: TItem) => ReactNode;
  valueOf: (item: TItem) => string;
  options: TItem[];
}

class RadioButtons<TItem> extends Component<CheckBoxesProps<TItem>> {
  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { input } = this.props;
    input.onChange(e.currentTarget.value);
  };

  render() {
    const { input, meta, options, labelOf, valueOf, ...rest } = this.props;

    const value = input.value || undefined;

    return (
      <FormItem meta={meta} {...rest}>
        <div className="form-item-value">
          {options.map(option => {
            const optionValue = valueOf(option);
            return (
              <label
                key={optionValue}
                className={cx('form-subitem form-subitem--checkbox', meta.error ? 'form-item-error' : '')}
              >
                <input
                  {...input}
                  type="radio"
                  onChange={this.handleChange}
                  checked={value === optionValue}
                  value={optionValue}
                  name={input.name}
                />
                {labelOf(option)}
              </label>
            );
          })}
        </div>
        <ValidationError meta={meta} />
      </FormItem>
    );
  }
}

export default RadioButtons;
