/* global BrowserImageResizer, Sentry */
import { Component, createRef, RefObject, SyntheticEvent } from 'react';
import { FieldRenderProps } from 'react-final-form';
import FormItem from './atoms/FormItem';
import ValidationError from './atoms/ValidationError';
import Image from '../../atoms/image/Image';

const resizerScriptUrl = 'https://cdn.jsdelivr.net/gh/ericnograles/browser-image-resizer@2.4.0/dist/index.js';
let hasResizerBeenLoaded = false;

export interface UploadButtonProps extends FieldRenderProps<string | File> {
  label?: string;
  className?: string;
  type?: string;
  required?: boolean;
  selectedText?: string;
  fileTypes: string[];
}

interface UploadButtonState {
  dataUrl?: string;
}

const getFileName = (file?: string | File): [string?, string?] => {
  if (!file || typeof file === 'string') {
    return [undefined, undefined];
  }

  const result: string = file.name;

  let resultShort = result;
  if (resultShort.length > 20) {
    resultShort = result.substring(0, 10) + '…' + result.substring(result.length - 10);
  }

  return [result, resultShort];
};

class UploadButton extends Component<UploadButtonProps, UploadButtonState> {
  state: UploadButtonState = {};

  private readonly uploadButtonRef!: RefObject<HTMLInputElement>;

  constructor(props: UploadButtonProps) {
    super(props);
    this.uploadButtonRef = createRef<HTMLInputElement>();
  }

  componentDidMount(): void {
    if (hasResizerBeenLoaded) {
      return;
    }

    if (document.head) {
      hasResizerBeenLoaded = true;
      const script: HTMLScriptElement = document.createElement('script');
      script.src = resizerScriptUrl;
      script.type = 'text/javascript';
      document.head.appendChild(script);
    }
  }

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files || e.currentTarget.files.length === 0) {
      return;
    }

    const file = e.currentTarget.files[0];
    if (this.props.fileTypes.indexOf(file.type) === -1) {
      return;
    }

    if ('BrowserImageResizer' in window && file.type === 'image/jpeg') {
      console.log('Going to do autorotate');
      // @ts-ignore TS2304
      BrowserImageResizer.readAndCompressImage(file, {
        quality: 0.85,
        maxWidth: 2048,
        maxHeight: 2048,
        debug: process.env.NODE_ENV !== 'production',
      })
        .then((blob: Blob) => {
          // @ts-ignore TS2339
          blob.name = file.name;
          this.handleFile(blob);
        })
        .catch(error => {
          // @ts-ignore TS2304
          if (Sentry) {
            // @ts-ignore TS2304
            Sentry.captureException(error);
          }

          this.handleFile(file);
        });
    } else {
      this.handleFile(file);
    }
  };

  handleFile = (file: Blob) => {
    this.props.input.onChange(file);
    if (file.type === 'image/jpeg') {
      const reader = new FileReader();
      reader.onload = () => {
        this.setState(() => ({
          dataUrl: typeof reader.result === 'string' ? reader.result : undefined,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  handleDeleteClick = () => {
    this.props.input.onChange(null);

    // Also clear the value of the input button,
    // otherwise nothing happens when the user reselects the previous image.
    if (this.uploadButtonRef.current) {
      this.uploadButtonRef.current.value = '';
    }
  };

  triggerUploadButton = () => {
    if (this.uploadButtonRef.current) {
      this.uploadButtonRef.current.click();
    }
  };

  render() {
    const { input, meta, selectedText, fileTypes, ...rest } = this.props;
    const { dataUrl } = this.state;

    const { value, ...restOfInput } = input;

    // Use value if it is a string, use dataUrl if value is not a string and undefined in all other cases.
    const src = typeof value === 'string' ? value : value && dataUrl ? dataUrl : undefined;
    const [fileName, fileNameShort] = getFileName(value);

    return (
      <FormItem meta={meta} {...rest}>
        <div className="form-item-value uploadbutton">
          <input
            {...restOfInput}
            required={rest.required}
            type="file"
            ref={this.uploadButtonRef}
            onChange={this.handleChange}
            accept={fileTypes.join(',')}
          />
          {src && <Image src={src} height="100" width="100" class="uploadbutton__file" alt={fileName || ''} />}
          {!src && <div className="uploadbutton__file" />}
          <div className="uploadbutton__select">
            <input type="button" value={rest.label} className="cta_button" onClick={this.triggerUploadButton} />
            {value && (
              <button
                className="cta_button cta_button--secondary uploadbutton__delete"
                type="button"
                onClick={this.handleDeleteClick}
              >
                Verwijder
              </button>
            )}
            {fileName && <label htmlFor={input.name} title={fileName}>{`${selectedText}: ${fileNameShort}`}</label>}
          </div>
        </div>
        <ValidationError meta={meta} />
      </FormItem>
    );
  }
}

export default UploadButton;
