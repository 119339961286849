import { FunctionComponent, MouseEventHandler } from 'react';
import { MenuItemViewModel } from '../../models/MenuItemViewModel';
import Link from '../atoms/link/Link';

export interface MenuItemProps extends MenuItemViewModel {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const MenuItem: FunctionComponent<MenuItemProps> = ({ url, target, title, classValue, onClick }) => (
  <Link url={url} text={title} target={target} class={classValue} onClick={onClick} />
);
export default MenuItem;
