/* global google */
import { Component, createRef, RefObject } from 'react';
import GoogleMapReact, { Coords } from 'google-map-react';
import Marker from './marker/Marker';
import { RouteMapMarker } from '../../models/RouteMapMarker';
import { MapHeaderViewModel } from '../../models/MapHeaderViewModel';
import { centerMap } from '../../helpers/map';
import { mapStyles } from '../mapBlock/style';
import { getHashUrlOnly } from '../travelPartNavigation/TravelPartNavigation';

const mapMarkerHasInfo = (marker: RouteMapMarker) => {
  return marker.title || marker.subTitle || (marker.links && marker.links.length > 0) || marker.icon;
};

export default class MapHeaderGoogleMapsRoute extends Component<MapHeaderViewModel> {
  map!: google.maps.Map;
  infowindow!: google.maps.InfoWindow;
  private readonly containerRef!: RefObject<HTMLDivElement>;

  constructor(props: MapHeaderViewModel) {
    super(props);

    this.state = {
      activeUrl: this.props.routeMapMarkers && this.props.routeMapMarkers[0].id ? this.props.routeMapMarkers[0].id : '',
    };

    this.handleGoogleApiLoaded = this.handleGoogleApiLoaded.bind(this);
    this.openInfoWindow = this.openInfoWindow.bind(this);
    this.hashChangeHandler = this.hashChangeHandler.bind(this);
    this.getActiveMarker = this.getActiveMarker.bind(this);

    this.containerRef = createRef<HTMLDivElement>();
  }

  componentDidMount() {
    if (!this.containerRef.current) {
      return;
    }

    window.addEventListener('hashchange', this.hashChangeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.hashChangeHandler);
  }

  hashChangeHandler() {
    const activeMarker = this.getActiveMarker();

    if (activeMarker) {
      this.openInfoWindow(activeMarker);
    }
  }

  handleGoogleApiLoaded({ map }: { map: google.maps.Map }) {
    if (!map) {
      return;
    }

    this.map = map;
    this.infowindow = new google.maps.InfoWindow();

    if (this.props.routeMapMarkers) {
      centerMap(map, this.props.routeMapMarkers, {
        top: 80,
        right: 0,
        bottom: 0,
        left: 0,
      });
    }

    const zoom = map.getZoom();
    if (zoom === undefined || zoom > 10) {
      map.setZoom(10);
    }

    this.drawRoute();

    const activeMarker = this.getActiveMarker();

    if (activeMarker) {
      this.openInfoWindow(activeMarker);
    }
  }

  getActiveMarker(): RouteMapMarker | undefined {
    if (!this.props.routeMapMarkers) {
      return;
    }

    const activeUrl = window.location.hash ? getHashUrlOnly() : this.props.routeMapMarkers[0].id;
    const activeMarkerIndex = this.props.routeMapMarkers.findIndex(marker => marker.id === activeUrl);

    if (activeMarkerIndex < 0) {
      return;
    }

    return this.props.routeMapMarkers[activeMarkerIndex];
  }

  getDefaultCenter(): Coords | undefined {
    const mapMarkers = this.props.routeMapMarkers;

    if (!mapMarkers || mapMarkers.length === 0) {
      return undefined;
    }

    return {
      lat: mapMarkers[0].latitude,
      lng: mapMarkers[0].longitude,
    };
  }

  drawRoute() {
    const coords =
      this.props.routeMapMarkers &&
      this.props.routeMapMarkers.map(marker => {
        return {
          lat: marker.latitude,
          lng: marker.longitude,
        };
      });

    if (!coords || coords.length === 0) {
      return;
    }

    const route = new google.maps.Polyline({
      path: coords,
      geodesic: true,
      strokeColor: '#34444a',
      strokeOpacity: 1.0,
      strokeWeight: 5,
    });

    route.setMap(this.map);
  }

  openInfoWindow(marker: RouteMapMarker) {
    if (!this.infowindow) {
      // Google Maps may be slow to load.
      return;
    }

    if (!marker || !marker.latitude || !marker.longitude) {
      this.infowindow.close();
      return;
    }

    this.infowindow.setPosition({
      lat: marker.latitude,
      lng: marker.longitude,
    });

    if (marker.title || marker.links) {
      const content = `
                <div class="info-window">
                ${marker.title ? `<p class="info-window__title">${marker.title}</p>` : ''}
                ${
                  marker.links
                    ? `<ul class="info-window__list">
                        ${marker.links.map(link => `<li><a href="${link.url}">${link.text}</a></li>`).join('')}
                    </ul>
                    `
                    : ''
                }
                </div>
            `;

      this.infowindow.setContent(content);
      this.infowindow.open(this.map);
    } else {
      this.infowindow.close();
    }
  }

  render() {
    const { mapsApiKey, routeMapMarkers } = this.props;

    return (
      <div className="spotlight-header-wrapper map">
        <section className="spotlight-header">
          <div className="spotlight-header__media-background" ref={this.containerRef}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: mapsApiKey || '', language: 'nl' }}
              defaultZoom={11}
              defaultCenter={this.getDefaultCenter()}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={this.handleGoogleApiLoaded}
              options={{ styles: mapStyles }}
            >
              {routeMapMarkers &&
                routeMapMarkers
                  .filter(mapMarkerHasInfo)
                  .map((mapMarker, index) => (
                    <Marker
                      key={mapMarker.id || index}
                      lat={mapMarker.latitude}
                      lng={mapMarker.longitude}
                      icon={mapMarker.icon ? mapMarker.icon : ''}
                      onClick={() => this.openInfoWindow(mapMarker)}
                    />
                  ))}
            </GoogleMapReact>
          </div>
        </section>
      </div>
    );
  }
}
