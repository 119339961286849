import { FunctionComponent } from 'react';
import { SpotlightHeaderViewModel } from '../../../models/SpotlightHeaderViewModel';
import Text from '../../atoms/text/Text';
import { asEditable } from '../../../helpers/sitecore';

const TripWidgetTop: FunctionComponent<SpotlightHeaderViewModel> = ({ componentTitle, componentSubTitle }) => (
  <div className="headercountryinner">
    <Text {...componentTitle} />
    {componentSubTitle && <h2 dangerouslySetInnerHTML={asEditable(componentSubTitle)} />}
  </div>
);

export default TripWidgetTop;
