import { FunctionComponent } from 'react';
import { Link as ILink } from '../../models/Link';
import Link from '../atoms/link/Link';

const MinisiteBackButton: FunctionComponent<ILink> = props => (
  <div className="minisite__backbutton">
    <Link {...props} class="cta_button" />
  </div>
);

export default MinisiteBackButton;
