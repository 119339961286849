import { FunctionComponent } from 'react';
import { FooterViewModel } from '../../models/FooterViewModel';
import { asEditable } from '../../helpers/sitecore';

const FooterLogos: FunctionComponent<FooterViewModel> = ({ logos, colorCombination }: FooterViewModel) => (
  <div className={`footercontainer footercontainer__${colorCombination || 'theme-primary'}`}>
    {logos && logos.length > 0 && (
      <div className="footerrow footer-logos" dangerouslySetInnerHTML={asEditable(logos.join(''))} />
    )}
  </div>
);

export default FooterLogos;
