import { Component, MouseEvent } from 'react';
import qs from 'qs';
import withSizes from 'react-sizes';
import { BreakPoints } from '../../constants/breakPoints';

interface PaginationProps {
  totalResults: number;
  size: number;
  activePage: number;
  onActivePageChange?: (activePage: number) => void;
  url: string;
  isXs: boolean;
}

/**
 * Change url to contain the given page number.
 *
 * If page = 1 the page number is removed.
 *
 * @param url
 * @param page
 */
function setPageQueryString(url: string | undefined, page: number): string {
  if (!url) {
    return '#';
  }

  const [baseurl, query] = url.split('?');

  const queryParsed = qs.parse(query);

  const queryResult = qs.stringify({
    ...queryParsed,
    p: page <= 1 ? undefined : page,
  });

  if (!queryResult) {
    return baseurl;
  }

  return `${baseurl}?${queryResult}`;
}

class Pagination extends Component<PaginationProps> {
  constructor(props: PaginationProps) {
    super(props);

    this.increaseActivePage = this.increaseActivePage.bind(this);
    this.decreaseActivePage = this.decreaseActivePage.bind(this);
  }

  getTotalPages(): number {
    const { totalResults, size } = this.props;

    return Math.ceil(totalResults / size);
  }

  increaseActivePage(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    this.setActivePage(1);
  }

  decreaseActivePage(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    this.setActivePage(-1);
  }

  setActivePage(change: number) {
    const { onActivePageChange, activePage } = this.props;

    if (!onActivePageChange) {
      return;
    }

    onActivePageChange(activePage + change);
  }

  render() {
    const { activePage, url, isXs } = this.props;

    const totalPages = this.getTotalPages();
    if (totalPages === 0) {
      return null;
    }

    return (
      <div className="paging">
        {activePage !== 1 && (
          <a href={setPageQueryString(url, activePage - 1)} onClick={this.decreaseActivePage}>
            &lt;
          </a>
        )}

        <div className={activePage === 1 ? 'extra-margin' : ''}>
          {isXs ? ` ${activePage} / ${totalPages} ` : ` Pagina ${activePage} van ${totalPages} `}
        </div>

        {activePage < totalPages && (
          <a href={setPageQueryString(url, activePage + 1)} onClick={this.increaseActivePage}>
            &gt;
          </a>
        )}
      </div>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isXs: width < BreakPoints.screenXs,
});

export default withSizes(mapSizesToProps)(Pagination);
