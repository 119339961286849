/* global GTHEmbed */
/* eslint-disable jsx-a11y/iframe-has-title */
import { Component } from 'react';
import cx from 'classnames';
import { FrameViewModel } from '../../models/FrameViewModel';

interface FrameState {
  target?: string;
  scriptloaded?: boolean;
  pageloaded?: boolean;
}

const shouldUseScript = (host: string | undefined): boolean => !!host;
const isServerSide = typeof window === 'undefined';

// These components will be notified whenever the loader script is loaded.
const components: Frame[] = [];

export default class Frame extends Component<FrameViewModel, FrameState> {
  constructor(props: FrameViewModel) {
    super(props);

    if (shouldUseScript(props.host)) {
      this.state = {
        target: `target-${props.id}`,
        scriptloaded: !isServerSide && 'GTHEmbed' in window,
        pageloaded: false,
      };
    } else {
      this.state = {};
    }
  }

  componentDidMount() {
    // Load the loader script when this frame requires it, is not already loaded and is not server side.
    if (this.state.target && !this.state.scriptloaded && !isServerSide) {
      components.push(this);
      if (components.length === 1) {
        const script = document.createElement('script');
        script.src = 'https://wizard.travelhome.nl/_js/embed.js';
        script.onload = function () {
          components.forEach((component: Frame) => {
            component.setState(() => ({
              scriptloaded: true,
            }));
          });
        };
        if (document.head) {
          document.head.appendChild(script);
        }
      }
    }
  }

  loadEmbed = () => {
    const { target } = this.state;
    const { url, width, host } = this.props;
    // @ts-ignore TS2304
    GTHEmbed.load(target, host, {
      ssl: true,
      width: width || '100%',
      startpage: url,
      scrollOffset: () => {
        const element = document.querySelector('.header-overlay') as HTMLElement | null;
        if (element && element.offsetHeight) {
          return element.offsetHeight;
        }
        return 0;
      },
    });

    this.setState(() => ({ pageloaded: true }));
  };

  render() {
    const { target } = this.state;
    const { url, extraMarginTop, height, width, host } = this.props;

    if (target) {
      if (!host) {
        return null;
      }

      if (this.state.scriptloaded && !this.state.pageloaded) {
        // Load the initialization script after the loader script has loaded and after the HTML was written.
        window.setTimeout(this.loadEmbed, 500);
      }

      return <div id={target} className={cx('framecontainer', extraMarginTop && 'extra-margin-top')} />;
    }

    return (
      <iframe
        src={url}
        frameBorder="0"
        height={height || 'auto'}
        width={width || '100%'}
        style={{ width: '100%' }}
        className={cx('frame', extraMarginTop && 'extra-margin-top')}
      />
    );
  }
}
