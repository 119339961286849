import { FunctionComponent } from 'react';
import { ContentBlockViewModel } from '../../models/ContentBlockViewModel';
import Text from '../atoms/text/Text';
import { asEditable } from '../../helpers/sitecore';
import { Placeholder } from '../structure/Placeholder';

const ExpandableContentBlock: FunctionComponent<ContentBlockViewModel> = ({
  componentTitle,
  componentText,
  isInEditMode,
  placeholderContent,
}) => (
  <section>
    <details className="margin-bottom" open={isInEditMode}>
      {componentTitle && <Text {...componentTitle} tag="summary" />}
      <div dangerouslySetInnerHTML={asEditable(componentText)} />
      {placeholderContent && <Placeholder placeholders={[placeholderContent]} placeholderIndex={0} />}
    </details>
  </section>
);

export default ExpandableContentBlock;
