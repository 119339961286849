import { Component, createRef, FunctionComponent, RefObject } from 'react';
import cx from 'classnames';
import { parseISO, format } from 'date-fns';
import ReactHoverObserver from 'react-hover-observer/dist/ReactHoverObserver';
import { VideoPlayerWrapper } from '../video/VideoPlayerWrapper';
import Stars from '../atoms/stars/Stars';
import { SearchResultItemViewModel } from '../../models/SearchResultItemViewModel';
import { BreakPoints } from '../../constants/breakPoints';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import { asEditable } from '../../helpers/sitecore';
import { SearchResultIconViewModel } from '../../models/SearchResultIconViewModel';
import Text from '../atoms/text/Text';

export interface SearchResultProps extends SearchResultItemViewModel {
  isHovering?: boolean;
  numberOfPersons?: number;
  priceDate?: string;
  isMobile?: boolean;
  headingTag?: string;
}

interface SearchResultState {
  initialized: boolean;
  isMobile?: boolean;
}

const withSizes = require('react-sizes');

const SearchResultIcon: FunctionComponent<SearchResultIconViewModel> = icon => {
  const image = <img src={icon.imageUrl} title={icon.title} alt={icon.title} />;

  if (icon.url) {
    return (
      <a href={icon.url} className="info__icons__icon">
        {image}
      </a>
    );
  }

  return <div className="info__icons__icon">{image}</div>;
};

class SearchResult extends Component<SearchResultProps, SearchResultState> {
  private readonly searchResultAnchorRef!: RefObject<HTMLAnchorElement>;

  constructor(props: SearchResultProps) {
    super(props);
    this.searchResultAnchorRef = createRef<HTMLAnchorElement>();
    this.triggerSearchResultAnchor = this.triggerSearchResultAnchor.bind(this);
    this.state = {
      initialized: false,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.setState({ initialized: true });

    if (this.props.isMobile) {
      this.setState({ isMobile: true });
    }
  }

  componentDidUpdate(prevProps: SearchResultProps) {
    if (prevProps.isMobile !== this.props.isMobile) {
      this.setState({
        isMobile: this.props.isMobile,
      });
    }
  }

  getQueryStringFilters() {
    const { numberOfPersons, priceDate } = this.props;
    const querystring: string[] = [];

    if (!priceDate && !numberOfPersons) {
      return '';
    }

    if (numberOfPersons) {
      querystring.push(`persons=${numberOfPersons}`);
    }

    if (priceDate) {
      // format the date, so it's readable from the querystring.
      const date = parseISO(priceDate);
      querystring.push(`startDate=${format(date, 'yyyy-MM-dd')}`);
    }

    return `?${querystring.join('&')}`;
  }

  triggerSearchResultAnchor() {
    if (this.searchResultAnchorRef.current) {
      this.searchResultAnchorRef.current.click();
    }
  }

  render() {
    const {
      text,
      priceLabel,
      priceValue,
      videoId,
      isInEditMode,
      rating,
      title,
      subTitle,
      ternaryTitle,
      relatedTourleader,
      type,
      image,
      url,
      buttonText,
      icons,
      priceDescription,
      headingTag,
    } = this.props;

    const { isMobile } = this.state;

    const queryStringFilters = this.getQueryStringFilters();

    return (
      <>
        <div
          className={cx('info', priceDescription && 'info--withpricedescription')}
          onClick={this.triggerSearchResultAnchor}
        >
          {icons && (
            <div
              className={cx(
                'info__icons',
                icons.length >= 7 && 'info__icons--many',
                icons.length <= 4 && 'info__icons--few'
              )}
            >
              {icons.map((icon, index) => (
                <SearchResultIcon key={`${icon.title}${index}`} {...icon} />
              ))}
            </div>
          )}
          <div className="info__description" dangerouslySetInnerHTML={asEditable(text)} />
          {priceValue && (
            <div className="cta_button cta_button--price">
              <span className="pricelabel">{priceLabel}</span>
              <span className="pricevalue">{priceValue}</span>
            </div>
          )}

          {!priceValue && buttonText && <div className="cta_button">{buttonText}</div>}

          {priceDescription && (
            <div className="result__pricedescription" dangerouslySetInnerHTML={asEditable(priceDescription)}></div>
          )}
        </div>

        <div className="journeywidget hasvideobackground">
          <div className="result-background">
            {!isMobile && videoId && (
              <VideoPlayerWrapper videoId={videoId} playing={!!this.props.isHovering} isInEditMode={isInEditMode} />
            )}
            {image && image.html && <ImageHtml {...image} class="result-background__image" />}
          </div>
          <a className="result-content" href={`${url}${queryStringFilters}`} ref={this.searchResultAnchorRef}>
            {(rating || type) && (
              <div className="topleft">
                {!!rating && <Stars count={rating} />}
                {type && <span className="result-label">{type}</span>}
              </div>
            )}

            {relatedTourleader && (
              <div className="tipbottom tipleft">
                <span className="tip">
                  {relatedTourleader.componentImageHtml && (
                    <>
                      <ImageHtml {...relatedTourleader.componentImageHtml} />
                      <br />
                    </>
                  )}
                  {relatedTourleader.componentText}
                </span>
              </div>
            )}

            <div className="tipbottom">
              {subTitle && <p className="preheader">{subTitle}</p>}
              {title && <Text className="heading" value={title} tag={headingTag || 'p'} />}
              {ternaryTitle && (
                <p>
                  {ternaryTitle}
                  <br />
                </p>
              )}
            </div>
          </a>
        </div>
      </>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < BreakPoints.screenSm,
});

export default withSizes.withSizes(mapSizesToProps)((props: SearchResultProps) => (
  <ReactHoverObserver hoverDelayInMs={250} hoverOffDelayInMs={250} className="result">
    <SearchResult {...props} />
  </ReactHoverObserver>
));
