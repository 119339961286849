/* global Sentry */

const loadtime = new Date();

if (typeof window !== 'undefined' && 'Sentry' in window) {
  const rootelement = document.querySelector('html');

  // @ts-ignore TS2304
  Sentry.onLoad(function () {
    // @ts-ignore TS2304
    Sentry.init({
      release: `travelhome-website@${(rootelement && rootelement.dataset.version) || '0.0.0.1'}`,
      environment: (rootelement && rootelement.dataset.env) || 'unknown',
      autoSessionTracking: false,
      ignoreErrors: [
        'Could not load "stats"',
        'Object Not Found Matching Id',
        "evaluating 'r.lat'",
        "evaluating 'this.getPanes().overlayMouseTarget'",
        'webkitExitFullScreen',
      ],
    });
    // @ts-ignore TS2304
    Sentry.addBreadcrumb({
      category: 'load',
      message: `Site was loaded at ${loadtime}`,
      level: 'info',
    });
  });
}

// 'fix' TS1208.
export {};
