import { FunctionComponent } from 'react';
import cx from 'classnames';
import MenuItem from '../../menuItem/MenuItem';
import { MenuViewModel } from '../../../models/MenuViewModel';

export interface MiniMenuProps extends MenuViewModel {
  onSelect?: (id: string) => void;
}

const MiniMenu: FunctionComponent<MiniMenuProps> = ({ items, onSelect }: MiniMenuProps) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <nav className="minimenu">
      {items.map(item => (
        <MenuItem
          key={item.id || item.url}
          {...item}
          classValue={cx('minimenu__item', item.selected && 'minimenu__item--active')}
          onClick={
            onSelect &&
            (e => {
              onSelect(item.id);
              e.preventDefault();
            })
          }
        />
      ))}
    </nav>
  );
};

export default MiniMenu;
