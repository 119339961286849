import { FunctionComponent } from 'react';
import SpotlightHeader from './SpotlightHeader';
import { SpotlightHeaderCustomerAreaViewModel } from '../../models/SpotlightHeaderCustomerAreaViewModel';
import { asEditable } from '../../helpers/sitecore';
import Link from '../atoms/link/Link';
import CustomerLogout from '../customerLogin/CustomerLogout';
import withSizes from 'react-sizes';
import { BreakPoints } from '../../constants/breakPoints';

interface SpotlightHeaderCustomerAreaProps extends SpotlightHeaderCustomerAreaViewModel {
  isXs: boolean;
}

const SpotlightHeaderCustomerArea: FunctionComponent<SpotlightHeaderCustomerAreaProps> = props => (
  <SpotlightHeader {...props} className="customerarea">
    <div className="spotlightheadercustomerarea">
      <div className="boxed boxed--firstcolumnfirst">
        <div className="spotlightheadercustomerarea__column col1-3">
          <p className="heading">{props.isOffer ? 'Mijn reisvoorstel' : 'Mijn reis'}</p>
          <div className="boxed">
            <div className="col1-2">{props.isXs ? 'Boekingsnr.' : 'Boekingsnummer'}:</div>
            <div className="col1-2">{props.reservationNumber}</div>
          </div>
          <div className="boxed">
            <div className="col1-2">Vertrekdatum:</div>
            <div className="col1-2">
              {props.departureDate}
              {props.remainingDays === 1 && ` (nog 1 dag)`}
              {props.remainingDays && props.remainingDays > 1 && ` (nog ${props.remainingDays} dagen)`}
            </div>
          </div>
          {props.destination && (
            <div className="boxed">
              <div className="col1-2">Bestemming:</div>
              <div className="col1-2">{props.destination}</div>
            </div>
          )}
          {props.vehicleType && (
            <div className="boxed">
              <div className="col1-2">Campertype:</div>
              <div className="col1-2">{props.vehicleType}</div>
            </div>
          )}
          <div className="boxed">
            <div className="col1-2">Duur:</div>
            <div className="col1-2">{props.duration}</div>
          </div>
        </div>
        <div className="spotlightheadercustomerarea__column col1-3">
          <p className="heading">Reishistorie</p>
          {props.trips.length > 0 && (
            <ul className="spotlightheadercustomerarea__trips">
              {props.trips.map(trip => (
                <li key={trip.url}>
                  <Link {...trip} />
                </li>
              ))}
            </ul>
          )}
          {props.trips.length === 0 && (
            <p>
              Je hebt nog geen andere reizen bij Travelhome geboekt of deze reizen zijn gemaakt met een ander
              klantnummer.
            </p>
          )}
        </div>
        <div className="spotlightheadercustomerarea__column col1-3">
          <p className="heading">Mijn profiel</p>
          <p dangerouslySetInnerHTML={asEditable(props.customerInfo)} />

          <CustomerLogout {...props.logout} />
        </div>
      </div>
    </div>
  </SpotlightHeader>
);

const mapSizesToProps = ({ width }) => ({
  isXs: width < BreakPoints.screenXs,
});

export default withSizes(mapSizesToProps)(SpotlightHeaderCustomerArea);
