import { Component } from 'react';
import { BlogCommentsViewModel } from '../../models/BlogCommentsViewModel';
import BlogComment from './BlogComment';
import { getHashUrlOnly } from '../travelPartNavigation/TravelPartNavigation';
import { BlogPostComment } from '../../models/BlogPostComment';
import PartialScreenLoader from '../atoms/loader/PartialScreenLoader';
import { BlogCommentsApiViewModel } from '../../models/BlogCommentsApiViewModel';
import BlogCommentsForm from './BlogCommentsForm';
import { asEditable } from '../../helpers/sitecore';
import { handleError, jsonOrThrow } from '../../helpers/fetch';

interface BlogPostCommentsState {
  blogPostComments: BlogPostComment[];
  loading: boolean;
  noCommentsText: string;
  activeBlogPostId: string;
}

class BlogComments extends Component<BlogCommentsViewModel, BlogPostCommentsState> {
  loadingTimeoutId!: number;
  loadingTimeout = 25;

  constructor(props: BlogCommentsViewModel) {
    super(props);

    this.state = {
      blogPostComments: [],
      loading: false,
      noCommentsText: this.props.noCommentsText || '',
      activeBlogPostId: '',
    };

    this.fetchBlogPostComments = this.fetchBlogPostComments.bind(this);
    this.getActiveBlogPost = this.getActiveBlogPost.bind(this);
  }

  showLoader = () => {
    this.setState({
      loading: true,
    });
  };

  fetchBlogPostComments(blogPostId: string) {
    this.loadingTimeoutId = window.setTimeout(this.showLoader, this.loadingTimeout);

    fetch(`${this.props.apiUrl}?id=${blogPostId}`)
      .then(jsonOrThrow)
      .then((results: BlogCommentsApiViewModel) => {
        clearTimeout(this.loadingTimeoutId);

        this.setState({
          blogPostComments: results.comments,
          loading: false,
          noCommentsText: results.noCommentsText || '',
          activeBlogPostId: blogPostId,
        });
      })
      .catch(err => {
        handleError('Error while fetching blogpost comments', err);

        this.setState({
          loading: false,
        });
      });
  }

  getActiveBlogPost() {
    const blogPostId = getHashUrlOnly() || this.props.firstBlogPostId;

    if (blogPostId) {
      this.fetchBlogPostComments(blogPostId);
    }
  }

  componentDidMount() {
    this.getActiveBlogPost();

    window.addEventListener('hashchange', this.getActiveBlogPost);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.getActiveBlogPost);
  }

  render() {
    const { componentTitle, loadingText, blogCommentsForm, firstBlogPostId } = this.props;
    const { blogPostComments, loading, noCommentsText, activeBlogPostId } = this.state;

    return (
      <section className="reactions">
        <p
          className="heading"
          dangerouslySetInnerHTML={{
            __html: componentTitle ? componentTitle : '',
          }}
        />
        {loading && <PartialScreenLoader loadingText={loadingText} />}
        {blogPostComments && blogPostComments.length > 0 ? (
          blogPostComments.map(comment => {
            return comment.text && <BlogComment {...comment} />;
          })
        ) : (
          <div dangerouslySetInnerHTML={asEditable(noCommentsText)} />
        )}
        {blogCommentsForm && (
          <BlogCommentsForm
            {...blogCommentsForm}
            activeBlogPostId={activeBlogPostId || firstBlogPostId}
            fetchComments={this.fetchBlogPostComments}
          />
        )}
      </section>
    );
  }
}

export default BlogComments;
