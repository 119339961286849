import { FORM_ERROR, FormApi, SubmissionErrors } from 'final-form';
import { GenericFormViewModel } from '../models/GenericFormViewModel';
import { BlogEditViewModel } from '../models/BlogEditViewModel';
import { MapMarker } from '../models/MapMarker';
import { handleError } from './fetch';

const errorText = 'Fout tijdens versturen. Probeer het later opnieuw.';

const getRequestInit = (formData: FormData): RequestInit => ({
  method: 'POST',
  credentials: 'include', // same-origin is not supported by unfetch.
  body: formData,
});

export function submitForm<TFormValues>(
  formProps: GenericFormViewModel,
  initialValues: TFormValues
): (values: TFormValues, form: FormApi<TFormValues>) => Promise<SubmissionErrors | undefined> {
  return (values: TFormValues, form: FormApi<TFormValues>) =>
    fetch(formProps.apiUrl, getRequestInit(GetFormData<TFormValues>(formProps, values)))
      .then((response: Response) => handleResponse(response, formProps))
      .then(errors => {
        if (errors) {
          return errors;
        }
        form.initialize(initialValues);
      })
      .catch(handleFormError);
}

function handleResponse(response: Response, formProps: GenericFormViewModel) {
  if (response.status === 201) {
    if (formProps.successDestination) {
      window.location.href = formProps.successDestination;
    }
    return;
  }
  if (response.status === 500) {
    return {
      [FORM_ERROR]: errorText,
    };
  }

  return response.json();
}

function handleFormError(error: Error): SubmissionErrors {
  handleError('Error while submitting form', error);

  return {
    [FORM_ERROR]: errorText,
  };
}

function GetFormData<TFormValues = undefined>(
  formProps: GenericFormViewModel,
  values: TFormValues | undefined = undefined
) {
  const formData = new FormData();

  formData.append('__RequestVerificationToken', formProps.csrfToken);
  if (formProps.pageId) {
    formData.append('pageId', formProps.pageId);
  }
  if (formProps.formId) {
    formData.append('formId', formProps.formId);
  }

  if (values !== undefined && values !== null) {
    for (const key of Object.keys(values)) {
      const value = values[key];
      if (value instanceof Blob && 'name' in value) {
        // @ts-ignore TS2339
        formData.append(key, value, value.name);
      } else if (Array.isArray(value)) {
        for (const subValue of value) {
          formData.append(key, subValue);
        }
      } else {
        formData.append(key, value);
      }
    }
  }

  return formData;
}

export function submitBlogPost<TFormValues>(
  formProps: GenericFormViewModel,
  values: TFormValues,
  form: FormApi,
  mapLocation?: string,
  locationMapMarker?: MapMarker
) {
  const formData = GetFormData<TFormValues>(formProps, values);

  if (mapLocation && locationMapMarker) {
    formData.set('location', mapLocation);
    formData.set('geoLocationLatitude', locationMapMarker ? locationMapMarker.latitude.toString() : '0');
    formData.set('geoLocationLongitude', locationMapMarker ? locationMapMarker.longitude.toString() : '0');
  }

  return fetch(formProps.apiUrl, getRequestInit(formData))
    .then((response: Response) => handleResponse(response, formProps))
    .catch(handleFormError);
}

export function deleteBlogPost(formProps: BlogEditViewModel) {
  if (!formProps.blogEditInputModel) {
    throw new TypeError('formProps.blogEditInputModel is required');
  }

  if (!formProps.blogEditInputModel.currentBlogPostId) {
    throw new TypeError('formProps.blogEditInputModel.currentBlogPostId is required');
  }

  const formData = GetFormData(formProps);
  formData.append('CurrentBlogPostId', formProps.blogEditInputModel.currentBlogPostId);

  return fetch(formProps.deleteBlogPostApiUrl, getRequestInit(formData))
    .then((response: Response) => handleResponse(response, formProps))
    .catch(handleFormError);
}

export function logOff(formProps: BlogEditViewModel) {
  if (!formProps.blogLogOffViewModel) {
    throw new TypeError('formProps.blogLogOffViewModel is required');
  }

  const formData = GetFormData(formProps);

  return fetch(formProps.blogLogOffViewModel.logoffApiUrl, getRequestInit(formData))
    .then((response: Response) => {
      if (response.status === 201) {
        if (formProps && formProps.blogLogOffViewModel) {
          window.location.href = formProps.blogLogOffViewModel.logOffSuccesDestination;
        }
        return;
      }
      return response.json();
    })
    .catch(handleFormError);
}
