import { ChangeEvent, FunctionComponent } from 'react';
import { FieldRenderProps } from 'react-final-form';
import ValidationError from './atoms/ValidationError';
import FormItem from './atoms/FormItem';

export interface HorizontalRadioBoxesProps extends FieldRenderProps<string> {
  label?: string;
  className?: string;
  options?: { [key: string]: string };
  onChange?: (id: string, value: string) => void;
  required?: boolean;
}

const HorizontalRadioBoxes: FunctionComponent<HorizontalRadioBoxesProps> = ({ input, meta, ...rest }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const onChange: any = input.onChange;
    onChange.call(input, e.currentTarget.value);
  };

  if (!rest.options) {
    return null;
  }

  const options = rest.options;

  return (
    <FormItem meta={meta} {...rest}>
      {rest.label && <label className={rest.required ? 'required' : ''}>{rest.label}:</label>}
      <div className="form-item-value">
        {Object.keys(options).map(id => (
          <label key={id}>
            <input
              value={id}
              required={rest.required}
              type="radio"
              onChange={handleChange}
              checked={input.value === id}
            />{' '}
            {options[id]}
          </label>
        ))}
      </div>
      <ValidationError meta={meta} />
    </FormItem>
  );
};

export default HorizontalRadioBoxes;
