import { FunctionComponent, Fragment } from 'react';
import { TableViewModel } from '../../models/TableViewModel';

const Table: FunctionComponent<TableViewModel> = ({ tableRows, columnTitles, colorCombination, emptyText }) => {
  if (!tableRows || tableRows.length === 0) {
    if (emptyText) {
      return <div className="boxed" dangerouslySetInnerHTML={{ __html: emptyText }} />;
    }

    return null;
  }

  return (
    <div className="boxed boxed_table">
      <section className="centered">
        <div className={`table ${colorCombination || 'theme-senary'}`}>
          {tableRows.map((row, index) => (
            <Fragment key={index}>
              <div className="tablerow tablehead">
                {columnTitles &&
                  columnTitles.length > 0 &&
                  columnTitles.map(
                    (columnTitle, columnIndex) =>
                      columnTitle && (
                        <div
                          className="tablecell"
                          key={columnIndex}
                          dangerouslySetInnerHTML={{ __html: columnTitle }}
                        />
                      )
                  )}
              </div>
              <div className="tablerow">
                {row &&
                  row.length > 0 &&
                  row.map(
                    cell =>
                      cell && (
                        <div className="tablecell" key={cell.id} dangerouslySetInnerHTML={{ __html: cell.text }} />
                      )
                  )}
              </div>
            </Fragment>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Table;
