import { FunctionComponent } from 'react';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import { BrochureDownloadsListViewModel } from '../../models/BrochureDownloadsListViewModel';

const BrochureDownloadsList: FunctionComponent<BrochureDownloadsListViewModel> = ({ brochures }) => (
  <div className="brochureform-brochures">
    {brochures
      .filter(b => b.link)
      .map(brochure => (
        <div className="brochureform-brochure" key={brochure.id}>
          <a href={brochure.link}>
            <ImageHtml html={brochure.image} class="brochureform-brochureimage" />
          </a>
          <a
            href={brochure.link}
            className="brochureform-brochuretitle"
            dangerouslySetInnerHTML={{ __html: brochure.title }}
          />
        </div>
      ))}
  </div>
);

export default BrochureDownloadsList;
