import { DistanceType, PaneDataItem } from './types';

export function findItem<T extends PaneDataItem>(itemKey: string, items: T[]): T | null {
  if (!items || items.length === 0) {
    return null;
  }

  for (const child of items) {
    if (child.key === itemKey || child.title === itemKey) {
      return child;
    }

    if (child.children) {
      const possibleItem = findItem(itemKey, child.children as T[]);
      if (possibleItem) {
        return possibleItem;
      }
    }
  }

  return null;
}

export function calculateDistance(
  days: number | undefined,
  distance: number | undefined,
  distanceType: DistanceType | undefined
): number | undefined {
  if (!distance) {
    return undefined;
  }

  switch (distanceType) {
    case DistanceType.KmTotal:
      return distance;

    case DistanceType.KmPerDay:
      if (!days) {
        return undefined;
      }
      return distance * days;

    case DistanceType.MilePerDay:
      if (!days) {
        return undefined;
      }
      return distance * days * 1.6;

    case DistanceType.MileTotal:
      return distance * 1.6;

    default:
      return undefined;
  }
}

export function reverseColorCombination(colorCombination = 'theme-secondary'): string {
  if (
    colorCombination === 'theme-senary' ||
    colorCombination === 'theme-quaternary' ||
    colorCombination === 'theme-secondary'
  ) {
    return `${colorCombination}--reversed`;
  }

  return 'theme-senary';
}

export const distanceTypes = [
  { key: DistanceType.KmPerDay, title: 'km/dag' },
  { key: DistanceType.KmTotal, title: 'km totaal' },
  { key: DistanceType.MilePerDay, title: 'mijl/dag' },
  { key: DistanceType.MileTotal, title: 'mijl totaal' },
];
