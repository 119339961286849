import { FunctionComponent } from 'react';
import { RegionBlockViewModel } from '../../models/RegionBlockViewModel';
import Text from '../atoms/text/Text';

const RegionBlockTeaser: FunctionComponent<RegionBlockViewModel> = ({
  componentTitle,
  componentLink,
  colorCombination,
}) => (
  <div className={`country-teaser country-teaser__${colorCombination}`}>
    <a href={componentLink ? componentLink.url : undefined} className={colorCombination}>
      <Text {...componentTitle} className="heading" />
    </a>
  </div>
);

export default RegionBlockTeaser;
