import { FunctionComponent } from 'react';

export interface ValidationErrorProps {
  meta: Partial<{
    error: any;
    submitError: any;
    dirty: any;
    touched: any;
  }>;
}

const ValidationError: FunctionComponent<ValidationErrorProps> = ({ meta }) =>
  (meta.error && meta.touched) || meta.submitError ? (
    <div className="form-error">{meta.error || meta.submitError}</div>
  ) : null;

export default ValidationError;
