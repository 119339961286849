import { FunctionComponent } from 'react';
import { WidgetViewModel } from '../../models/WidgetViewModel';
import Button from '../atoms/button/Button';
import Text from '../atoms/text/Text';
import { asEditable } from '../../helpers/sitecore';

const ActionWidget: FunctionComponent<WidgetViewModel> = ({
  buttonText,
  showButton,
  componentTitle,
  componentText,
  componentLink,
  colorCombination,
  textAlignment,
}) => {
  return (
    <div
      className={`custom_cta actionwidget--${colorCombination ? colorCombination : 'theme-secondary'} ${
        !!textAlignment ? `text--${textAlignment}` : 'left'
      }`}
    >
      <Text {...componentTitle} className="heading" />
      <p dangerouslySetInnerHTML={asEditable(componentText)} />
      {showButton && componentLink && (
        <Button text={buttonText} class="cta_button" url={componentLink.url} target={componentLink.target} />
      )}
    </div>
  );
};

export default ActionWidget;
