import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import cx from 'classnames';

export interface SubmitButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  buttonText?: string;
  onClick?: () => void;
}

const SubmitButton: FunctionComponent<SubmitButtonProps> = props => (
  <button className={cx('cta_button', props.className)} type={props.type || 'submit'} onClick={props.onClick}>
    {props.children || props.buttonText || 'Versturen'}
  </button>
);

export default SubmitButton;
