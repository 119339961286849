import { FunctionComponent } from 'react';
import { FooterList as IFooterList } from '../../models/FooterList';
import { FooterItem as IFooterItem } from '../../models/FooterItem';
import FooterItem from './FooterItem';

const FooterList: FunctionComponent<IFooterList> = ({ componentTitle, footerItems }) => (
  <>
    {componentTitle && <p className="heading">{componentTitle}</p>}
    {footerItems &&
      footerItems.length > 0 &&
      footerItems.map(
        (footerItem: IFooterItem) =>
          (footerItem.componentLink || footerItem.componentText) && <FooterItem {...footerItem} key={footerItem.id} />
      )}
  </>
);

export default FooterList;
