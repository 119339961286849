import { FunctionComponent } from 'react';
import { ConsentDialogViewModel } from '../../models/ConsentDialogViewModel';
import { asEditable } from '../../helpers/sitecore';

interface SimpleConsentFormProps {
  viewModel: ConsentDialogViewModel;
  onAccept: () => void;
  onDeny: () => void;
  onFull: () => void;
}

const SimpleConsentForm: FunctionComponent<SimpleConsentFormProps> = ({ viewModel, onAccept, onDeny, onFull }) => {
  return (
    <div
      className="popup consentdialog"
      role="dialog"
      aria-labelledby="simpleconsenttitle"
      aria-describedby="simpleconsentdescription"
    >
      <h1 id="simpleconsenttitle">{viewModel.title}</h1>
      <div id="simpleconsentdescription" dangerouslySetInnerHTML={asEditable(viewModel.text)} />
      <div className="consent__buttons">
        <button className="cta_button cta_button--secondary" type="button" onClick={onDeny}>
          Weigeren
        </button>
        <button className="cta_button cta_button--secondary" type="button" onClick={onFull}>
          Meer cookievoorkeuren
        </button>
        <button className="cta_button" type="button" onClick={onAccept}>
          Ja, ik ga akkoord
        </button>
      </div>
    </div>
  );
};

export default SimpleConsentForm;
