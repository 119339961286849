import { FunctionComponent } from 'react';
import { IntroTextViewModel } from '../../models/IntroTextViewModel';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import Text from '../atoms/text/Text';
import ReadMore from '../atoms/text/ReadMore';
import { asEditable } from '../../helpers/sitecore';

const IntroText: FunctionComponent<IntroTextViewModel> = (props: IntroTextViewModel) => {
  const {
    componentTitle,
    componentShortText,
    componentText,
    componentImage,
    buttonMoreText,
    buttonLessText,
    isInEditMode,
    id,
  } = props;

  const elementId = id ? id.replace(/-/g, '') : 'readmore';

  return (
    <section>
      <div className="quote">
        {componentTitle && <Text {...componentTitle} className="heading" />}
        <div>
          {componentImage && <ImageHtml {...componentImage} />}
          <div dangerouslySetInnerHTML={asEditable(componentShortText)} />

          <ReadMore
            uniqueId={elementId}
            text={componentText}
            buttonLessText={buttonLessText || ''}
            buttonMoreText={buttonMoreText || ''}
            editMode={isInEditMode === true}
          />
        </div>
      </div>
    </section>
  );
};

export default IntroText;
