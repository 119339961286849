import { Component, FunctionComponent } from 'react';
import Slider, { CustomArrowProps, Settings } from 'react-slick';
import { FullWidthCarouselViewModel } from '../../models/FullWidthCarouselViewModel';
import { BreakPoints } from '../../constants/breakPoints';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
const withSizes = require('react-sizes');

export interface CarouselState {
  initialized: boolean;
}

const Arrow: FunctionComponent<CustomArrowProps> = ({ className, style, onClick }: CustomArrowProps) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    &gt;
  </div>
);

interface CarouselModel extends FullWidthCarouselViewModel {
  isMobile: boolean;
}

class FullWidthCarousel extends Component<CarouselModel, CarouselState> {
  constructor(props: CarouselModel) {
    super(props);

    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    this.setState({ initialized: true });
  }

  render() {
    const { relatedImages, isMobile, isInEditMode, editModeText } = this.props;

    const sliderSettings: Settings = {
      infinite: true,
      slidesToShow: isMobile ? 1 : 2,
      slidesToScroll: 1,
      variableWidth: true,
      nextArrow: <Arrow />,
      prevArrow: <Arrow />,
    };

    if (!relatedImages || relatedImages.length <= 3) {
      return !isInEditMode ? (
        <div />
      ) : (
        <div className="edit-mode-text">
          <h1>{editModeText}</h1>
        </div>
      );
    }

    return isInEditMode ? (
      <div className="carousel-edit-mode">
        {relatedImages.map((relatedImage, key) => {
          return (
            <div key={key}>
              <ImageHtml {...relatedImage} showCopyright={true} />
            </div>
          );
        })}
      </div>
    ) : (
      <div className="fullwidth-carousel">
        {this.state.initialized && (
          <Slider {...sliderSettings}>
            {relatedImages.map((relatedImage, key) => {
              return (
                <div key={key}>
                  <ImageHtml {...relatedImage} showCopyright={true} />
                </div>
              );
            })}
          </Slider>
        )}
      </div>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < BreakPoints.screenMd,
});

export default withSizes.withSizes(mapSizesToProps)(FullWidthCarousel);
