import '../App.scss';
import HomepageLayout from './structure/layouts/HomepageLayout';
import LandingpageLayout from './structure/layouts/LandingpageLayout';
import AreaLayout from './structure/layouts/AreaLayout';
import CampingLayout from './structure/layouts/CampingLayout';
import CountryLayout from './structure/layouts/CountryLayout';
import RouteSuggestionLayout from './structure/layouts/RouteSuggestionLayout';
import SightLayout from './structure/layouts/SightLayout';
import TravelReportLayout from './structure/layouts/TravelReportLayout';
import TripLayout from './structure/layouts/TripLayout';
import Header from './header/Header';
import WidgetContainerWrapper from './structure/WidgetContainerWrapper';
import ArticleWidget from './widget/ArticleWidget';
import ColumnContainer from './structure/ColumnContainer';
import AreaColumnContainer from './structure/AreaColumnContainer';
import CountryColumnContainer from './structure/CountryColumnContainer';
import TripColumnContainer from './structure/TripColumnContainer';
import CampingColumnContainer from './structure/CampingColumnContainer';
import Footer from './footer/Footer';
import FooterLogos from './footer/FooterLogos';
import TailoredWidget from './widget/TailoredWidget';
import CustomerReview from './customerReview/CustomerReview';
import RegionBlock from './regionBlock/RegionBlock';
import RegionBlockTeaser from './regionBlock/RegionBlockTeaser';
import RegionBlockContainer from './structure/RegionBlockContainer';
import RegionBlockRowContainer from './regionBlock/RegionBlockRowContainer';
import IntroText from './introText/IntroText';
import IntroTextArea from './introText/IntroTextArea';
import IntroTextCountry from './introText/IntroTextCountry';
import IntroTextTrip from './introText/IntroTextTrip';
import IntroTextCamping from './introText/IntroTextCamping';
import IntroTextSight from './introText/IntroTextSight';
import IntroTextRouteSuggestion from './introText/IntroTextRouteSuggestion';
import IntroTextTravelReport from './introText/IntroTextTravelReport';
import IntroTextSearchResults from './introText/IntroTextSearchResults';
import Highlight from './highlight/Highlight';
import SubMenu from './subMenu/SubMenu';
import SpotlightHeader from './spotlightHeader/SpotlightHeader';
import SpotlightHeaderProduct from './spotlightHeader/SpotlightHeaderProduct';
import SpotlightHeaderCountry from './spotlightHeader/SpotlightHeaderCountry';
import SpotlightHeaderArea from './spotlightHeader/SpotlightHeaderArea';
import SpotlightHeaderTrip from './spotlightHeader/SpotlightHeaderTrip';
import ScrollButton from './scrollButton/scrollButton';
import ActionWidget from './widget/ActionWidget';
import SubMenuArea from './subMenu/SubMenuArea';
import SubMenuTrip from './subMenu/SubMenuTrip';
import SubMenuCountry from './subMenu/SubMenuCountry';
import SubMenuCamping from './subMenu/SubMenuCamping';
import SubMenuSight from './subMenu/SubMenuSight';
import SubMenuBlog from './subMenu/SubMenuBlog';
import SubMenuRouteSuggestion from './subMenu/SubMenuSight';
import SubMenuTravelReport from './subMenu/SubMenuSight';
import FullWidthCarousel from './fullWidthCarousel/FullWidthCarousel';
import FullWidthCarouselTrip from './fullWidthCarousel/FullWidthCarouselTrip';
import FullWidthCarouselCamping from './fullWidthCarousel/FullWidthCarouselCamping';
import SearchFormContainer from './searchform/SearchFormContainer';
import HolidayResultContainer from './holidayResultContainer/HolidayResultContainer';
import Inclusive from './inclusive/Inclusive';
import PracticalInfoNavigation from './practicalInfo/PracticalInfoNavigation';
import PracticalInfo from './practicalInfo/PracticalInfo';
import ContentBlock from './contentBlock/ContentBlock';
import PriceBlock from './priceBlock/PriceBlock';
import SightColumnContainer from './structure/SightColumnContainer';
import FullWidthCarouselSight from './fullWidthCarousel/FullWidthCarouselSight';
import MapHeaderSight from './mapHeader/MapHeaderSight';
import MapHeaderCamping from './mapHeader/MapHeaderCamping';
import Carousel from './carousel/Carousel';
import CarouselTrip from './carousel/CarouselTrip';
import CarouselCamping from './carousel/CarouselCamping';
import CarouselSight from './carousel/CarouselSight';
import RouteSuggestionColumnContainer from './structure/RouteSuggestionColumnContainer';
import TravelReportColumnContainer from './structure/TravelReportColumnContainer';
import MapHeaderWithRoute from './mapHeader/MapHeaderWithRoute';
import MapHeaderRouteSuggestion from './mapHeader/MapHeaderRouteSuggestion';
import MapHeaderTravelReport from './mapHeader/MapHeaderTravelReport';
import TravelPartNavigation from './travelPartNavigation/TravelPartNavigation';
import TravelPartNavigationRouteSuggestion from './travelPartNavigation/TravelPartNavigationRouteSuggestion';
import TravelPartNavigationTravelReport from './travelPartNavigation/TravelPartNavigationTravelReport';
import TravelPartNavigationBlog from './travelPartNavigation/TravelPartNavigationBlog';
import TravelPartNavigationTrip from './travelPartNavigation/TravelPartNavigationTrip';
import TravelPartContent from './travelPartContent/TravelPartContent';
import TravelPartContentBlog from './travelPartContent/TravelPartContentBlog';
import TravelPartContentRouteSuggestion from './travelPartContent/TravelPartContentRouteSuggestion';
import TravelPartContentTravelReport from './travelPartContent/TravelPartContentTravelReport';
import TravelPartContentTrip from './travelPartContent/TravelPartContentTravelReport';
import MapBlock from './mapBlock/MapBlock';
import Table from './table/Table';
import SearchResultsLayout from './structure/layouts/SearchResultsLayout';
import RichContentBlock from './contentBlock/RichContentBlock';
import Frame from './frame/Frame';
import NewsLayout from './structure/layouts/NewsLayout';
import NewsArticleLayout from './structure/layouts/NewsArticleLayout';
import IntroTextFullWidth from './introText/IntroTextFullWidth';
import NewsColumnContainer from './structure/NewsColumnContainer';
import SpotlightHeaderNewsArticle from './spotlightHeader/SpotlightHeaderNewsArticle';
import SpotlightHeaderBasic from './spotlightHeader/SpotlightHeaderBasic';
import IntroTextNewsArticle from './introText/IntroTextNewsArticle';
import Container from './structure/Container';
import FooterTrips from './footer/FooterTrips';
import QuotationFrame from './frame/QuotationFrame';
import SpotlightHeaderForm from './spotlightHeader/SpotlightHeaderForm';
import BookingFrame from './frame/BookingFrame';
import SubMenuForm from './subMenu/SubMenuForm';
import NewsletterForm from './newsletterForm/NewsletterForm';
import ContentSearchContainer from './contentSearchContainer/ContentSearchContainer';
import ComplaintForm from './complaintForm/ComplaintForm';
import BrochureForm from './brochureForm/BrochureForm';
import BlogLayout from './structure/layouts/BlogLayout';
import BlogColumnContainer from './structure/BlogColumnContainer';
import MapHeaderBlogOverview from './mapHeader/MapHeaderBlogOverview';
import MapHeaderBlog from './mapHeader/MapHeaderBlog';
import ActionWidgetBlog from './widget/ActionWidgetBlog';
import BlogOverviewContainer from './blogOverviewContainer/BlogOverviewContainer';
import BlogLogin from './blogEdit/BlogLogin';
import BlogEditForm from './blogEdit/BlogEditForm';
import BlogEditContainer from './structure/BlogEditContainer';
import BlogComments from './blogEdit/BlogComments';
import MinisiteHomepage from './minisite/MinisiteHomepage';
import MinisiteBackButton from './minisite/MinisiteBackButton';
import CamperFrame from './frame/CamperFrame';
import SpotlightHeaderButtons from './spotlightHeader/SpotlightHeaderButtons';
import BrochureDownloadsList from './brochureForm/BrochureDownloadsList';
import MapHeaderTrip from './mapHeader/MapHeaderTrip';
import ExpandableContentBlock from './contentBlock/ExpandableContentBlock';
import SideMenu from './sideMenu/SideMenu';
import CampersOverview from './campersOverview/CampersOverview';
import TripExtensions from './tripExtensions/TripExtensions';
import SpotlightHeaderSellableProductExtension from './spotlightHeader/SpotlightHeaderSellableProductExtension';
import IntroTextSellableProductExtension from './introText/IntroTextSellableProductExtension';
import FullWidthCarouselSellableProductExtension from './fullWidthCarousel/FullWidthCarouselSellableProductExtension';
import SubMenuSellableProductExtension from './subMenu/SubMenuSellableProductExtension';
import FullTextSearchForm from './fullTextSearchForm/FullTextSearchForm';
import SubMenuNewsArticle from './subMenu/SubMenuNewsArticle';
import FuelCalculator from './fuelCalculator/FuelCalculator';
import CustomerLogin from './customerLogin/CustomerLogin';
import CustomerLogout from './customerLogin/CustomerLogout';
import SpotlightHeaderCustomerArea from './spotlightHeader/SpotlightHeaderCustomerArea';
import HtmlBlock from './htmlBlock/HtmlBlock';
import Popup from './popup/Popup';
import ConsentDialog from './consentDialog/ConsentDialog';

// If a component has to track its state, has javascript events or lifecycle hooks add the comment 'client-side', else 'server-side'
// server-side: The Sitecore rendering will point to the ~/Areas/Travelhome/Views/Shared/React/Placeholder.cshtml
// client-side: The Sitecore rendering will point to the ~/Areas/Travelhome/Views/Shared/React/Index.cshtml

const Components = {
  ArticleWidget, // server-side
  ColumnContainer, // server-side
  AreaColumnContainer, // server-side,
  CountryColumnContainer, // server-side,
  TripColumnContainer, // server-side
  CampingColumnContainer, // server-side
  SightColumnContainer, // server-side
  RouteSuggestionColumnContainer, // server-side
  TravelReportColumnContainer, // server-side
  NewsColumnContainer, // server-side
  BlogColumnContainer, // server-side
  CampingLayout, // server-side
  CountryLayout, // server-side
  AreaLayout, // server-side
  BlogLayout, // server-side
  RouteSuggestionLayout, // server-side
  SightLayout, // server-side
  TripLayout, // server-side
  NewsLayout, // server-side
  NewsArticleLayout, // server-side
  Header, // client-side
  Highlight, // client-side
  HomepageLayout, // server-side
  LandingpageLayout, // server-side
  TravelReportLayout, // server-side
  Footer, // server-side
  FooterLogos, // server-side
  FooterTrips, // server-side
  CustomerReview, // client-side
  TailoredWidget, // client-side
  WidgetContainerWrapper, // server-side
  RegionBlock, // server-side
  RegionBlockTeaser, // server-side
  RegionBlockContainer, // server-side
  RegionBlockRowContainer, // server-side
  IntroText, // client-side
  IntroTextArea, // client-side
  IntroTextCountry, // client-side
  IntroTextTrip, // client-side
  IntroTextCamping, // server-side
  IntroTextSight, // server-side
  IntroTextTravelReport, // server-side
  IntroTextRouteSuggestion, // server-side
  IntroTextSearchResults, // server-side
  IntroTextFullWidth, // client-side
  IntroTextNewsArticle, // client-side
  SearchResultsLayout, // server-side
  SubMenu, // server-side
  SubMenuArea, // server-side
  SubMenuCountry, // server-side
  SubMenuTrip, // server-side
  SubMenuCamping, // server-side
  SubMenuSight, // server-side
  SubMenuBlog, // server-side
  SubMenuRouteSuggestion, // server-side
  SubMenuTravelReport, // server-side
  SubMenuForm, // server-side
  SpotlightHeader, // client-side
  SpotlightHeaderProduct, // client-side
  SpotlightHeaderArea, // client-side
  SpotlightHeaderCountry, // client-side
  SpotlightHeaderTrip, // client-side
  SpotlightHeaderButtons, // client-side
  SpotlightHeaderForm, // client-side
  SpotlightHeaderNewsArticle, // client-side
  SpotlightHeaderBasic, // client-side
  ScrollButton, // client-side
  ActionWidget, // server-side
  ActionWidgetBlog, // server-side
  Carousel, // client-side
  CarouselCamping, // client-side
  CarouselSight, // client-side
  CarouselTrip, // client-side
  FullWidthCarousel, // client-side
  FullWidthCarouselTrip, // client-side
  FullWidthCarouselCamping, // client-side
  FullWidthCarouselSight, // client-side
  ContentSearchContainer, // client-side
  SearchFormContainer, // client-side
  HolidayResultContainer, // client-side
  Inclusive, // server-side,
  PracticalInfo, // server-side
  PracticalInfoNavigation, // client-side
  ContentBlock, // server-side
  PriceBlock, // client-side
  MapHeaderCamping, // client-side
  MapHeaderSight, // client-side
  MapHeaderWithRoute, // client-side
  MapHeaderRouteSuggestion, // client-side
  MapHeaderTravelReport, // client-side
  MapHeaderBlogOverview, // client-side
  MapHeaderBlog, // client-side
  TravelPartNavigation, // client-side
  TravelPartNavigationBlog, // client-side
  TravelPartNavigationTrip, // client-side
  TravelPartNavigationRouteSuggestion, // client-side
  TravelPartNavigationTravelReport, // client-side
  TravelPartContent, // client-side
  TravelPartContentBlog, // client-side
  TravelPartContentRouteSuggestion, // client-side
  TravelPartContentTravelReport, // client-side
  TravelPartContentTrip, // client-side
  MapBlock, // client-side
  Table, // server-side
  RichContentBlock, // client-side
  Frame, // client-side
  BookingFrame, // client-side
  QuotationFrame, // client-side
  Container, // server-side
  NewsletterForm, // client-side
  ComplaintForm, // client-side
  BrochureForm, // client-side
  BlogOverviewContainer, // server-side
  BlogLogin, // client-side
  BlogEditForm, // client-side
  BlogEditContainer, // server-side
  CamperFrame,
  BlogComments, // client-side
  MinisiteHomepage, // client-side
  MinisiteBackButton, // server-side
  BrochureDownloadsList, // server-side
  MapHeaderTrip, // client-side
  ExpandableContentBlock, // server-side
  SideMenu, // server-side
  CampersOverview, // client-side
  TripExtensions, // client-side
  SpotlightHeaderSellableProductExtension, // client-side
  IntroTextSellableProductExtension, // client-side
  FullWidthCarouselSellableProductExtension, // client-side
  SubMenuSellableProductExtension, // server-side
  FullTextSearchForm, // client-side
  SubMenuNewsArticle, // server-side
  FuelCalculator, // client-side
  CustomerLogin, // client-side
  CustomerLogout, // client-side
  SpotlightHeaderCustomerArea, // client-side
  HtmlBlock, // server-side
  Popup, // client-side
  ConsentDialog, // client-side
};

export default Components;
