import { Link } from '../../../models/Link';
import { asEditable } from '../../../helpers/sitecore';
import { FunctionComponent } from 'react';

export interface ButtonProps extends Link {
  backgroundColor?: string;
  icon?: string;
  text?: string;
}

const Button: FunctionComponent<ButtonProps> = ({ url, target, title, class: classValue, text, onClick }) => (
  <a
    href={url}
    target={target}
    title={title}
    className={classValue}
    onClick={onClick}
    dangerouslySetInnerHTML={asEditable(text)}
  />
);

export default Button;
