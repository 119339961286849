import { Attributes, ComponentType, createElement, FunctionComponent } from 'react';
import { BaseViewModel } from '../models/BaseViewModel';
import { EmptyGuid } from '../constants/emptyGuid';
import RelatedContent from '../components/relatedContent/RelatedContent';

export const asEditable = (value?: string | null) => (value ? { __html: value } : undefined);

export function filterEmptyId<TModel extends BaseViewModel & Attributes>(
  Element: ComponentType<TModel>
): FunctionComponent<TModel> {
  const wrapper: FunctionComponent<TModel> = (props: TModel) => {
    if (props.id === EmptyGuid) {
      return createElement<BaseViewModel>(RelatedContent, props);
    }

    return createElement<TModel>(Element, props);
  };

  wrapper.displayName = `filterEmptyId(${Element.displayName})`;

  return wrapper;
}
