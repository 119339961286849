import { Component } from 'react';
import { default as QuillType, Sources } from 'quill';

export interface RichTextEditorProps {
  id: string;
  initialValue?: string | undefined;
  handleChange: (value: string) => any;
}

interface RichTextEditorState {
  target: string;
  scriptloaded?: boolean;
  pageloaded?: boolean;
  loading?: boolean;
}

const isServerSide = typeof window === 'undefined';
const scriptUrl = 'https://cdn.quilljs.com/1.3.6/quill.min.js';
const cssUrl = 'https://cdn.quilljs.com/1.3.6/quill.snow.css';
const scriptId = 'rte-script';
const cssId = 'rte-css';

export default class RichTextEditor extends Component<RichTextEditorProps, RichTextEditorState> {
  quillEditor?: QuillType;
  lastValue?: string;

  state: RichTextEditorState = {
    target: `rte-editor-${this.props.id}`,
    scriptloaded: !isServerSide && !!document.getElementById(scriptId),
    pageloaded: false,
    loading: true,
  };

  componentWillMount() {
    if (!this.state.scriptloaded && !isServerSide) {
      const existingScript = document.getElementById(scriptId);
      const existingCss = document.getElementById(cssId);

      if (!existingCss) {
        const link: HTMLLinkElement = document.createElement('link');
        link.href = cssUrl;
        link.id = cssId;
        link.rel = 'stylesheet';

        if (document.head) {
          document.head.appendChild(link);
        }
      }

      if (!existingScript) {
        const script: HTMLScriptElement = document.createElement('script');
        script.src = scriptUrl;
        script.id = scriptId;
        script.type = 'text/javascript';

        script.onload = () => {
          this.setState(() => ({
            scriptloaded: true,
          }));
        };

        if (document.head) {
          document.head.appendChild(script);
        }
      }
    }
  }

  loadQuill = () => {
    const { handleChange, initialValue } = this.props;
    const { pageloaded, target } = this.state;

    // @ts-ignore TS2304
    const Quill = window ? window.Quill : null;
    if (!Quill || pageloaded) {
      return;
    }

    // @ts-ignore TS2304
    const quillEditor = new Quill(`#${target}`, {
      modules: {
        toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], [{ align: [] }]],
      },
      placeholder: 'Plaats een reactie',
      theme: 'snow', // or 'bubble'
    });

    if (initialValue) {
      quillEditor.clipboard.dangerouslyPasteHTML(0, initialValue, 'api');
    }

    quillEditor.on('text-change', (delta: any, oldContents: any, source: Sources) => {
      if (source === 'user') {
        const lastValue = quillEditor.container.firstChild.innerHTML;
        this.lastValue = lastValue;
        handleChange(lastValue);
      }
    });

    this.quillEditor = quillEditor;

    this.setState(() => ({ pageloaded: true, loading: false }));
  };

  componentWillReceiveProps({ initialValue }: Readonly<RichTextEditorProps>): void {
    if (initialValue && this.quillEditor && initialValue !== this.lastValue) {
      this.quillEditor.clipboard.dangerouslyPasteHTML(initialValue, 'api');
    }
  }

  render() {
    if (this.state.scriptloaded && !this.state.pageloaded) {
      // Load the initialization script after the loader script has loaded and after the HTML was written.
      window.setTimeout(this.loadQuill, 500);
    }

    return (
      <div className="richtexteditor">
        <div id={this.state.target} />
      </div>
    );
  }
}
