import { FunctionComponent } from 'react';
import { MapHeaderViewModel } from '../../models/MapHeaderViewModel';
import MapHeaderRoadTrippersRoute from './MapHeaderRoadTrippersRoute';
import MapHeaderGoogleMapsRoute from './MapHeaderGoogleMapsRoute';

const MapHeaderWithRoute: FunctionComponent<MapHeaderViewModel> = props => {
  if (props.roadTrippersCode) {
    return <MapHeaderRoadTrippersRoute {...props} />;
  }

  return <MapHeaderGoogleMapsRoute {...props} />;
};

export default MapHeaderWithRoute;
