import { MapTypeStyle } from 'google-map-react';

export const mapStyles: MapTypeStyle[] = [
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        hue: '#76aee3',
      },
      {
        saturation: 38,
      },
      {
        lightness: -11,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        hue: '#79a71e',
      },
      {
        saturation: -30,
      },
      {
        lightness: -20,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'all',
    stylers: [
      {
        hue: '#c6e3a4',
      },
      {
        saturation: 17,
      },
      {
        lightness: -2,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'all',
    stylers: [
      {
        hue: '#cccccc',
      },
      {
        saturation: -100,
      },
      {
        lightness: 13,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'all',
    stylers: [
      {
        hue: '#5f5855',
      },
      {
        saturation: 6,
      },
      {
        lightness: -31,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'all',
    stylers: [
      {
        hue: '#ffffff',
      },
      {
        saturation: -100,
      },
      {
        lightness: 100,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [],
  },
];
