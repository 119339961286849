import { FunctionComponent } from 'react';
import cx from 'classnames';
import { RegionBlockViewModel } from '../../models/RegionBlockViewModel';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import { asEditable } from '../../helpers/sitecore';
import Text from '../atoms/text/Text';

const RegionBlock: FunctionComponent<RegionBlockViewModel> = ({
  componentTitle,
  componentImage,
  componentText,
  componentLink,
  colorCombination,
}) => (
  <a
    href={componentLink ? componentLink.url : undefined}
    className={cx('country', (!componentImage || !componentImage.html) && `no-image__${colorCombination}`)}
  >
    {componentImage && <ImageHtml {...componentImage} />}
    <Text {...componentTitle} className="heading" />
    <div className="country-hover" dangerouslySetInnerHTML={asEditable(componentText)} />
  </a>
);

export default RegionBlock;
