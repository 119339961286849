export function getPracticalInfoItem<T extends { id: string; subItems?: T[] }>(
  items: T[],
  id: string | undefined
): T | undefined {
  if (!id) {
    return undefined;
  }

  let item: T | undefined = undefined;

  items.forEach(i => {
    if (i.id === id) {
      item = i;
    } else if (i.subItems && i.subItems.length > 0) {
      const childItem = getPracticalInfoItem(i.subItems, id);
      if (childItem) {
        item = childItem;
      }
    }
  });

  return item;
}

export const getHashUrlOnly = (): string | undefined => window.location.hash && window.location.hash.split('info-')[1];
