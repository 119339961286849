import { FunctionComponent, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { PopupViewModel } from '../../models/PopupViewModel';
import PopupModal from './PopupModal';
import PopupInner from './PopupInner';

export interface PopupProps extends PopupViewModel {}

const getIsOpen = (id: string): boolean => {
  const cookieValue = Cookies.get('popups');

  if (!cookieValue) {
    return true;
  }

  return !cookieValue.includes(`|${id}|`);
};

const saveClosed = (id: string): void => {
  let cookieValue = Cookies.get('popups');
  if (!cookieValue) {
    cookieValue = '|';
  }
  cookieValue = `${cookieValue}${id}|`;
  Cookies.set('popups', cookieValue, { expires: 1 });
};

const Popup: FunctionComponent<PopupProps> = props => {
  const [isOpen, setOpen] = useState<boolean>(() => false);

  // (Re)set state when props.id changes.
  useEffect(() => {
    setOpen(getIsOpen(props.id || '_'));
  }, [props.id]);

  // Handle closing the popup.
  const handleClose = () => {
    saveClosed(props.id || '_');
    setOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  if (props.isInEditMode) {
    return <PopupInner text={props.text} onClose={handleClose} title={props.title} />;
  }

  return <PopupModal {...props} onClose={handleClose} />;
};
export default Popup;
