import { Component } from 'react';
import { Field, withTypes } from 'react-final-form';
import createDecorator from 'final-form-focus';
import TextField from '../atoms/form/TextField';
import HorizontalRadioBoxes from '../atoms/form/HorizontalRadioBoxes';
import CheckBox from '../atoms/form/CheckBox';
import SubmitButton from '../atoms/form/SubmitButton';
import { NewsletterFormViewModel } from '../../models/NewsletterFormViewModel';
import SuccessMessage from '../atoms/message/SuccessMessage';
import ErrorMessage from '../atoms/message/ErrorMessage';
import { submitForm } from '../../helpers/form';
import { NewsletterFormInputModel } from '../../models/NewsletterFormInputModel';

const decorators = [createDecorator()];

const { Form } = withTypes<NewsletterFormInputModel>();

class NewsletterForm extends Component<NewsletterFormViewModel> {
  handleSubmit = (initialValues: NewsletterFormInputModel) => submitForm(this.props, initialValues);

  render() {
    const { interests, successMessage, fieldNames, genders } = this.props;
    const requiredTermsField = value => (value ? undefined : this.props.termsFieldValidationMessage);
    const requiredField = value => (value ? undefined : this.props.clientSideFieldValidationMessage);

    const initialValues: NewsletterFormInputModel = {};

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleSubmit(initialValues)}
        decorators={decorators}
        subscription={{
          pristine: true,
          invalid: true,
          submitFailed: true,
          submitError: true,
          submitErrors: true,
          submitSucceeded: true,
          submitting: true,
        }}
        render={({ handleSubmit, pristine, invalid, submitFailed, submitError, submitSucceeded, submitting }) => {
          return (
            <form onSubmit={handleSubmit} noValidate={true}>
              {!submitting && invalid && submitFailed && <ErrorMessage>{this.props.submitErrorMessage}</ErrorMessage>}
              {!submitting && submitSucceeded && (
                <SuccessMessage scrollIntoView={true}>
                  <div dangerouslySetInnerHTML={{ __html: successMessage }} />
                </SuccessMessage>
              )}
              {!submitting && submitError && <ErrorMessage scrollIntoView={true}>{submitError}</ErrorMessage>}

              <Field
                validate={requiredField}
                component={HorizontalRadioBoxes}
                name="gender"
                label={fieldNames.gender}
                options={genders}
              />
              <Field
                validate={requiredField}
                component={TextField}
                name="givenName"
                label={fieldNames.givenName}
                required={true}
              />
              <Field component={TextField} name="surnamePrefix" label={fieldNames.surnamePrefix} />
              <Field
                validate={requiredField}
                component={TextField}
                name="surname"
                label={fieldNames.surname}
                required={true}
              />
              <Field
                validate={requiredField}
                component={TextField}
                name="email"
                type="email"
                label={fieldNames.email}
                required={true}
              />
              <Field
                validate={requiredField}
                component={TextField}
                name="emailConfirm"
                type="email"
                label={fieldNames.emailConfirm}
                required={true}
              />

              {interests && interests.length > 0 && (
                <>
                  <h4>{fieldNames.interests}</h4>

                  <div className="newsletter-interests">
                    {interests.map(interest => (
                      <Field
                        component={CheckBox}
                        type="checkbox"
                        key={interest.key}
                        name="interests"
                        value={interest.key}
                        label={interest.value}
                      />
                    ))}
                  </div>
                </>
              )}

              <Field component={CheckBox} type="checkbox" name="wegbrengAmerika" label={fieldNames.wegbrengAmerika} />

              <div className="newsletter-terms">
                <Field
                  validate={requiredTermsField}
                  component={CheckBox}
                  type="checkbox"
                  required={true}
                  name="agree"
                  label={<span dangerouslySetInnerHTML={{ __html: fieldNames.agree }} />}
                />
              </div>
              <SubmitButton disabled={pristine || invalid} />
            </form>
          );
        }}
      />
    );
  }
}

export default NewsletterForm;
