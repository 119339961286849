import { FunctionComponent } from 'react';
import { IntroTextViewModel } from '../../models/IntroTextViewModel';
import Text from '../atoms/text/Text';
import { asEditable } from '../../helpers/sitecore';

const IntroTextSellableProductExtension: FunctionComponent<IntroTextViewModel> = ({
  componentTitle,
  componentText,
}: IntroTextViewModel) => (
  <section className="quote">
    {componentTitle && <Text {...componentTitle} className="heading" />}
    <div className="content" dangerouslySetInnerHTML={asEditable(componentText)} />
  </section>
);

export default IntroTextSellableProductExtension;
