import { forwardRef, FunctionComponent, Ref } from 'react';
import { Link as ILink } from '../../../models/Link';
import { asEditable } from '../../../helpers/sitecore';

interface LinkProps extends ILink {
  rel?: string;
  ref?: Ref<HTMLAnchorElement>;
}

const Link: FunctionComponent<LinkProps> = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ url, target, title, class: classValue, text, children, onClick, rel, ...rest }, ref) => (
    <a
      href={url}
      target={target || undefined}
      title={title || undefined}
      className={classValue || undefined}
      dangerouslySetInnerHTML={children ? undefined : asEditable(text)}
      onClick={onClick}
      rel={rel || undefined}
      ref={ref}
      {...rest}
    >
      {children}
    </a>
  )
);

export default Link;
