import { Placeholder } from '../Placeholder';
import { FunctionComponent, PropsWithChildren } from 'react';
import { BaseLayout } from './BaseLayout';
import { ReactPlaceholder } from '../../../models/ReactPlaceholder';

export interface BaseStructureProps {
  placeholders?: ReactPlaceholder[];
  isInEditMode?: boolean;
  editModeText?: string;
}

const BaseStructure: FunctionComponent<PropsWithChildren<BaseStructureProps>> = ({
  placeholders,
  children,
  isInEditMode,
}) => {
  return (
    <BaseLayout isInEditMode={isInEditMode}>
      <header className={`header${isInEditMode ? ' header-edit-mode' : ''}`}>
        <Placeholder children={children} placeholders={placeholders} placeholderIndex={0} />
      </header>
      <main>
        <Placeholder children={children} placeholders={placeholders} placeholderIndex={1} />
      </main>
      <footer>
        <Placeholder children={children} placeholders={placeholders} placeholderIndex={2} />
      </footer>
    </BaseLayout>
  );
};

export default BaseStructure;
