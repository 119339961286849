import { FunctionComponent, PropsWithChildren } from 'react';
import cx from 'classnames';

export interface FormItemProps {
  className?: string;
  id?: string;
  meta: {
    invalid?: boolean;
    submitFailed?: boolean;
  };
}

const FormItem: FunctionComponent<PropsWithChildren<FormItemProps>> = ({ meta, children, ...rest }) => (
  <div className={cx('form-item', rest.className, meta.invalid && meta.submitFailed && 'form-item-error')} id={rest.id}>
    {children}
  </div>
);

export default FormItem;
