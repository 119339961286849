import { FunctionComponent } from 'react';
import { asEditable } from '../../helpers/sitecore';
import { PracticalInfoItemViewModel } from '../../models/PracticalInfoItemViewModel';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import { Placeholder } from '../structure/Placeholder';

const Scroll = require('react-scroll');
const Element = Scroll.Element;

const PracticalInfo: FunctionComponent<PracticalInfoItemViewModel> = ({ title, text, image, placeholders }) => (
  <section>
    <Element name="paragraphContent">
      <div>
        <h2 dangerouslySetInnerHTML={asEditable(title || '')} className="heading" />
        {text && <div dangerouslySetInnerHTML={asEditable(text)} />}
        {image && <ImageHtml {...image} />}
        {placeholders && <Placeholder extractScripts={true} placeholders={placeholders} placeholderIndex={0} />}
      </div>
    </Element>
  </section>
);

export default PracticalInfo;
