import { FunctionComponent } from 'react';

interface FullScreenLoaderProps {
  loadingText?: string;
}

const FullScreenLoader: FunctionComponent<FullScreenLoaderProps> = ({ loadingText }) => (
  <div className="full-screen-loader">
    <div className="full-screen-loader__inner">
      {loadingText && <p>{loadingText}</p>}
      <div className="ball-pulse">
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);

export default FullScreenLoader;
