import { FunctionComponent } from 'react';
import Carousel from '../../carousel/Carousel';
import { TravelPartContentViewModel } from '../../../models/TravelPartContentViewModel';
import { asEditable } from '../../../helpers/sitecore';
const Scroll = require('react-scroll');
const Element = Scroll.Element;

interface DayProps extends TravelPartContentViewModel {
  dayTransition: boolean;
}

export const Day: FunctionComponent<DayProps> = ({
  componentTitle,
  componentSubTitle,
  componentText,
  componentShortText,
  carousel,
  dayTransition,
}: DayProps) => {
  return (
    <Element name="dayContent">
      <h2 className="heading">{componentTitle}</h2>
      {componentSubTitle && (
        <div>
          <em>{componentSubTitle}</em>
        </div>
      )}
      <div dangerouslySetInnerHTML={asEditable(componentText)} />

      {componentShortText && (
        <div className="day-content">
          <strong dangerouslySetInnerHTML={asEditable(componentShortText)} />
        </div>
      )}

      {/* Don't show the carousel during transition, this is a fix to reset it's internal state */}
      {!dayTransition && carousel && <Carousel {...carousel} />}
    </Element>
  );
};
