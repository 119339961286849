import { Component } from 'react';
import cx from 'classnames';
import IconSprite from '../../icons/IconSprite';
import { ReactNode } from 'react';

interface FilterContainerProps {
  children?: ReactNode;
}

interface FilterContainerState {
  expanded: boolean;
}

export default class FilterContainer extends Component<FilterContainerProps, FilterContainerState> {
  constructor(props: FilterContainerProps) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.toggleFilters = this.toggleFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
  }

  toggleFilters() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  closeFilters() {
    this.setState({
      expanded: false,
    });
  }

  render() {
    const { children } = this.props;

    return (
      <section className={cx('filters', this.state.expanded && 'filters--expanded')}>
        {children && Object.values(children).some(i => !!i) && (
          <>
            <div className="filterstoggle" onClick={this.toggleFilters}>
              <IconSprite name="filter" />
            </div>
            <div className="filtercontent">
              <p className="heading">Filters</p>
              {children}
              <div className="submit ctabutton" onClick={this.closeFilters}>
                Filters toepassen
              </div>
            </div>
          </>
        )}
      </section>
    );
  }
}
