import { Component, FunctionComponent } from 'react';
import GoogleMapReact, { MapOptions } from 'google-map-react';
import { MapHeaderViewModel } from '../../models/MapHeaderViewModel';
import { mapStyles } from '../mapBlock/style';
import { ContentMapMarker } from '../../models/ContentMapMarker';
import { BreakPoints } from '../../constants/breakPoints';
const withSizes = require('react-sizes');

export interface ContentMarkerProps {
  lat: number;
  lng: number;
  title: string;
  subTitle: string;
  onClick?: () => void;
}

const ContentMarker: FunctionComponent<ContentMarkerProps> = ({ onClick, title, subTitle }) => {
  return (
    <div className="map-content-marker" onClick={onClick}>
      <h2 className="map-content-marker__title">{title}</h2>
      <p className="map-content-marker__subtitle">{subTitle}</p>
    </div>
  );
};

function handleMarkerClick(marker: ContentMapMarker) {
  if (window && marker.url) {
    window.location.assign(marker.url);
  }
}

interface BlogOverviewMapHeaderProps extends MapHeaderViewModel {
  defaultZoom: number;
  defaultCenter: {
    lat: number;
    lng: number;
  };
}

const mapOptions: MapOptions = {
  styles: mapStyles,
  minZoom: 0,
  zoomControl: false,
};

class BlogOverviewMapHeader extends Component<BlogOverviewMapHeaderProps> {
  handleGoogleApiLoaded = ({ map, maps }: { map: google.maps.Map; maps: google.maps.Map[] }) => {
    if (!map) {
      return;
    }

    const { defaultZoom } = this.props;

    const zoom = map.getZoom();
    if (zoom === undefined || zoom > defaultZoom) {
      map.setZoom(defaultZoom);
    }
  };

  render() {
    const { contentMapMarkers, mapsApiKey, defaultZoom, defaultCenter } = this.props;

    return (
      <div className="spotlight-header-wrapper map">
        <section className="spotlight-header">
          <div className="spotlight-header__media-background">
            <GoogleMapReact
              bootstrapURLKeys={{ key: mapsApiKey || '', language: 'nl' }}
              defaultZoom={defaultZoom}
              zoom={0}
              defaultCenter={defaultCenter}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={this.handleGoogleApiLoaded}
              options={mapOptions}
            >
              {contentMapMarkers &&
                contentMapMarkers.map((mapMarker, index) => (
                  <ContentMarker
                    key={mapMarker.id || index}
                    lat={mapMarker.latitude}
                    lng={mapMarker.longitude}
                    title={mapMarker.title || ''}
                    subTitle={mapMarker.subTitle || ''}
                    onClick={() => handleMarkerClick(mapMarker)}
                  />
                ))}
            </GoogleMapReact>
          </div>
        </section>
      </div>
    );
  }
}

const propsMobile = {
  defaultZoom: 0.5,
  defaultCenter: { lat: 55, lng: 66 },
};

const propsMobileLandscape = {
  defaultZoom: 1,
  defaultCenter: { lat: 55, lng: 66 },
};

const propsDesktop = {
  defaultZoom: 1.8,
  defaultCenter: { lat: 45, lng: 66 },
};

const propsWideDesktop = {
  defaultZoom: 2.1,
  defaultCenter: { lat: 35, lng: 66 },
};

const mapSizesToProps = ({ width }) => {
  if (width < BreakPoints.screenXs) {
    return propsMobile;
  }
  if (width < BreakPoints.screenSm) {
    return propsMobileLandscape;
  }
  if (width < BreakPoints.screenMd) {
    return propsDesktop;
  }
  return propsWideDesktop;
};

export default withSizes.withSizes(mapSizesToProps)(BlogOverviewMapHeader);
