import { ActiveFilters } from '../components/holidayResultContainer/HolidayResultContainer';
import { ResultFacet } from '../models/ResultFacet';
import { RangeFacetViewModel } from '../models/RangeFacetViewModel';

export type SearchParams = {
  currentPageId?: string;
  activeFilters: ActiveFilters;
  activePage: number;
  sort?: string;
};

export const createQuerystring = ({ currentPageId, activeFilters, activePage, sort }: SearchParams) => {
  const queryString: string[] = [];

  if (currentPageId) {
    queryString.push(`pageid=${currentPageId}`);
  }

  Object.keys(activeFilters).forEach(key => {
    const activeRange = activeFilters[key].split('-range-');

    // Range filters
    if (activeRange.length > 1) {
      queryString.push(`${key}From=${activeRange[0]}`);
      queryString.push(`${key}To=${activeRange[1]}`);
      // Regular filters
    } else {
      const activeOptions = activeFilters[key].split(',');
      activeOptions.forEach(activeOption => {
        if (activeOption) {
          queryString.push(`${key}=${activeOption}`);
        }
      });
    }
  });

  if (activePage && activePage > 1) {
    queryString.push(`p=${activePage}`);
  }

  if (sort && sort !== 'None') {
    queryString.push(`sort=${sort}`);
  }

  return queryString.join('&');
};

export const setSearchParamsOnHistoryState = (searchparams: SearchParams, url: string) => {
  window.history.pushState({ ...searchparams }, '', url);
};

export const getSearchParamsFromHistory = (): SearchParams => {
  const historyState = window.history.state;

  if (historyState && historyState.activeFilters) {
    return { ...historyState };
  }

  return {
    activeFilters: {},
    activePage: 1,
  };
};

export const buildActiveFiltersFromFacets = (
  facets: ResultFacet[],
  rangeFacets: RangeFacetViewModel[],
  freeText: string | undefined = undefined
): ActiveFilters => {
  const activeFilters: ActiveFilters = {};

  facets.forEach(facet => {
    const selectedIds = facet.values.filter(v => v && v.isSelected).map(v => v.id);
    if (selectedIds && selectedIds.length > 0) {
      activeFilters[facet.id] = selectedIds.join(',');
    }
  });

  rangeFacets.forEach(facet => {
    if (facet.selectedLowestValue || facet.selectedHighestValue) {
      activeFilters[facet.id] = `${facet.selectedLowestValue || facet.lowestValue}-range-${
        facet.selectedHighestValue || facet.highestValue
      }`;
    }
  });

  if (freeText) {
    activeFilters.t = freeText;
  }

  return activeFilters;
};

export const removeRedundantActiveFilters = (activeFilters: ActiveFilters, facets: ResultFacet[]) => {
  Object.keys(activeFilters).forEach(key => {
    if (key === 't') {
      return;
    }

    const facet = facets.find(resultFacet => resultFacet.id === key);
    const rangeFacet = activeFilters[key].indexOf('-range-') > -1;

    // exclude rangeFacets
    if (!rangeFacet) {
      // if the facet isn't returned at all, delete all the active filters from this facet
      if (!facet) {
        delete activeFilters[key];
      } else {
        // only filter out the active filters that are not in the result anymore
        let activeOptions = activeFilters[key].split(',');

        activeOptions = activeOptions.filter(activeOption => {
          return !!facet.values.find(facetValue => facetValue.id === activeOption);
        });

        activeFilters[key] = activeOptions.join(',');
      }
    }
  });

  return activeFilters;
};

/**
 * Removes item from array when it contains the value, adds it otherwise.
 *
 * Returns a new array.
 *
 * @param list
 * @param value
 */
export function flipValue(list: string[], value: string): string[] {
  if (list.includes(value)) {
    return list.filter(v => v !== value);
  }

  return [...list, value];
}
