import { FunctionComponent } from 'react';
import { MapHeaderViewModel } from '../../models/MapHeaderViewModel';
import RoadTrippersLink from '../atoms/roadTrippersLink/RoadTrippersLink';

const MapHeaderRoadTrippersRoute: FunctionComponent<MapHeaderViewModel> = ({ roadTrippersCode }) => (
  <div className="spotlight-header-wrapper map">
    <section className="spotlight-header">
      <div className="spotlight-header__media-background">
        {roadTrippersCode && (
          <iframe
            title="Kaart van deze route op RoadTrippers.com"
            width="100%"
            height="100%"
            className="roadtrippersembed"
            scrolling="no"
            src={`https://maps.roadtrippers.com/embedded/trips/${roadTrippersCode}?title=false&stats=false`}
          />
        )}
      </div>
      {roadTrippersCode && <RoadTrippersLink roadTrippersCode={roadTrippersCode} />}
    </section>
  </div>
);

export default MapHeaderRoadTrippersRoute;
