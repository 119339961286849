import { Children, cloneElement, Component, FunctionComponent, ReactElement, ReactNode } from 'react';
import { ReactPlaceholder } from '../../models/ReactPlaceholder';
import { asEditable } from '../../helpers/sitecore';

interface SFCPlaceholderProps {
  children?: ReactNode;
  placeholders?: ReactPlaceholder[];
  name?: string;
  placeholderIndex?: number;
  classNames?: string[];
  extractScripts?: boolean;
}

export const Placeholder: FunctionComponent<SFCPlaceholderProps> = (props: SFCPlaceholderProps) => {
  if (props.placeholders) {
    return renderSitecorePlaceholders(props);
  } else if (props.children) {
    return renderReactPlaceholders(props);
  }

  return null;
};

export class PrivatePlaceholder extends Component<any, unknown> {
  displayName!: 'Placeholder';
  script: HTMLScriptElement | null = null;

  render() {
    if (this.props.hasOwnProperty('placeholder') && this.props.placeholder != null) {
      return this.renderSitecore();
    }

    return this.renderFED();
  }

  componentDidMount(): void {
    const { extractScripts, placeholder } = this.props;

    if (extractScripts && placeholder) {
      const scriptMatcher = /<script>(.+)<\/script>/gs;
      let match;
      while ((match = scriptMatcher.exec(placeholder as string))) {
        const script = document.createElement('script');
        script.innerHTML = match[1];
        document.head.appendChild(script);
        this.script = script;
      }
    }
  }

  componentWillUnmount(): void {
    if (this.script !== null) {
      document.head.removeChild(this.script);
    }
  }

  renderSitecore() {
    let { placeholder } = this.props;
    const { extractScripts } = this.props;
    const placeHolderLength = placeholder.match(/\bplaceholder/g);

    if (extractScripts) {
      placeholder = (placeholder as string).replace(/<script>(.+)<\/script>/gs, '');
    }

    return (
      <div
        className="placeholder__container"
        data-placeholder-length={placeHolderLength && placeHolderLength.length}
        dangerouslySetInnerHTML={asEditable(placeholder)}
      />
    );
  }

  renderFED() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    if (this.props.content) {
      // Todo: refactor for strict null checking
      let content: any = [];
      // let fallbackToAll = true;

      if (typeof this.props.content === 'string') {
        return <span>{this.props.content}</span>;
      }

      if (Children.count(this.props.content) > 0) {
        content = Children.map(this.props.content, child => {
          const reactChild = child as ReactElement<any>;
          if (reactChild.props.hasOwnProperty('placeholderKey')) {
            if (reactChild.props.placeholderKey === self.props.placeholderKey) {
              return child;
            }
          }
          return;
        });
      }

      if (content.length > 0) {
        return <div>{content}</div>;
      }

      return <h2>No Content</h2>;
    }
    if (this.props.children) {
      let content: any = [];
      if (Children.count(this.props.children) > 0) {
        content = Children.map(this.props.children, child => {
          const reactChild = child as ReactElement<any>;
          if (reactChild.props.hasOwnProperty('placeholderKey')) {
            if (reactChild.props.placeholderKey === self.props.placeholderKey) {
              return child;
            }
          }
          return;
        });
      }

      if (content.length > 0) {
        return <div>{content}</div>;
      }

      return this.props.children;
    }

    return <h2>No content</h2>;
  }
}

function renderSitecorePlaceholders({ placeholders, name, placeholderIndex, extractScripts }: SFCPlaceholderProps) {
  if (!placeholders) {
    return null;
  }

  const targetPlaceholder = placeholders.find((placeholder, index) => {
    if (name) {
      return placeholder.placeholderKey === name;
    }
    if (typeof placeholderIndex !== 'undefined') {
      return index === placeholderIndex;
    }
    return false;
  });

  if (targetPlaceholder) {
    return (
      <PrivatePlaceholder
        placeholderKey={targetPlaceholder.placeholderKey}
        isDynamic={targetPlaceholder.isDynamic}
        placeholder={targetPlaceholder.placeholder}
        extractScripts={extractScripts}
      />
    );
  }

  if (name || placeholderIndex) {
    return null;
  }

  return (
    <>
      {placeholders.map((placeholder, index) => (
        <PrivatePlaceholder
          placeholderKey={placeholder.placeholderKey}
          isDynamic={placeholder.isDynamic}
          placeholder={placeholder.placeholder}
          key={index}
          extractScripts={extractScripts}
        />
      ))}
    </>
  );
}

function renderReactPlaceholders({ classNames, children, name, placeholderIndex }: SFCPlaceholderProps) {
  if (!children) {
    return null;
  }

  let returnOneChild = false;
  let returnChildren: ReactElement<any>[] = [];

  Children.forEach(children, (child, index) => {
    const reactChild = child as ReactElement<any>;
    const clonedChild = cloneElement(reactChild, {
      renderedbyplaceholder: 'true',
      key: index,
    });

    // if a name or index is requested, only return this result
    if (
      (reactChild.props.name && name && reactChild.props.name === name) ||
      (typeof placeholderIndex !== 'undefined' && index === placeholderIndex)
    ) {
      returnOneChild = true;
      returnChildren = [];
      returnChildren.push(clonedChild);
    }

    if (!returnOneChild) {
      returnChildren.push(reactChild);
    }
  });

  // Okay, there are divs added here. This is done because React.NET also renders them. This way we keep both environments in sync.
  // The first div is added by the renderSitecore method in React. The second one is added by React.NET. This adds the id="react_*" attribute.
  if ((typeof name !== 'undefined' || typeof placeholderIndex !== 'undefined') && !returnChildren.length) {
    return null;
  }

  return (
    <div className="placeholder__container" data-placeholder-length={returnChildren.length}>
      {returnChildren.map((child, index) => (
        <div
          key={index}
          className={`placeholder index${index}${classNames && classNames[index] ? ' ' + classNames[index] : ''}`}
        >
          {child}
        </div>
      ))}
    </div>
  );
}

export const RenderReactPlaceholder = ({ renderedbyplaceholder, children }) => {
  if (renderedbyplaceholder && renderedbyplaceholder === 'true' && Children.count(children) > 0) {
    return children;
  }

  return null;
};
