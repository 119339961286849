import { FocusEvent, FormEvent, FunctionComponent, useEffect, useState } from 'react';
import Text from '../atoms/text/Text';
import FuelCalculatorPane from './FuelCalculatorPane';
import {
  DestinationDataItem,
  DistanceType,
  PaneData,
  PaneDataItem,
  PaneState,
  PaneType,
  VehicleDataItem,
} from './types';
import FuelCalculatorResult from './FuelCalculatorResult';
import { findItem, calculateDistance, reverseColorCombination, distanceTypes } from './utils';
import { FuelCalculatorViewModel } from '../../models/FuelCalculatorViewModel';
import { handleError, jsonOrThrow } from '../../helpers/fetch';

interface FormState {
  distance?: number;
  distanceType: DistanceType;
  days?: number;
  paneOpen?: PaneType;
  paneState: PaneState;
  destinationData?: PaneData<DestinationDataItem>;
  vehicleData?: PaneData<VehicleDataItem>;
  destination?: DestinationDataItem;
  vehicle?: VehicleDataItem;
}

function doFetch(
  setState: (value: ((prevState: FormState) => FormState) | FormState) => void,
  paneType: PaneType,
  apiUrl: string,
  destinationId: string | undefined
): Promise<PaneDataItem[] | null> {
  let url = `${apiUrl}${paneType}`;
  if (paneType === PaneType.Vehicles && destinationId) {
    url += `/${encodeURIComponent(destinationId)}`;
  }

  return fetch(url)
    .then(jsonOrThrow)
    .then(r => {
      setState(oldState => ({
        ...oldState,
        paneState: oldState.paneOpen === paneType ? PaneState.Loaded : oldState.paneState,
        destinationData:
          paneType === PaneType.Destination ? { items: r as DestinationDataItem[] } : oldState.destinationData,
        vehicleData: paneType === PaneType.Vehicles ? { items: r as VehicleDataItem[] } : oldState.vehicleData,
      }));

      return r as PaneDataItem[];
    })
    .catch(e => {
      handleError('Error fetching fuel informaton', e);

      setState(oldState => ({
        ...oldState,
        paneState: oldState.paneOpen === paneType ? PaneState.Error : oldState.paneState,
      }));

      return null;
    });
}

function triggerPane(
  event: FocusEvent<HTMLInputElement>,
  paneType: PaneType,
  apiUrl: string,
  setState: (value: ((prevState: FormState) => FormState) | FormState) => void
): void {
  event.preventDefault();

  setState(state => {
    if (paneType === PaneType.Vehicles && state.vehicleData) {
      return { ...state, paneOpen: paneType, paneState: PaneState.Loaded };
    }

    if (paneType === PaneType.Destination && state.destinationData) {
      return { ...state, paneOpen: paneType, paneState: PaneState.Loaded };
    }

    if (paneType === PaneType.Vehicles && !state.destination) {
      return state;
    }

    doFetch(setState, paneType, apiUrl, state.destination && state.destination.key);

    return { ...state, paneOpen: paneType, paneState: PaneState.Loading };
  });
}

function hasEnoughData(state: FormState): boolean {
  if (!state.destination || !state.vehicle || !state.distance || state.distance <= 0) {
    return false;
  }

  if (state.distanceType === DistanceType.KmPerDay || state.distanceType === DistanceType.MilePerDay) {
    if (!state.days || state.distance <= 0) {
      return false;
    }
  }

  return true;
}

const FuelCalculator: FunctionComponent<FuelCalculatorViewModel> = ({
  componentTitle,
  destination,
  colorCombination,
  disclaimer,
  apiUrl,
}) => {
  // Setup state.
  const [state, setState] = useState<FormState>(() => ({
    distanceType: DistanceType.KmPerDay,
    paneState: PaneState.None,
  }));

  // Load destination data when destination is preselected.
  useEffect(() => {
    if (destination) {
      doFetch(setState, PaneType.Destination, apiUrl, undefined).then(items => {
        if (items) {
          const destinationItem = findItem<DestinationDataItem>(destination, items as DestinationDataItem[]);
          if (destinationItem) {
            // If the destination item has children, use it as a fixed parent.
            // Otherwise, use it as a preselected item.
            const children = destinationItem.children as DestinationDataItem[] | undefined;
            if (children && children.length > 0) {
              setState(old => ({ ...old, destinationData: { items: children } }));
            } else {
              setState(old => ({ ...old, destination: destinationItem }));
            }
          }
        }
      });
    }
  }, [destination, apiUrl]);

  const showDaysField = state.distanceType === DistanceType.KmPerDay || state.distanceType === DistanceType.MilePerDay;

  const distance = calculateDistance(state.days, state.distance, state.distanceType);

  return (
    <aside
      className={`custom_cta fuelcalculator actionwidget--${colorCombination ? colorCombination : 'theme-secondary'}`}
    >
      {componentTitle && <Text {...componentTitle} />}
      <form
        className="fuelcalculator__form"
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          setState(fs => ({ ...fs, paneOpen: hasEnoughData(fs) ? PaneType.Result : fs.paneOpen }));
        }}
      >
        <div className="fuelcalculator__distancesection">
          <label htmlFor="field-distance" className="fuelcalculator__sectionlabel">
            Afstand
          </label>
          <input
            type="number"
            id="field-distance"
            className="fuelcalculator__distance"
            value={state.distance || ''}
            onChange={e => setState({ ...state, distance: e.target.value ? parseInt(e.target.value, 10) : undefined })}
            placeholder="Advies: 200 km/dag"
            min={0}
          />
          <select
            className="fuelcalculator__distancetype"
            value={state.distanceType}
            onChange={e => setState({ ...state, distanceType: e.target.value as DistanceType })}
          >
            {distanceTypes.map(dt => (
              <option key={dt.key} value={dt.key}>
                {dt.title}
              </option>
            ))}
          </select>
          {showDaysField && (
            <>
              <input
                className="fuelcalculator__days"
                type="number"
                id="field-days"
                value={state.days || ''}
                onChange={e => setState({ ...state, days: e.target.value ? parseInt(e.target.value, 10) : undefined })}
                min={0}
              />{' '}
              <label htmlFor="field-days" className="fuelcalculator__dayslabel">
                dagen
              </label>
            </>
          )}
        </div>
        <div className="fuelcalculator__destinationsection">
          <label htmlFor="destination" className="fuelcalculator__sectionlabel">
            Bestemming
          </label>
          <input
            className="fuelcalculator__destination"
            type="text"
            id="destination"
            value={(state.destination && state.destination.title) || ''}
            placeholder="Kies je bestemming"
            onFocus={(e: FocusEvent<HTMLInputElement>) => triggerPane(e, PaneType.Destination, apiUrl, setState)}
            autoComplete="off"
            readOnly={true}
          />
          {state.paneOpen === PaneType.Destination && (
            <FuelCalculatorPane
              state={state.paneState}
              data={state.destinationData}
              currentItemKey={state.destination && state.destination.key}
              onSelect={dest => {
                setState(s => ({
                  ...s,
                  paneOpen: undefined,
                  destination: dest ? (dest as DestinationDataItem) : s.destination,
                  vehicle: dest ? undefined : s.vehicle,
                  vehicleData: dest ? undefined : s.vehicleData,
                }));
              }}
            />
          )}
        </div>
        <div className="fuelcalculator__vehiclesection">
          <label htmlFor="vehicle" className="fuelcalculator__sectionlabel">
            Camper
          </label>
          <input
            className="fuelcalculator__vehicle"
            type="text"
            id="vehicle"
            value={(state.vehicle && state.vehicle.title) || ''}
            placeholder={state.destination ? 'Kies je campertype' : 'Kies eerst je bestemming'}
            onFocus={(e: FocusEvent<HTMLInputElement>) => triggerPane(e, PaneType.Vehicles, apiUrl, setState)}
            autoComplete="off"
            readOnly={true}
          />
          {state.paneOpen === PaneType.Vehicles && (
            <FuelCalculatorPane
              state={state.paneState}
              data={state.vehicleData}
              currentItemKey={state.vehicle && state.vehicle.key}
              onSelect={vehicle => {
                setState(s => ({
                  ...state,
                  vehicle: vehicle ? (vehicle as VehicleDataItem) : s.vehicle,
                  paneOpen: undefined,
                }));
              }}
            />
          )}
        </div>
        <div className="fuelcalculator__buttons">
          <button className="cta_button fuelcalculator__button" type="submit" disabled={!hasEnoughData(state)}>
            Bereken je kosten
          </button>
        </div>

        {state.paneOpen === PaneType.Result && state.destination && state.vehicle && distance && (
          <FuelCalculatorResult
            destination={state.destination}
            vehicle={state.vehicle}
            distance={distance}
            colorCombination={reverseColorCombination(colorCombination)}
            disclaimer={disclaimer}
          />
        )}
      </form>
    </aside>
  );
};

export default FuelCalculator;
