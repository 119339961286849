import Cookies from 'js-cookie';
import { add } from 'date-fns';

export const getCurrentLevel = (): number | undefined => {
  const cookieValue = Cookies.get('thconsent');
  if (cookieValue === undefined) {
    return undefined;
  }

  const level = Number(cookieValue);
  if (Number.isNaN(level) || level < 0 || level > 2) {
    return undefined;
  }

  return level;
};

export const handleConsentPick = (level: number): void => {
  const host = window.location.hostname; // host contains port, don't use that one.
  Cookies.set('thconsent', level.toString(), {
    domain: host.endsWith('.travelhome.nl') ? '.travelhome.nl' : host,
    expires: add(new Date(), { years: 1 }),
    secure: !/localhost/.test(host),
    sameSite: 'lax',
    path: '/',
  });

  if ('onConsentLevelUpdate' in window && typeof window.onConsentLevelUpdate === 'function') {
    window.onConsentLevelUpdate(level);
  }
};
