import { Component } from 'react';
import { Field, withTypes } from 'react-final-form';
import createDecorator from 'final-form-focus';
import TextField from '../atoms/form/TextField';
import HorizontalRadioBoxes from '../atoms/form/HorizontalRadioBoxes';
import CheckBox from '../atoms/form/CheckBox';
import SubmitButton from '../atoms/form/SubmitButton';
import { BrochureFormViewModel } from '../../models/BrochureFormViewModel';
import SuccessMessage from '../atoms/message/SuccessMessage';
import ErrorMessage from '../atoms/message/ErrorMessage';
import Dropdown from '../atoms/form/Dropdown';
import { submitForm } from '../../helpers/form';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import RadioButtons from '../atoms/form/RadioButtons';
import { BrochureViewModel } from '../../models/BrochureViewModel';
import { BrochureFormInputModel } from '../../models/BrochureFormInputModel';

const decorators = [createDecorator()];

const { Form } = withTypes<BrochureFormInputModel>();

const BrochureRadioButtons = RadioButtons as new () => RadioButtons<BrochureViewModel>;

class BrochureForm extends Component<BrochureFormViewModel> {
  handleSubmit = (initialValues: BrochureFormInputModel) =>
    submitForm<BrochureFormInputModel>(this.props, initialValues);

  render() {
    const { brochures, successMessage, fieldNames, genders, countries } = this.props;
    const requiredTermsField = value => (value ? undefined : this.props.termsFieldValidationMessage);
    const requiredField = value => (value ? undefined : this.props.clientSideFieldValidationMessage);

    const initialValues: BrochureFormInputModel = {
      countryCode: Object.keys(countries)[0],
      gender: '',
      givenName: '',
      initials: '',
      agree: false,
      city: '',
      houseNumber: '',
      newsletter: false,
      postalCode: '',
      streetName: '',
      surname: '',
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleSubmit(initialValues)}
        decorators={decorators}
        subscription={{
          pristine: true,
          invalid: true,
          submitFailed: true,
          submitError: true,
          submitErrors: true,
          submitSucceeded: true,
          submitting: true,
        }}
        render={({ handleSubmit, pristine, invalid, submitFailed, submitError, submitSucceeded, submitting }) => {
          return (
            <form onSubmit={handleSubmit} noValidate={true}>
              {!submitting && invalid && submitFailed && <ErrorMessage>{this.props.submitErrorMessage}</ErrorMessage>}
              {!submitting && submitSucceeded && pristine && (
                <SuccessMessage scrollIntoView={true}>
                  <div dangerouslySetInnerHTML={{ __html: successMessage }} />
                </SuccessMessage>
              )}
              {!submitting && submitError && <ErrorMessage scrollIntoView={true}>{submitError}</ErrorMessage>}

              <h4>{fieldNames.brochures}</h4>

              <div className="brochureform-brochures">
                <Field
                  component={BrochureRadioButtons}
                  className="form-item--brochures"
                  name="brochure"
                  options={brochures.filter(b => !b.link)}
                  valueOf={(brochure: BrochureViewModel) => brochure.id}
                  labelOf={(brochure: BrochureViewModel) => (
                    <div className="brochureform-brochure">
                      <ImageHtml html={brochure.image} class="brochureform-brochureimage" />
                      <span
                        className="brochureform-brochuretitle"
                        dangerouslySetInnerHTML={{ __html: brochure.title }}
                      />
                    </div>
                  )}
                />
                {brochures
                  .filter(b => b.link)
                  .map(brochure => (
                    <div className="brochureform-brochure" key={brochure.id}>
                      <a href={brochure.link}>
                        <ImageHtml html={brochure.image} class="brochureform-brochureimage" />
                      </a>
                      <a
                        href={brochure.link}
                        className="brochureform-brochuretitle"
                        dangerouslySetInnerHTML={{ __html: brochure.title }}
                      />
                    </div>
                  ))}
              </div>

              <Field component={HorizontalRadioBoxes} name="gender" label={fieldNames.gender} options={genders} />
              <Field
                validate={requiredField}
                component={TextField}
                name="givenName"
                label={fieldNames.givenName}
                required={true}
              />
              <Field
                validate={requiredField}
                component={TextField}
                name="initials"
                label={fieldNames.initials}
                required={true}
              />
              <Field component={TextField} name="surnamePrefix" label={fieldNames.surnamePrefix} />
              <Field
                validate={requiredField}
                component={TextField}
                name="surname"
                label={fieldNames.surname}
                required={true}
              />
              <Field
                validate={requiredField}
                component={Dropdown}
                name="countryCode"
                label={fieldNames.countryCode}
                required={true}
                options={countries}
              />
              <Field
                validate={requiredField}
                component={TextField}
                name="postalCode"
                label={fieldNames.postalCode}
                required={true}
                size={10}
              />
              <Field
                validate={requiredField}
                component={TextField}
                name="houseNumber"
                label={fieldNames.houseNumber}
                required={true}
                size={10}
              />
              <Field component={TextField} name="houseNumberSuffix" label={fieldNames.houseNumberSuffix} size={10} />
              <Field
                validate={requiredField}
                component={TextField}
                name="streetName"
                label={fieldNames.streetName}
                required={true}
              />
              <Field
                validate={requiredField}
                component={TextField}
                name="city"
                label={fieldNames.city}
                required={true}
              />
              <Field component={TextField} name="email" type="email" label={fieldNames.email} />
              <Field component={TextField} name="emailConfirm" type="email" label={fieldNames.emailConfirm} />

              <h4>Nieuwbrief</h4>

              <Field
                component={CheckBox}
                type="checkbox"
                name="newsletter"
                label={<span dangerouslySetInnerHTML={{ __html: fieldNames.newsletter }} />}
              />

              <h4>Privacy</h4>

              <Field
                validate={requiredTermsField}
                component={CheckBox}
                type="checkbox"
                required={true}
                name="agree"
                label={<span dangerouslySetInnerHTML={{ __html: fieldNames.agree }} />}
              />

              <SubmitButton disabled={pristine || invalid} />
            </form>
          );
        }}
      />
    );
  }
}

export default BrochureForm;
