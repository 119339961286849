import { FunctionComponent } from 'react';
import { TextViewModel } from '../../../models/TextViewModel';
import { createElement } from 'react';

// inspired by the npm module @sitecore-jss/sitecore-jss-react
export interface ExtendedTextViewModel extends TextViewModel {
  [htmlAttributes: string]: any;
}

const Text: FunctionComponent<ExtendedTextViewModel> = ({ value, tag, editable, encode, ...otherProps }) => {
  if (!value && !editable) {
    return null;
  }

  // can't use editable value if we want to output unencoded
  if (!encode) {
    editable = false;
  }

  const output = value;
  const setDangerously = editable || !encode;

  let children: string | undefined = undefined;
  const htmlProps: any = {
    ...otherProps,
  };

  if (setDangerously) {
    htmlProps.dangerouslySetInnerHTML = {
      __html: output,
    };
  } else {
    children = output;
  }

  if (tag || setDangerously) {
    return createElement(tag || 'h3', htmlProps, children);
  }

  return <>{children}</>;
};

export default Text;
