/* global google */
import { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from '../mapHeader/marker/Marker';
import { mapStyles } from './style';
import { MapBlockViewModel } from '../../models/MapBlockViewModel';

interface MapBlockState {
  map?: google.maps.Map;
}

class MapBlock extends Component<MapBlockViewModel, MapBlockState> {
  infowindow!: google.maps.InfoWindow;

  constructor(props: MapBlockViewModel) {
    super(props);

    this.handleGoogleApiLoaded = this.handleGoogleApiLoaded.bind(this);
    this.setMarker = this.setMarker.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
  }

  componentDidUpdate(prevProps: MapBlockViewModel) {
    if (this.props.locationMapMarker) {
      if (prevProps.locationMapMarker && prevProps.locationMapMarker.title !== this.props.locationMapMarker.title) {
        this.setMarker();
      }
    }
  }

  setMarker() {
    const { locationMapMarker } = this.props;

    if (!locationMapMarker || !locationMapMarker.title) {
      return;
    }

    const content = `
        <div class="info-window">
            <p class="info-window__title">${locationMapMarker.title}</p>
            ${locationMapMarker.subTitle ? `<p class="info-window__sub-title">${locationMapMarker.subTitle}</p>` : ''}
        </div>
    `;

    this.infowindow = new google.maps.InfoWindow({
      position: {
        lat: locationMapMarker.latitude,
        lng: locationMapMarker.longitude,
      },
      content: content,
      maxWidth: 200,
    });

    if (this.infowindow) {
      this.infowindow.close();
    }

    this.infowindow.open(this.state.map);
  }

  handleMarkerClick() {
    if (this.infowindow) {
      this.infowindow.open(this.state.map);
    }
  }

  handleGoogleApiLoaded({ map }: { map: google.maps.Map }) {
    this.setState({
      map: map,
    });

    this.setMarker();
  }

  render() {
    const { mapsApiKey, locationMapMarker } = this.props;
    return (
      <div className="map-block">
        <div className="map-block__inner">
          {locationMapMarker && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: mapsApiKey || '', language: 'nl' }}
              defaultZoom={11}
              defaultCenter={{
                lat: locationMapMarker.latitude,
                lng: locationMapMarker.longitude,
              }}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={this.handleGoogleApiLoaded}
              options={{ styles: mapStyles }}
            >
              <Marker
                lat={locationMapMarker.latitude}
                lng={locationMapMarker.longitude}
                icon={locationMapMarker.icon || ''}
                onClick={this.handleMarkerClick}
                title={locationMapMarker.title}
              />
            </GoogleMapReact>
          )}
        </div>
      </div>
    );
  }
}

export default MapBlock;
