import { FunctionComponent } from 'react';
import { IntroTextViewModel } from '../../models/IntroTextViewModel';
import Text from '../atoms/text/Text';
import { asEditable } from '../../helpers/sitecore';

const IntroTextSearchResults: FunctionComponent<IntroTextViewModel> = ({
  componentTitle,
  componentText,
}: IntroTextViewModel) => (
  <section>
    <div className="quote">
      {componentTitle && <Text {...componentTitle} className="heading" />}
      <div className="content" dangerouslySetInnerHTML={asEditable(componentText)} />
    </div>
  </section>
);

export default IntroTextSearchResults;
