import { Component, PropsWithChildren } from 'react';
import { Placeholder } from './Placeholder';
import { ContainerViewModel } from '../../models/ContainerViewModel';

class BlogEditContainer extends Component<PropsWithChildren<ContainerViewModel>> {
  render() {
    const { children, placeholders, isInEditMode } = this.props;

    return (
      <div className="boxed">
        <div className="container-full-width">
          <Placeholder children={children} placeholders={placeholders} placeholderIndex={0} />
          {isInEditMode && (
            <div className="blog-edit-mode">
              <Placeholder children={children} placeholders={placeholders} placeholderIndex={1} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default BlogEditContainer;
