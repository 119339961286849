import { FunctionComponent } from 'react';
import { asEditable } from '../../../helpers/sitecore';

interface ReadMoreProps {
  buttonMoreText: string;
  buttonLessText: string;
  text?: string;
  uniqueId: string;
  editMode: boolean;
}

const ReadMore: FunctionComponent<ReadMoreProps> = ({ text, buttonLessText, buttonMoreText, uniqueId, editMode }) => {
  if (editMode) {
    return (
      <div className="readmore readmore--editmode">
        <div className="readmore__content" dangerouslySetInnerHTML={asEditable(text)} />
      </div>
    );
  }

  if (!text) {
    return null;
  }

  return (
    <div className="readmore">
      <input id={uniqueId} type="checkbox" aria-hidden={true} className="readmore__triggercheckbox" />
      <label className="readmore__triggerlabel readmore__triggerlabel--show" htmlFor={uniqueId}>
        {buttonMoreText}
      </label>
      <label className="readmore__triggerlabel readmore__triggerlabel--hide" htmlFor={uniqueId}>
        {buttonLessText}
      </label>
      <div className="readmore__content" dangerouslySetInnerHTML={asEditable(text)} />
    </div>
  );
};

export default ReadMore;
