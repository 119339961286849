import { Fragment, FunctionComponent, useState } from 'react';
import groupBy from 'lodash/groupBy';
import { CamperViewModel } from '../../models/CamperViewModel';
import { asEditable } from '../../helpers/sitecore';
import Carousel from '../carousel/Carousel';
import Paging from '../atoms/paging/Paging';
import { VideoPlayerWrapper } from '../video/VideoPlayerWrapper';
import { writeDriverAge, writeMaximumRecommendedPassengers, writeRange, writeYearsAndMonths } from './utils';
import Score from '../score/Score';
import Stars from '../atoms/stars/Stars';
import TooltipButtonAuto from '../tooltip/TooltipButtonAuto';

export interface CamperInformationProps extends CamperViewModel {
  disclaimer?: string;
}

const CamperInformation: FunctionComponent<CamperInformationProps> = (vehicle: CamperInformationProps) => {
  const [videoNr, setVideoNr] = useState(0);
  const facetsByCategory = Object.entries(groupBy(vehicle.facets, facet => facet.category.name));

  const { videos, supplier, categoryFacet } = vehicle;
  const { stars, score, title: companyTitle } = supplier;

  return (
    <section className="camperinformation">
      <h2 className="heading">
        {vehicle.name} van {companyTitle}
      </h2>

      {vehicle.carousel && <Carousel {...vehicle.carousel} />}

      <dl className="camperinformation__definitionlist">
        {categoryFacet && (
          <>
            <dt>Voertuigcategorie</dt>
            <dd>
              {categoryFacet.name}
              {categoryFacet.description && (
                <TooltipButtonAuto small={true} tooltipClass="camperinformation__tooltip">
                  <div dangerouslySetInnerHTML={asEditable(categoryFacet.description)} />
                </TooltipButtonAuto>
              )}
            </dd>
          </>
        )}
        {facetsByCategory.map(([categoryName, facetValues]) => (
          <Fragment key={categoryName}>
            <dt>{categoryName}</dt>
            <dd>
              {facetValues
                .map(fv => fv.name)
                .sort()
                .join(', ')}
            </dd>
          </Fragment>
        ))}
        {vehicle.maximumPassengers && (
          <>
            <dt>Maximum passagiers</dt>
            <dd>{vehicle.maximumPassengers}</dd>
          </>
        )}
        {vehicle.recommendedMaximumAdults && (
          <>
            <dt>Maximum aanbevolen passagiers</dt>
            <dd>{writeMaximumRecommendedPassengers(vehicle)}</dd>
          </>
        )}
        {vehicle.minimumPassengerAge && (
          <>
            <dt>Min. leeftijd passagiers</dt>
            <dd>{writeYearsAndMonths(vehicle.minimumPassengerAge)}</dd>
          </>
        )}
        {vehicle.driverAge && (
          <>
            <dt>Bestuurderleeftijd</dt>
            <dd>
              <div className="htmlcontainer" dangerouslySetInnerHTML={asEditable(writeDriverAge(vehicle.driverAge))} />
            </dd>
          </>
        )}
        {vehicle.minimumDriversLicenseAge && (
          <>
            <dt>Min. bezit rijbewijs</dt>
            <dd>{writeYearsAndMonths(vehicle.minimumDriversLicenseAge)}</dd>
          </>
        )}
        {vehicle.length && (
          <>
            <dt>Voertuiglengte</dt>
            <dd>{writeRange(vehicle.length, 'cm')}</dd>
          </>
        )}
        {vehicle.width && (
          <>
            <dt>Voertuigbreedte</dt>
            <dd>{writeRange(vehicle.width, 'cm')}</dd>
          </>
        )}
        {vehicle.height && (
          <>
            <dt>Voertuighoogte</dt>
            <dd>{writeRange(vehicle.height, 'cm')}</dd>
          </>
        )}
        {vehicle.interiorHeight && (
          <>
            <dt>Interieurhoogte</dt>
            <dd>{writeRange(vehicle.interiorHeight, 'cm')}</dd>
          </>
        )}
        {vehicle.fuelConsumption && (
          <>
            <dt>Brandstofverbruik</dt>
            <dd>{writeRange(vehicle.fuelConsumption, `L/100 km`)}</dd>
          </>
        )}
        {vehicle.fuelTankCapacity && (
          <>
            <dt>Brandstoftank</dt>
            <dd>{writeRange(vehicle.fuelTankCapacity, 'L')}</dd>
          </>
        )}
        {vehicle.freshWaterTankCapacity && (
          <>
            <dt>Verswatertank</dt>
            <dd>{writeRange(vehicle.freshWaterTankCapacity, 'L')}</dd>
          </>
        )}
        {vehicle.greyWaterTankCapacity && (
          <>
            <dt>Grijswatertank</dt>
            <dd>{writeRange(vehicle.greyWaterTankCapacity, 'L')}</dd>
          </>
        )}
        {vehicle.blackWaterTankCapacity && (
          <>
            <dt>Zwartwatertank</dt>
            <dd>{writeRange(vehicle.blackWaterTankCapacity, 'L')}</dd>
          </>
        )}
        {vehicle.modelYear && (
          <>
            <dt>Modeljaar</dt>
            <dd>{writeRange(vehicle.modelYear, '')}</dd>
          </>
        )}
      </dl>

      {(vehicle.beds.length > 0 || vehicle.bedsRemarks) && (
        <>
          <h3>Bedden in deze camper</h3>
          {vehicle.beds.length > 0 && (
            <table className="camperinformation__beds">
              <thead>
                <tr>
                  <th />
                  <th>Lengte</th>
                  <th>Breedte</th>
                </tr>
              </thead>
              <tbody>
                {vehicle.beds.map(b => (
                  <tr key={b.id}>
                    <th>{b.name}</th>
                    <td>{b.length} cm</td>
                    <td>{b.width} cm</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}

      {vehicle.childSeats && (
        <>
          <h3>Kinderstoelen</h3>
          <div dangerouslySetInnerHTML={asEditable(vehicle.childSeats)} />
        </>
      )}
      {vehicle.text && (
        <>
          <h3>Beschrijving</h3>
          <div dangerouslySetInnerHTML={asEditable(vehicle.text)} />
        </>
      )}

      {stars && (
        <section>
          <h3>
            Onze beoordeling: <Stars count={Number(stars)} color="_tertiary" className="camperinformation__stars" />
          </h3>
          <div className="keepnewlines">
            Het aantal sterren (1-5) waarmee wij onze verhuurders beoordelen, is gebaseerd op:
            <br />
            <br />
            - onze jarenlange ervaring;
            <br />
            - de feedback van onze reizigers;
            <br />
            - de leeftijd en de staat van de campers;
            <br />
            - de ophaal- en inleverprocedure;
            <br />
            - de assistentie onderweg;
            <br />
            - de customer-service bij problemen of klachten tijdens/na de reis.
            <br />
            <br />
            Deze beoordeling is subjectief en is bedoeld als een richtlijn. Er kunnen geen rechten ontleend worden aan
            deze classificatie.
          </div>
        </section>
      )}

      {score && (
        <section>
          <h3>Klantwaardering</h3>
          <Score score={score} supplierName={companyTitle} />
        </section>
      )}

      {vehicle.virtualTourUrl && (
        <>
          <h3>360° tour</h3>
          <div className="camperinformation__virtualtour">
            <iframe src={vehicle.virtualTourUrl} allowFullScreen={true} title={`${vehicle.name} virtual tour`} />
          </div>
        </>
      )}

      {videos && videos.length > 0 && (
        <>
          {videos.length === 1 && <h3>Video</h3>}
          {videos.length > 1 && (
            <div className="camperinformation__videoheader">
              <h3>Video's</h3>
              <Paging
                className="camperinformation__videopager"
                onPrevious={videoNr > 0 ? () => setVideoNr(videoNr - 1) : undefined}
                onNext={videoNr < videos.length - 1 ? () => setVideoNr(videoNr + 1) : undefined}
              />
            </div>
          )}

          <div className="camperinformation__video">
            <VideoPlayerWrapper videoId={videos[videoNr].id} setMaxSize={true} interactive={true} />
          </div>
        </>
      )}

      {vehicle.disclaimer && (
        <>
          <h3>Disclaimer</h3>
          <div dangerouslySetInnerHTML={asEditable(vehicle.disclaimer)} />
        </>
      )}
    </section>
  );
};

export default CamperInformation;
