import { Component } from 'react';
import { stringify } from 'qs';
import SearchForm, { SearchFormSelections } from './SearchForm';
import { SearchFormViewModel } from '../../models/SearchFormViewModel';
import { jsonOrThrow } from '../../helpers/fetch';

export default class SearchFormContainer extends Component<SearchFormViewModel> {
  handleSubmit = (selections: SearchFormSelections) => {
    const { departureFacet, countryFacet, personsFacet } = this.props;

    const queryParams = {
      [departureFacet.id]: selections.departures,
      [countryFacet.id]: selections.countries,
      [personsFacet.id]: selections.numberOfPersons,
    };

    fetch(
      `/travelhome/api/productsearch/url?${stringify(queryParams, {
        indices: false,
      })}`
    )
      .then(jsonOrThrow)
      .then(json => {
        window.location.href = json.url;
      })
      .catch(err => {
        console.error('Error while fetching results', err);
      });
  };

  render() {
    return (
      <div className="searchform-container">
        <SearchForm onSubmit={this.handleSubmit} {...this.props} />
      </div>
    );
  }
}
