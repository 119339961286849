import { Component, SyntheticEvent } from 'react';
import cx from 'classnames';
import { ResultFacetValue } from '../../models/ResultFacetValue';
import { SearchFormViewModel } from '../../models/SearchFormViewModel';
import { flipValue } from '../../helpers/search';

export interface SearchFormSelections {
  departures: string[];
  numberOfPersons?: string;
  countries: string[];
}

export interface SearchFormProps extends SearchFormViewModel {
  onSubmit: (selections: SearchFormSelections) => void;
}

interface SearchFormState extends SearchFormSelections {
  expanded?: string;
}

function getCombinedFacetValue(facetValues: ResultFacetValue[], selectedIds: string[]): string {
  if (selectedIds.length === 0) {
    return '';
  }

  const firstValue = facetValues.find(v => v.id === selectedIds[0]);
  if (!firstValue) {
    // Should not happen.
    return '';
  }

  if (selectedIds.length === 1) {
    return firstValue.title;
  }

  return `${firstValue.title} + ${selectedIds.length - 1}`;
}

export default class SearchForm extends Component<SearchFormProps, SearchFormState> {
  state: SearchFormState = {
    departures: [],
    numberOfPersons: undefined,
    countries: [],
    expanded: undefined,
  };

  handleInputClick = (e: SyntheticEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget;

    e.currentTarget.blur();

    this.setState(s => ({
      expanded: s.expanded === id ? undefined : id,
    }));
  };

  handleFacetValueClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    const { facet, value } = e.currentTarget.dataset;

    if (!value) {
      return;
    }

    this.setState(s => ({
      departures: facet === 'departure' ? flipValue(s.departures, value) : s.departures,
      countries: facet === 'country' ? flipValue(s.countries, value) : s.countries,
    }));
  };

  handleNumberOfPersonsChange = (e: SyntheticEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;

    this.setState(s => ({
      numberOfPersons: value === '' ? undefined : value,
    }));
  };

  handleSubmitClick = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    this.props.onSubmit({
      countries: [...this.state.countries],
      departures: [...this.state.departures],
      numberOfPersons: this.state.numberOfPersons,
    });
  };

  handleNumberOfPersonsFocus = (e: SyntheticEvent<HTMLSelectElement>) => {
    // Close all popups when the number of persons select is highlighted.
    this.setState(() => ({
      expanded: undefined,
    }));
  };

  render() {
    const {
      personsFacet,
      countryFacet,
      departureFacet,
      url,
      departureFacetTitle,
      personsFacetTitle,
      countryFacetTitle,
    } = this.props;
    const { departures, numberOfPersons, countries, expanded } = this.state;

    if (!departureFacet || !countryFacet || !personsFacet) {
      return null;
    }

    const departureValue = getCombinedFacetValue(departureFacet.values, departures);
    const countryValue = getCombinedFacetValue(countryFacet.values, countries);

    return (
      <div className={cx('searchform', expanded && 'searchform--slideactive')}>
        <div className="searchform__row">
          <div>
            <input
              type="text"
              id="country"
              value={countryValue}
              placeholder={countryFacetTitle}
              onFocus={this.handleInputClick}
              autoComplete="off"
              readOnly={true}
            />
          </div>
          <div>
            <input
              type="text"
              name="date"
              id="departure"
              value={departureValue}
              placeholder={departureFacetTitle}
              onClick={this.handleInputClick}
              autoComplete="off"
              readOnly={true}
            />
          </div>
          <div>
            <div className="select-style">
              <select
                id="persons"
                value={numberOfPersons + ''}
                onChange={this.handleNumberOfPersonsChange}
                onFocus={this.handleNumberOfPersonsFocus}
              >
                <option value="">{personsFacetTitle}</option>
                {personsFacet.values.map(facet => (
                  <option key={facet.id} value={facet.id}>
                    {facet.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <a href={url} className="searchform__button" onClick={this.handleSubmitClick}>
              Zoek
            </a>
          </div>
        </div>
        <div
          className={cx(
            'searchform__row',
            'searchform__row--slide',
            expanded === 'departure' ? 'searchform__row--slideactive' : 'searchform__row--slidehidden'
          )}
        >
          {departureFacet.values.map(facet => (
            <button
              key={facet.id}
              className={cx('searchform__slideitem', departures.includes(facet.id) && 'searchform__slideitem--active')}
              data-facet="departure"
              data-value={facet.id}
              onClick={this.handleFacetValueClick}
            >
              {facet.title}
            </button>
          ))}
        </div>
        <div
          className={cx(
            'searchform__row',
            'searchform__row--slide',
            expanded === 'country' ? 'searchform__row--slideactive' : 'searchform__row--slidehidden'
          )}
        >
          {countryFacet.values.map(facet => (
            <button
              key={facet.id}
              className={cx('searchform__slideitem', countries.includes(facet.id) && 'searchform__slideitem--active')}
              data-facet="country"
              data-value={facet.id}
              onClick={this.handleFacetValueClick}
            >
              {facet.title}
            </button>
          ))}
        </div>
      </div>
    );
  }
}
