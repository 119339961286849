import { Component, createRef, RefObject } from 'react';
import Slider from 'react-slick';
import cx from 'classnames';
import { CarouselViewModel } from '../../models/CarouselViewModel';
import Arrow from '../atoms/slider-arrow/Arrow';
import LazyLoad from 'react-lazy-load';
import ImageHtml from '../atoms/imageHtml/ImageHtml';

export interface CarouselState {
  initialized: boolean;
  activeSlide: number;
  width?: number;
}

// Images are 600x450.
const baseImageHeight = 450;
const baseImageWidth = 600;

class Carousel extends Component<CarouselViewModel, CarouselState> {
  state: CarouselState = {
    initialized: false,
    activeSlide: 0,
  };

  private readonly carouselRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  private readonly mainSliderRef: RefObject<Slider> = createRef<Slider>();

  componentDidMount() {
    this.setState({
      initialized: true,
      width: this.carouselRef.current ? this.carouselRef.current.offsetWidth : undefined,
    });

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleThumbSlideClick(index: number) {
    if (this.mainSliderRef.current) {
      this.mainSliderRef.current.slickGoTo(index);
    }
  }

  handleResize = () => {
    this.setState({
      width: this.carouselRef.current ? this.carouselRef.current.offsetWidth : undefined,
    });
  };

  render() {
    const { relatedImages, relatedThumbnails, isInEditMode, editModeText } = this.props;
    const { width, initialized } = this.state;
    const moreThanFour = relatedImages && relatedImages.length > 4;

    const mainSliderSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (currentSlide, nextSlide: number) => {
        this.setState({
          activeSlide: nextSlide,
        });
      },
    };

    const thumbSliderSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <Arrow />,
      prevArrow: <Arrow />,
      asNavFor: this.mainSliderRef.current || undefined,
      swipeToSlide: true,
      variableWidth: true,
      infinite: moreThanFour,
      arrows: moreThanFour,
    };

    if (!relatedImages || relatedImages.length === 0) {
      return !isInEditMode ? (
        <div />
      ) : (
        <div className="edit-mode-text">
          <h1>{editModeText}</h1>
        </div>
      );
    }

    const imageHeight = width && width < baseImageWidth ? (baseImageHeight * width) / baseImageWidth : baseImageHeight;

    return isInEditMode ? (
      <div className="carousel-edit-mode">
        {relatedImages.map((relatedImage, key) => (
          <ImageHtml key={key} html={relatedImage.html} />
        ))}
      </div>
    ) : (
      <div className="carousel" ref={this.carouselRef}>
        {initialized && (
          <>
            <div className="carousel__main">
              <Slider {...mainSliderSettings} ref={this.mainSliderRef}>
                {relatedImages.map((relatedImage, index) => (
                  <LazyLoad key={index} height={imageHeight} offset={300}>
                    <ImageHtml class="carousel__image" html={relatedImage.html} />
                  </LazyLoad>
                ))}
              </Slider>
            </div>
            {relatedImages.length > 1 && (
              <div className={cx('carousel__thumbs', !moreThanFour && 'carousel__thumbs--center')}>
                <Slider {...thumbSliderSettings}>
                  {(relatedThumbnails || relatedImages).map((relatedImage, index) => (
                    <div key={index} onClick={() => this.handleThumbSlideClick(index)}>
                      <LazyLoad height={68} offset={300}>
                        <ImageHtml
                          html={relatedImage.html}
                          class={(this.state.activeSlide === index && 'thumb-active') || ''}
                        />
                      </LazyLoad>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default Carousel;
