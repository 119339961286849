import { FunctionComponent, PropsWithChildren } from 'react';
import { Placeholder } from '../Placeholder';
import { BaseLayout } from './BaseLayout';
import { BaseStructureProps } from './BaseStructure';

const SearchResultsBaseStructure: FunctionComponent<PropsWithChildren<BaseStructureProps>> = ({
  placeholders,
  children,
  isInEditMode,
}) => (
  <BaseLayout isInEditMode={isInEditMode}>
    <header className={`header${isInEditMode ? ' header-edit-mode' : ''}`}>
      <Placeholder children={children} placeholders={placeholders} placeholderIndex={0} />
    </header>
    <main>
      <Placeholder children={children} placeholders={placeholders} placeholderIndex={1} />
    </main>
    <footer>
      <Placeholder children={children} placeholders={placeholders} placeholderIndex={2} />
    </footer>
  </BaseLayout>
);

export default SearchResultsBaseStructure;
