import { FunctionComponent, PropsWithChildren } from 'react';
import { Placeholder } from '../structure/Placeholder';
import { RegionBlockRowContainerViewModel } from '../../models/RegionBlockRowContainerViewModel';

const RegionBlockRowContainer: FunctionComponent<PropsWithChildren<RegionBlockRowContainerViewModel>> = ({
  placeholders,
  children,
}) => (
  <div className="regionblock-row-container">
    <Placeholder children={children} placeholders={placeholders} />
  </div>
);

export default RegionBlockRowContainer;
