import { FunctionComponent, PropsWithChildren } from 'react';
import cx from 'classnames';

interface TooltipProps {
  className?: string;
  right?: true;
}

const Tooltip: FunctionComponent<PropsWithChildren<TooltipProps>> = ({ children, className, right }) => (
  <div className={cx('tooltip__tooltip', right && 'tooltip__tooltip--right', className)}>{children}</div>
);

export default Tooltip;
