import { FunctionComponent } from 'react';
import { ContactItem } from '../../models/ContactItem';
import Link from '../atoms/link/Link';

interface HeaderContactItemProps {
  item: ContactItem;
}

const HeaderContactItem: FunctionComponent<HeaderContactItemProps> = ({ item }) => {
  return <Link {...item.componentLink} class={item.iconClass} data-tip={true} />;
};
export default HeaderContactItem;
