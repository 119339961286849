/* global google */
import { MapMarker } from '../models/MapMarker';

const getMapBounds = (mapMarkers: MapMarker[]) => {
  const bounds = new google.maps.LatLngBounds();

  mapMarkers.forEach(mapMarker => {
    bounds.extend(new google.maps.LatLng(mapMarker.latitude, mapMarker.longitude));
  });

  return bounds;
};

export const centerMap = (map: google.maps.Map, mapMarkers: MapMarker[], padding?: google.maps.Padding) => {
  if (map) {
    const bounds: google.maps.LatLngBounds = getMapBounds(mapMarkers);
    map.fitBounds(bounds, padding);
  }
};
