import { Component } from 'react';
import cx from 'classnames';
import { HeaderViewModel } from '../../models/HeaderViewModel';
import Link from '../atoms/link/Link';
import DropdownMenuItem from '../menuItem/DropdownMenuItem';
import { BreakPoints } from '../../constants/breakPoints';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import Stars from '../atoms/stars/Stars';
import SearchBox from '../atoms/form/SearchBox';
import HeaderContactItem from './HeaderContactItem';
import HeaderUserIcon from './HeaderUserIcon';
import Tooltip from '../tooltip/Tooltip';
import { asEditable } from '../../helpers/sitecore';
import Button from '../atoms/button/Button';
import { getIsOpen, saveClosed } from './utils';
import { ContactItem } from '../../models/ContactItem';
const withSizes = require('react-sizes');

interface MobileMenuProps extends HeaderViewModel {
  isScreenSm: boolean;
}

interface MobileMenuState {
  mobileMenuOpen: boolean;
  small: boolean;
  tooltipOpen: boolean;
  tooltipItem: ContactItem | undefined;
}

class Header extends Component<MobileMenuProps, MobileMenuState> {
  static getDerivedStateFromProps(nextProps: MobileMenuProps, prevState: MobileMenuState) {
    if (!nextProps.isScreenSm && prevState.mobileMenuOpen) {
      return { mobileMenuOpen: false };
    }
    return null;
  }

  constructor(props: MobileMenuProps) {
    super(props);

    const { contactItems } = props;
    const tooltipItem = contactItems && contactItems.find(ci => !!ci.tooltipText);

    this.state = {
      mobileMenuOpen: false,
      small: false,
      tooltipItem: tooltipItem,
      tooltipOpen: !tooltipItem?.tooltipTextHash || getIsOpen(tooltipItem.tooltipTextHash),
    };
  }

  toggleMobileMenu = () => {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen }, () => {
      const body = document.querySelector('body');
      if (!body) {
        return;
      }

      if (this.state.mobileMenuOpen) {
        body.classList.add('no-scrolling');
      } else {
        body.classList.remove('no-scrolling');
      }
    });
  };

  componentDidUpdate(prevProps: Readonly<MobileMenuProps>, prevState: Readonly<MobileMenuState>, snapshot?: any): void {
    if (!this.props.isScreenSm && prevProps.isScreenSm) {
      const body = document.querySelector('body');
      if (body && body.classList.contains('no-scrolling')) {
        body.classList.remove('no-scrolling');
      }
    }
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (!this.props.isScreenSm) {
      return;
    }

    this.setState({
      small: window.scrollY > 100,
    });
  };

  handleCloseClick = () => {
    this.setState(s => ({
      ...s,
      tooltipOpen: false,
    }));

    const { tooltipItem } = this.state;
    if (tooltipItem?.tooltipTextHash) {
      saveClosed(tooltipItem.tooltipTextHash);
    }
  };

  render() {
    const {
      logoLink,
      logoImage,
      title,
      contactItems,
      logoRight,
      menuItems,
      mobileMenuDropdownImage,
      rating,
      versionInfo,
      isInEditMode,
      isInPreviewMode,
      textSearchField,
      userIcon,
      userLink,
      ratingLink,
    } = this.props;
    const { mobileMenuOpen, small, tooltipOpen, tooltipItem } = this.state;

    const fixed = !isInEditMode && !isInPreviewMode;

    return (
      <div className={cx('header-overlay', fixed && 'header-overlay--fixed', small && 'header-overlay--small')}>
        <input
          type="checkbox"
          id="nav-trigger"
          className="nav-trigger"
          onChange={this.toggleMobileMenu}
          checked={mobileMenuOpen}
        />
        <label
          htmlFor="nav-trigger"
          className={cx('nav-trigger-label', isInEditMode && 'nav-trigger-label--edit-mode')}
          style={{
            backgroundImage: mobileMenuDropdownImage ? `url(${mobileMenuDropdownImage.src})` : undefined,
          }}
        />
        <div className="header-main">
          <a href={logoLink && logoLink.url} className="header-main-logo-container">
            {logoImage && <ImageHtml class="header-main-logo" {...logoImage} />}
            {title && <div className="header-main-logo-info">{title}</div>}
          </a>
          <div className={cx('navright', contactItems?.length === 1 && !rating && 'navright--low')}>
            <nav className="navcontact">
              {contactItems &&
                contactItems.length > 0 &&
                contactItems.map(c => <HeaderContactItem key={c.id} item={c} />)}
            </nav>
            {logoRight && <ImageHtml {...logoRight} class="logo-anwb" />}

            {rating && rating.starRatingScore && rating.starRatingScore > 0 ? (
              <>
                <div
                  className={cx('topleft', ratingLink && 'topleft--clickable')}
                  onClick={
                    (ratingLink && ratingLink.url && (() => (window.location.href = ratingLink.url!))) || undefined
                  }
                >
                  <Stars count={rating.starRatingScore} color="_tertiary" />
                  <span className="topleft-reviews">{rating.ratingText}</span>
                </div>
                <div className="topleft-rating">
                  {ratingLink && <Link {...ratingLink}>{rating.ratingScore}</Link>}
                  {!ratingLink && rating.ratingScore}
                </div>
              </>
            ) : (
              <div className="topleft__usericon">
                {userLink && userIcon && <HeaderUserIcon userIcon={userIcon} userLink={userLink} />}
              </div>
            )}
          </div>

          {tooltipOpen && tooltipItem && (
            <div className="navcontact__tooltipcontainer">
              <Tooltip right={true} className="navcontact__tooltip">
                <div dangerouslySetInnerHTML={asEditable(tooltipItem.tooltipText)} />
                <Button
                  onClick={this.handleCloseClick}
                  class="cta_button cta_button--tertiary cta_button--small"
                  text="Sluiten"
                />
              </Tooltip>
            </div>
          )}
        </div>
        <div className={cx('bottomnav', mobileMenuOpen && 'bottomnav--open')}>
          <nav className={cx('subnav', mobileMenuOpen && 'subnav--open', isInEditMode && 'subnav--edit-mode')}>
            {menuItems &&
              menuItems.map(
                menuItem => menuItem.navigationTitle && <DropdownMenuItem menuItem={menuItem} key={menuItem.id} />
              )}
          </nav>
          {textSearchField && (
            <div className="bottomnav__searchbar">
              <SearchBox
                placeholderText={textSearchField.placeholderText}
                id="headersearchmobile"
                searchPageUrl={textSearchField.searchPageUrl}
                collapsible={!mobileMenuOpen}
              />
            </div>
          )}
          {userLink && userIcon && (
            <div className="bottomnav__usericon">
              <HeaderUserIcon userIcon={userIcon} userLink={userLink} />
            </div>
          )}
        </div>
        {versionInfo && <div className="header__versioninfo">{versionInfo}</div>}
      </div>
    );
  }
}
const mapSizesToProps = ({ width }) => ({
  isScreenSm: width < BreakPoints.screenSm,
});

export default withSizes.withSizes(mapSizesToProps)(Header);
