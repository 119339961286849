import { Component } from 'react';
import { ComplaintFormViewModel } from '../../models/ComplaintFormViewModel';
import { Field, withTypes } from 'react-final-form';
import createDecorator from 'final-form-focus';
import ErrorMessage from '../atoms/message/ErrorMessage';
import SuccessMessage from '../atoms/message/SuccessMessage';
import HorizontalRadioBoxes from '../atoms/form/HorizontalRadioBoxes';
import TextField from '../atoms/form/TextField';
import SubmitButton from '../atoms/form/SubmitButton';
import Dropdown from '../atoms/form/Dropdown';
import CheckBox from '../atoms/form/CheckBox';
import TextArea from '../atoms/form/TextArea';
import UploadButton from '../atoms/form/UploadButton';
import DocumentsField from '../atoms/form/DocumentsField';
import Condition from '../atoms/form/atoms/Condition';
import { submitForm } from '../../helpers/form';
import { ComplaintFormInputModel } from '../../models/ComplaintFormInputModel';

const decorators = [createDecorator()];

const { Form } = withTypes<ComplaintFormInputModel>();

const filetypes = [
  'image/jpeg',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

class ComplaintForm extends Component<ComplaintFormViewModel> {
  handleSubmit = (initialValues: ComplaintFormInputModel) => submitForm(this.props, initialValues);

  render() {
    const { componentTitle, componentSubTitle, fieldNames, genders, countries, successMessage, fileSelectedText } =
      this.props;

    const initialValues: ComplaintFormInputModel = {
      countryCode: Object.keys(countries)[0],
      city: '',
      complaintText: '',
      email: '',
      emailConfirm: '',
      gender: '',
      houseNumber: '',
      phoneNumber: '',
      postalCode: '',
      reservationNumber: '',
      streetName: '',
      surname: '',
      bankAccountNumber: '',
      bankAccountName: '',
    };
    const requiredField = value => (value ? undefined : this.props.clientSideFieldValidationMessage);

    return (
      <>
        {componentTitle && <h2>{componentTitle}</h2>}
        <Form
          initialValues={initialValues}
          onSubmit={this.handleSubmit(initialValues)}
          subscription={{
            pristine: true,
            invalid: true,
            submitFailed: true,
            submitError: true,
            submitSucceeded: true,
            submitting: true,
          }}
          decorators={decorators}
          render={({ handleSubmit, pristine, invalid, submitFailed, submitError, submitSucceeded, submitting }) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true}>
                {!submitting && invalid && submitFailed && <ErrorMessage>{this.props.submitErrorMessage}</ErrorMessage>}
                {!submitting && submitSucceeded && pristine && (
                  <SuccessMessage scrollIntoView={true}>
                    <div dangerouslySetInnerHTML={{ __html: successMessage }} />
                  </SuccessMessage>
                )}
                {!submitting && submitError && <ErrorMessage scrollIntoView={true}>{submitError}</ErrorMessage>}

                <Field
                  validate={requiredField}
                  component={TextArea}
                  name="complaintText"
                  label={fieldNames.complaintText}
                  required={true}
                />
                <Field
                  component={CheckBox}
                  name="hasReported"
                  type="checkbox"
                  label={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: fieldNames.hasReported,
                      }}
                    />
                  }
                />

                <Condition when="hasReported" is={true}>
                  <Field component={TextArea} name="reportedText" label={fieldNames.reportedText} />
                </Condition>

                <Field component={DocumentsField} name="documents" label={fieldNames.documents}>
                  <Field
                    component={UploadButton}
                    name="firstFile"
                    label={fieldNames.firstFile}
                    selectedText={fileSelectedText}
                    fileTypes={filetypes}
                  />
                  <Field
                    component={UploadButton}
                    name="secondFile"
                    label={fieldNames.secondFile}
                    selectedText={fileSelectedText}
                    fileTypes={filetypes}
                  />
                  <Field
                    component={UploadButton}
                    name="thirdFile"
                    label={fieldNames.thirdFile}
                    selectedText={fileSelectedText}
                    fileTypes={filetypes}
                  />
                </Field>
                <Field
                  validate={requiredField}
                  component={TextField}
                  name="reservationNumber"
                  label={fieldNames.reservationNumber}
                  required={true}
                />

                {componentSubTitle && <h2>{componentSubTitle}</h2>}

                <Field
                  validate={requiredField}
                  component={HorizontalRadioBoxes}
                  name="gender"
                  label={fieldNames.gender}
                  options={genders}
                  required={true}
                />
                <Field component={TextField} name="givenName" label={fieldNames.givenName} />
                <Field component={TextField} name="surnamePrefix" label={fieldNames.surnamePrefix} />
                <Field
                  validate={requiredField}
                  component={TextField}
                  name="surname"
                  label={fieldNames.surname}
                  required={true}
                />
                <Field
                  validate={requiredField}
                  component={TextField}
                  name="postalCode"
                  label={fieldNames.postalCode}
                  required={true}
                />
                <Field
                  validate={requiredField}
                  component={TextField}
                  name="houseNumber"
                  label={fieldNames.houseNumber}
                  required={true}
                />
                <Field component={TextField} name="houseNumberSuffix" label={fieldNames.houseNumberSuffix} />
                <Field
                  validate={requiredField}
                  component={TextField}
                  name="streetName"
                  label={fieldNames.streetName}
                  required={true}
                />
                <Field
                  validate={requiredField}
                  component={TextField}
                  name="city"
                  label={fieldNames.city}
                  required={true}
                />
                <Field
                  validate={requiredField}
                  component={Dropdown}
                  name="countryCode"
                  label={fieldNames.countryCode}
                  options={countries}
                  required={true}
                />
                <Field
                  validate={requiredField}
                  component={TextField}
                  name="phoneNumber"
                  label={fieldNames.phoneNumber}
                  required={true}
                />
                <Field
                  validate={requiredField}
                  component={TextField}
                  name="email"
                  type="email"
                  label={fieldNames.email}
                  required={true}
                />
                <Field
                  validate={requiredField}
                  component={TextField}
                  name="emailConfirm"
                  type="email"
                  label={fieldNames.emailConfirm}
                  required={true}
                />
                <Field
                  validate={requiredField}
                  component={TextField}
                  name="bankAccountNumber"
                  type="text"
                  label={fieldNames.bankAccountNumber}
                  required={true}
                />
                <Field
                  validate={requiredField}
                  component={TextField}
                  name="bankAccountName"
                  type="text"
                  label={fieldNames.bankAccountName}
                  required={true}
                />

                <SubmitButton disabled={pristine || invalid || submitting} />
              </form>
            );
          }}
        />
      </>
    );
  }
}

export default ComplaintForm;
