import { FunctionComponent } from 'react';
import { IntroTextViewModel } from '../../models/IntroTextViewModel';
import IntroText from './IntroText';

const IntroTextFullWidth: FunctionComponent<IntroTextViewModel> = (props: IntroTextViewModel) => (
  <div className="boxed">
    <IntroText {...props} />
  </div>
);

export default IntroTextFullWidth;
