import { FunctionComponent } from 'react';
import { Placeholder } from '../Placeholder';
import { ReactPlaceholder } from '../../../models/ReactPlaceholder';

export interface BaseLayoutProps {
  placeholders?: ReactPlaceholder[];
  children?: any;
  isInEditMode?: boolean;
}

export const BaseLayout: FunctionComponent<BaseLayoutProps> = ({ placeholders, children, isInEditMode }) => (
  <div className="site-wrap">
    <div className={`container${isInEditMode ? ' container--edit-mode' : ''}`}>
      <Placeholder children={children} placeholders={placeholders} />
    </div>
  </div>
);

export default BaseLayout;
