import { FunctionComponent, PropsWithChildren } from 'react';
import { FieldRenderProps } from 'react-final-form';
import FormItem from './atoms/FormItem';
import ValidationError from './atoms/ValidationError';

export interface DocumentsFieldProps extends FieldRenderProps<string> {
  label?: string;
  className?: string;
  type?: string;
  required?: boolean;
}

const DocumentsField: FunctionComponent<PropsWithChildren<DocumentsFieldProps>> = ({
  input,
  meta,
  children,
  ...rest
}) => (
  <FormItem meta={meta} {...rest}>
    {rest.label && <label htmlFor={input.name}>{rest.label}:</label>}
    <div className="form-item-value">{children}</div>
    <ValidationError meta={meta} />
  </FormItem>
);

export default DocumentsField;
