import { FunctionComponent } from 'react';
import cx from 'classnames';
import { FooterColumn as IFooterColumn } from '../../models/FooterColumn';
import { FooterList as IFooterList } from '../../models/FooterList';
import FooterList from './FooterList';

const FooterColumn: FunctionComponent<IFooterColumn> = ({ footerLists, isHighlighted, highlightColor }) => (
  <div className={cx('footercol', isHighlighted && highlightColor && `footercol--highlight_color__${highlightColor}`)}>
    {footerLists &&
      footerLists.length > 0 &&
      footerLists.map((footerList: IFooterList) => <FooterList {...footerList} key={footerList.id} />)}
  </div>
);

export default FooterColumn;
