import { FunctionComponent } from 'react';
import Link from '../atoms/link/Link';
import { RichContentBlockViewModel } from '../../models/RichContentBlockViewModel';
import Text from '../atoms/text/Text';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import ReadMore from '../atoms/text/ReadMore';

const RichContentBlock: FunctionComponent<RichContentBlockViewModel> = props => {
  const {
    id,
    componentTitle,
    componentShortText,
    componentImage,
    componentImageSize,
    componentText,
    showButton,
    buttonLessText,
    buttonMoreText,
    buttonText,
    componentLink,
    colorCombination,
    textAlignment,
    isInEditMode,
  } = props;

  const imageSize = componentImageSize ? componentImageSize : 'small';
  const textAlignmentClass = textAlignment ? textAlignment : 'left';
  const elementId = id ? id.replace(/-/g, '') : 'readmore';

  return (
    <div
      className={`rich-content-block margin-bottom rich-content-block--image-size-${imageSize} ${
        colorCombination ? `rich-content-block--theme-${colorCombination}` : ''
      } rich-content-block--alignment-${textAlignmentClass}`}
    >
      {componentTitle && <Text {...componentTitle} className="heading" />}
      <div className="content-block-container">
        <div className={`rich-content-block__col1${!componentImage ? '--full-width' : ''}`}>
          {componentShortText && (
            <div
              dangerouslySetInnerHTML={{
                __html: componentShortText ? componentShortText : '',
              }}
            />
          )}
          <ReadMore
            uniqueId={elementId}
            text={componentText}
            buttonLessText={buttonLessText || ''}
            buttonMoreText={buttonMoreText || ''}
            editMode={isInEditMode === true}
          />
          {componentLink && showButton && buttonText && (
            <>
              <Link {...componentLink} text={buttonText} class="cta_button" />
              <span className="clear" />
            </>
          )}
        </div>

        {componentImage && (
          <div className="rich-content-block__col2">
            <ImageHtml class="rich-content-block__image" {...componentImage} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RichContentBlock;
