import { FunctionComponent } from 'react';
import StickyBox from 'react-sticky-box';
import { SideMenuViewModel } from '../../models/SideMenuViewModel';
import MenuItem from '../menuItem/MenuItem';
import Text from '../atoms/text/Text';

const SideMenu: FunctionComponent<SideMenuViewModel> = ({ title, items }: SideMenuViewModel) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <StickyBox className="custom_cta sidenav" offsetTop={140}>
      <Text {...title} className="heading" />
      {items.map(item => (
        <MenuItem key={item.url} {...item} />
      ))}
    </StickyBox>
  );
};

export default SideMenu;
