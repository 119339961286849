import { FunctionComponent } from 'react';
import { Field, withTypes } from 'react-final-form';
import createDecorator from 'final-form-focus';
import TextField from '../atoms/form/TextField';
import SubmitButton from '../atoms/form/SubmitButton';
import ErrorMessage from '../atoms/message/ErrorMessage';
import { submitForm } from '../../helpers/form';
import { CustomerLoginViewModel } from '../../models/CustomerLoginViewModel';
import { CustomerLoginInputModel } from '../../models/CustomerLoginInputModel';

const decorators = [createDecorator()];

const { Form } = withTypes<CustomerLoginInputModel>();

const initialValues: CustomerLoginInputModel = {
  reservationNumber: '',
  email: '',
  postalCode: '',
};

const CustomerLogin: FunctionComponent<CustomerLoginViewModel> = (props: CustomerLoginViewModel) => {
  const initial = props.initialData || initialValues;

  return (
    <Form
      initialValues={initial}
      onSubmit={submitForm<CustomerLoginInputModel>(props, initial)}
      decorators={decorators}
      subscription={{
        pristine: true,
        invalid: true,
        submitFailed: true,
        submitError: true,
        submitErrors: true,
        submitSucceeded: true,
        submitting: true,
      }}
      render={({ handleSubmit, pristine, invalid, submitFailed, submitError, submitting }) => {
        return (
          <form onSubmit={handleSubmit} className="customerlogin" noValidate={true}>
            {!submitting && invalid && submitFailed && <ErrorMessage>{props.submitErrorMessage}</ErrorMessage>}
            {!submitting && submitError && <ErrorMessage scrollIntoView={true}>{submitError}</ErrorMessage>}

            <Field
              component={TextField}
              type="text"
              name="reservationNumber"
              autoFocus={!initial.reservationNumber}
              label={props.fieldNames.reservationNumber}
              required={true}
            />
            <Field
              component={TextField}
              type="email"
              name="email"
              autoFocus={!!initial.reservationNumber && !initial.email}
              label={props.fieldNames.email}
              required={true}
            />
            <Field
              component={TextField}
              type="text"
              name="postalCode"
              autoFocus={!!(initial.reservationNumber && initial.email)}
              label={props.fieldNames.postalCode}
              required={true}
            />
            <SubmitButton disabled={pristine || invalid} buttonText={props.buttonText} />
          </form>
        );
      }}
    />
  );
};

export default CustomerLogin;
