import { Component } from 'react';
import { Field, withTypes } from 'react-final-form';
import createDecorator from 'final-form-focus';
import TextField from '../atoms/form/TextField';
import SubmitButton from '../atoms/form/SubmitButton';
import ErrorMessage from '../atoms/message/ErrorMessage';
import { submitForm } from '../../helpers/form';
import { BlogEditViewModel } from '../../models/BlogEditViewModel';
import BlogHeading from './BlogHeading';
import { BlogLoginInputModel } from '../../models/BlogLoginInputModel';

const decorators = [createDecorator()];

const { Form } = withTypes<BlogLoginInputModel>();

class BlogLogIn extends Component<BlogEditViewModel> {
  handleSubmit = (initialValues: BlogLoginInputModel) => submitForm(this.props, initialValues);

  render() {
    const { fieldNames, buttonText, blogHeadingViewModel } = this.props;

    const initialValues: BlogLoginInputModel = {
      password: '',
    };

    return (
      <div className="edit-blog-form">
        <BlogHeading {...blogHeadingViewModel} />
        {fieldNames && fieldNames.password && (
          <Form
            initialValues={initialValues}
            onSubmit={this.handleSubmit(initialValues)}
            decorators={decorators}
            subscription={{
              pristine: true,
              invalid: true,
              submitFailed: true,
              submitError: true,
              submitErrors: true,
              submitSucceeded: true,
              submitting: true,
            }}
            render={({ handleSubmit, pristine, invalid, submitFailed, submitError, submitting }) => {
              return (
                <form onSubmit={handleSubmit} noValidate={true}>
                  {!submitting && invalid && submitFailed && (
                    <ErrorMessage>{this.props.submitErrorMessage}</ErrorMessage>
                  )}
                  {!submitting && submitError && <ErrorMessage scrollIntoView={true}>{submitError}</ErrorMessage>}

                  <div className="blog-loginform">
                    <Field
                      component={TextField}
                      type="password"
                      name="password"
                      autoFocus={true}
                      label={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: fieldNames.password,
                          }}
                        />
                      }
                      required={true}
                    />
                  </div>
                  <SubmitButton disabled={pristine || invalid} buttonText={buttonText} />
                </form>
              );
            }}
          />
        )}
      </div>
    );
  }
}

export default BlogLogIn;
