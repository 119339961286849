import { FunctionComponent } from 'react';
import { SpotlightHeaderViewModel } from '../../models/SpotlightHeaderViewModel';
import SpotlightHeader from './SpotlightHeader';
import TripWidgetTop from './widgets/TripWidgetTop';

const SpotlightHeaderForm: FunctionComponent<SpotlightHeaderViewModel> = props => (
  <SpotlightHeader {...props} className="form">
    <TripWidgetTop {...props} />
  </SpotlightHeader>
);

export default SpotlightHeaderForm;
