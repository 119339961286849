import { FunctionComponent } from 'react';
import { ContentBlockViewModel } from '../../models/ContentBlockViewModel';
import Text from '../atoms/text/Text';

const ContentBlock: FunctionComponent<ContentBlockViewModel> = ({ componentTitle, componentText }) => (
  <section>
    {componentTitle && <Text {...componentTitle} className="heading" />}
    <div className="margin-bottom" dangerouslySetInnerHTML={{ __html: componentText ? componentText : '' }} />
  </section>
);

export default ContentBlock;
