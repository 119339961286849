import { ChangeEvent, Component, SyntheticEvent } from 'react';
import cx from 'classnames';
import qs from 'qs';
import sortBy from 'lodash.sortby';
import { parseISO } from 'date-fns';
import Link from '../atoms/link/Link';
import { PriceBlockViewModel } from '../../models/PriceBlockViewModel';
import { PriceBlockItemViewModel } from '../../models/PriceBlockItemViewModel';
import { asEditable } from '../../helpers/sitecore';

type TableList = {
  description: string;
  prices: (PriceBlockItemViewModel | null)[];
};

type Day = {
  startDate: Date;
  startDateText: string;
  startDateYear: string;
};

type YearMonthOption = {
  text: string;
  date: Date;
};

interface PriceBlockState {
  activePersonOption: string;
  activeMonthYearOption?: YearMonthOption;
  activeDays: Day[];
  activeDayStartFrom: number;
  personOptions: string[];
  monthYearOptions: YearMonthOption[];
  availableDays: Day[];
  availableDescriptions: string[];
}

interface QueryStringParameters {
  persons?: string;
  startDate?: string;
}

interface SessionStorageState {
  activeMonthYearOption?: string;
  activePersonOption?: string;
}

// Filters from the resultpage are in a different format
const personFilter = {
  '2': '2 personen',
  '3': '3 personen',
  '4': '4 personen',
  '5': '5 personen',
  '6': '6 personen',
};

const amountOfDaysVisible = 3;

function isActiveDate(activeFilterDate: Date, optionDate: Date, checkDay?: boolean): boolean {
  if (activeFilterDate.getFullYear() !== optionDate.getFullYear()) {
    return false;
  }

  if (optionDate.getMonth() !== activeFilterDate.getMonth()) {
    return false;
  }

  return !checkDay || optionDate.getDate() === activeFilterDate.getDate();
}

function findMonth(monthYearOptions: YearMonthOption[], day: Day) {
  const year = day.startDate.getFullYear();
  const month = day.startDate.getMonth();
  return monthYearOptions.find(o => o.date.getFullYear() === year && o.date.getMonth() === month);
}

function removeQueryString() {
  if (document.location.search) {
    window.history.replaceState(null, '', document.location.pathname);
  }
}

export default class PriceBlock extends Component<PriceBlockViewModel, PriceBlockState> {
  constructor(props: PriceBlockViewModel) {
    super(props);

    this.state = {
      activePersonOption: '',
      activeDays: [],
      activeDayStartFrom: 0,
      personOptions: [],
      monthYearOptions: [],
      availableDays: [],
      availableDescriptions: [],
    };

    this.handlePersonSelect = this.handlePersonSelect.bind(this);
    this.handleMonthYearSelect = this.handleMonthYearSelect.bind(this);
    this.handleIncreaseActiveDays = this.handleIncreaseActiveDays.bind(this);
    this.handleDecreaseActiveDays = this.handleDecreaseActiveDays.bind(this);
  }

  filterProducts(activeDays: Day[], activePersonOption: string): TableList[] {
    const { prices } = this.props;
    const { availableDescriptions } = this.state;

    return availableDescriptions.map((description: string) => {
      return {
        description,
        prices: activeDays.map(activeDay => {
          const match = prices.find((price: PriceBlockItemViewModel) => {
            const year = price.startDate.substr(0, 4);
            return (
              price.description === description &&
              price.startDateText === activeDay.startDateText &&
              year === activeDay.startDateYear &&
              (!activePersonOption || price.numberOfPersons === activePersonOption)
            );
          });

          if (!match) {
            return null;
          }

          return match;
        }),
      };
    });
  }

  // correct the activeDayIndex, in order to equalize visibleDays to amountOfDaysVisible
  getCorrectActiveDayIndex(activeDayIndex: number, availableDays?: Day[]): number {
    const days = availableDays || this.state.availableDays;

    if (activeDayIndex <= 0) {
      return 0;
    }

    const visibleDays = days.length - activeDayIndex;
    activeDayIndex =
      visibleDays < amountOfDaysVisible ? activeDayIndex - (amountOfDaysVisible - visibleDays) : activeDayIndex;

    return activeDayIndex < 0 ? 0 : activeDayIndex;
  }

  componentDidMount() {
    this.setDefaultState();
  }

  setDefaultState() {
    // Build all filter lists.
    const { prices, id } = this.props;

    const personOptions: string[] = [];
    let monthYearOptions: YearMonthOption[] = [];
    let availableDays: Day[] = [];
    const availableDescriptions: string[] = [];

    prices.forEach((price: PriceBlockItemViewModel) => {
      // Price.
      if (price.numberOfPersons && personOptions.indexOf(price.numberOfPersons) === -1) {
        personOptions.push(price.numberOfPersons);
      }

      // Descriptions.
      if (availableDescriptions.indexOf(price.description) === -1) {
        availableDescriptions.push(price.description);
      }

      // Days.
      const startDate = parseISO(price.startDate);
      const startDateYear = startDate.getFullYear() + '';

      if (!monthYearOptions.find(monthYearOption => monthYearOption.text === (price.monthYear as string))) {
        monthYearOptions.push({
          date: startDate,
          text: price.monthYear,
        } as YearMonthOption);
      }

      // don't add duplicate year & day combinations
      if (
        !availableDays.find(
          availableDay =>
            availableDay.startDateYear === startDateYear && availableDay.startDateText === price.startDateText
        )
      ) {
        availableDays.push({
          startDateText: price.startDateText,
          startDateYear,
          startDate,
        } as Day);
      }
    });

    personOptions.sort();
    availableDescriptions.sort();
    availableDays = sortBy(availableDays, 'startDate');
    monthYearOptions = sortBy(monthYearOptions, 'date');

    let sessionStorageData: SessionStorageState | null = null;
    try {
      const sessionStorageItem = window.sessionStorage.getItem(`priceBlock-${id}`);
      if (sessionStorageItem) {
        sessionStorageData = JSON.parse(sessionStorageItem);
      }
    } catch (e) {
      // A security error may occur here when used in an iframe (lidl) and third party cookies are not allowed.
    }

    // Build filter values.
    let activeFilters: QueryStringParameters | null = null;
    if (typeof window !== 'undefined' && qs) {
      activeFilters = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
    }

    let activePersonOption;
    let activeMonthYearIndex = 0;
    let activeDayIndex = 0;

    // Get the active person value from the querystring
    if (activeFilters && activeFilters.persons) {
      // set person filter read from the querystring
      activePersonOption = personFilter[activeFilters.persons];
    } else if (personOptions.length > 0) {
      activePersonOption = personOptions[0];
    }

    // Get the active month/year combination from the querystring
    if (activeFilters && activeFilters.startDate) {
      const activeFilterDate = parseISO(activeFilters.startDate);
      // Legacy urls have only YYYY-MM.
      const activeFilterDateIsMonth = activeFilters.startDate.length === 7;

      activeMonthYearIndex = monthYearOptions.findIndex(monthYearOption => {
        return isActiveDate(activeFilterDate, monthYearOption.date);
      });

      activeDayIndex = availableDays.findIndex(availableDay => {
        return isActiveDate(activeFilterDate, availableDay.startDate, !activeFilterDateIsMonth);
      });

      // Otherwise check if this can be received from the sessionStorage
    } else if (sessionStorageData) {
      const state = sessionStorageData;

      if (state.constructor === Object && Object.keys(state).length > 0) {
        if (state.activeMonthYearOption) {
          const activeFilterDateFromSessionState = parseISO(state.activeMonthYearOption);

          activeMonthYearIndex = monthYearOptions.findIndex(monthYearOption => {
            return isActiveDate(activeFilterDateFromSessionState, monthYearOption.date);
          });

          activeDayIndex = availableDays.findIndex(dayDate => {
            return isActiveDate(activeFilterDateFromSessionState, dayDate.startDate, true);
          });
        }

        if (state.activePersonOption && personOptions.indexOf(state.activePersonOption) !== -1) {
          activePersonOption = state.activePersonOption;
        }
      }
    }

    activeDayIndex = this.getCorrectActiveDayIndex(activeDayIndex, availableDays);

    const activeDays = availableDays.slice(activeDayIndex, amountOfDaysVisible + activeDayIndex);

    this.setState(
      {
        personOptions,
        availableDays,
        monthYearOptions,
        availableDescriptions,
        activeMonthYearOption: monthYearOptions[activeMonthYearIndex],
        activePersonOption,
        activeDays,
        activeDayStartFrom: activeDayIndex,
      },
      () => this.setStateInSessionStorage(true)
    );
  }

  handlePersonSelect(event: ChangeEvent<HTMLSelectElement>) {
    const select = event.target;

    this.setState(
      {
        activePersonOption: select.value,
      },
      this.setStateInSessionStorage
    );
  }

  handleMonthYearSelect(event: SyntheticEvent<HTMLSelectElement>) {
    const { monthYearOptions, availableDays } = this.state;

    const select = event.target as HTMLSelectElement;
    const monthYearOption = monthYearOptions[select.selectedIndex];

    if (!monthYearOption) {
      return;
    }

    let activeDayIndex = availableDays.findIndex((availableDay: Day) =>
      isActiveDate(availableDay.startDate, monthYearOption.date)
    );
    activeDayIndex = this.getCorrectActiveDayIndex(activeDayIndex);
    const activeDays = availableDays.slice(activeDayIndex, amountOfDaysVisible + activeDayIndex);

    this.setState(
      {
        activeMonthYearOption: monthYearOption,
        activeDayStartFrom: activeDayIndex,
        activeDays,
      },
      this.setStateInSessionStorage
    );
  }

  setStateInSessionStorage(isInitialize = false) {
    const { activeMonthYearOption, activePersonOption } = this.state;

    const state: SessionStorageState = {
      activeMonthYearOption: activeMonthYearOption ? activeMonthYearOption.date.toISOString() : undefined,
      activePersonOption,
    };

    try {
      window.sessionStorage.setItem(`priceBlock-${this.props.id}`, JSON.stringify(state));
    } catch (e) {
      // A security error may occur here when used in an iframe (lidl) and third party cookies are not allowed.
    }

    // Remove query string value to prevent confusion when going back to this page with the back button.
    if (!isInitialize) {
      removeQueryString();
    }
  }

  handleDecreaseActiveDays() {
    const { availableDays, activeDayStartFrom, monthYearOptions } = this.state;

    const newActiveDayStartFrom =
      activeDayStartFrom - amountOfDaysVisible > 0 ? activeDayStartFrom - amountOfDaysVisible : 0;
    const activeDays = availableDays.slice(newActiveDayStartFrom, amountOfDaysVisible + newActiveDayStartFrom);

    this.setState({
      activeDayStartFrom: newActiveDayStartFrom,
      activeDays: activeDays,
      activeMonthYearOption: findMonth(monthYearOptions, activeDays[0]),
    });
  }

  handleIncreaseActiveDays() {
    const { availableDays, activeDayStartFrom, monthYearOptions } = this.state;

    const remainder = availableDays.length - (activeDayStartFrom + amountOfDaysVisible);
    const newActiveDayStartFrom =
      remainder < amountOfDaysVisible
        ? availableDays.length - amountOfDaysVisible
        : activeDayStartFrom + amountOfDaysVisible;

    const activeDays = availableDays.slice(newActiveDayStartFrom, amountOfDaysVisible + newActiveDayStartFrom);

    this.setState({
      activeDayStartFrom: newActiveDayStartFrom,
      activeDays: activeDays,
      activeMonthYearOption: findMonth(monthYearOptions, activeDays[0]),
    });
  }

  areMoreDatesAvailable() {
    const { availableDays, activeDayStartFrom } = this.state;
    return activeDayStartFrom + amountOfDaysVisible < availableDays.length;
  }

  render() {
    const { activeMonthYearOption, activePersonOption, activeDays } = this.state;
    const { componentTitle, prices, quotationText, componentLink, priceDisclaimer } = this.props;
    const { personOptions, monthYearOptions } = this.state;

    const list = this.filterProducts(activeDays, activePersonOption);

    return (
      <section className="pricetablesection">
        <p className="heading">{componentTitle}</p>
        {prices.length > 0 && (
          <>
            <div className="form-item--full">
              {personOptions.length > 0 && (
                <div className="form-item-value select-style">
                  <select onChange={this.handlePersonSelect} value={activePersonOption}>
                    {personOptions.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="form-item-value select-style">
                <select
                  onChange={this.handleMonthYearSelect}
                  value={activeMonthYearOption ? activeMonthYearOption.date.toString() : undefined}
                >
                  {monthYearOptions.map(option => (
                    <option key={option.date.toString()} value={option.date.toString()}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <table className="pricetable">
              <tbody>
                <tr>
                  <th className="pricetable__trip">
                    <button
                      className={this.state.activeDayStartFrom > 0 ? 'pricetable__button' : 'pricetable__hidden'}
                      onClick={this.handleDecreaseActiveDays}
                    >
                      &nbsp;&lt;&nbsp;
                    </button>
                  </th>
                  {activeDays &&
                    activeDays.map((activeDay: Day) => (
                      <th className="pricetable__date" key={activeDay.startDateText}>
                        {activeDay.startDateText}
                      </th>
                    ))}

                  <th className="pricetable__arrow">
                    <button
                      className={this.areMoreDatesAvailable() ? 'pricetable__button' : 'pricetable__hidden'}
                      onClick={this.handleIncreaseActiveDays}
                    >
                      &nbsp;&gt;&nbsp;
                    </button>
                  </th>
                </tr>

                {list &&
                  list.map((tableListItem: TableList) => {
                    return (
                      <tr key={tableListItem.description}>
                        <td className="pricetable__trip">{tableListItem.description}</td>

                        {tableListItem.prices.map((price: PriceBlockItemViewModel | null, tableIndex) => {
                          return (
                            <td key={tableIndex}>
                              {price ? (
                                <Link class="cta_button" url={price.componentLinkUrl}>
                                  {price.price} <span className="pricetable__pricetext">{price.priceText}</span>
                                </Link>
                              ) : (
                                <span className="pricetable__empty">x</span>
                              )}
                            </td>
                          );
                        })}

                        <td />
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        )}
        {priceDisclaimer && (
          <div
            className="pricetablesection__pricedisclaimer htmlcontainer"
            dangerouslySetInnerHTML={asEditable(priceDisclaimer)}
          />
        )}
        {quotationText && (
          <div className={cx('price-quotation-request', prices.length === 0 && 'no-margin-top')}>
            <div
              className="price-quotation-request__text"
              dangerouslySetInnerHTML={{
                __html: quotationText,
              }}
            />
            {componentLink && <Link {...componentLink} class="cta_button" />}
          </div>
        )}
      </section>
    );
  }
}
