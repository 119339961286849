import { Component, SyntheticEvent } from 'react';
import cx from 'classnames';
import { ResultFacet } from '../../../../models/ResultFacet';
import { ResultFacetValue } from '../../../../models/ResultFacetValue';
import { ActiveFilter } from '../../../holidayResultContainer/HolidayResultContainer';

interface MultiSelectFilterState {
  expanded: boolean;
}

interface SelectFilterProps extends ResultFacet {
  selectFilterChanged?: (activeFilter: ActiveFilter) => void;
  activeValues?: string;
  sortEmptyItemsLast: boolean;
  disableSelection?: boolean;
  disableExpansion?: boolean;
}

const cutoffPoint = 3;

export default class MultiSelectFilter extends Component<SelectFilterProps, MultiSelectFilterState> {
  constructor(props: SelectFilterProps) {
    super(props);

    this.state = {
      expanded: !!this.props.disableExpansion,
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  changeHandler(event: SyntheticEvent<HTMLAnchorElement>, filterValue: ResultFacetValue) {
    event.preventDefault();

    if (!this.props.selectFilterChanged) {
      return;
    }

    this.props.selectFilterChanged({
      facetId: this.props.id,
      activeValueId: filterValue.id,
    });
  }

  render() {
    const { title, values, sortEmptyItemsLast, disableSelection, disableExpansion } = this.props;
    const { expanded } = this.state;

    if (values.length < 1) {
      return null;
    }

    const hasExpand = values.length > cutoffPoint;

    // Sort as follows:
    // 1. When collapsed, sort selected items first, then the ones with results, then the one without results.
    // 2. When expanded, sort items with result first, then items without results, alphabetically.
    const sortedValues = sortEmptyItemsLast
      ? [
          ...values.filter(v => v && v.numberOfResults && v.numberOfResults > 0),
          ...values.filter(v => v && !v.numberOfResults),
        ]
      : values;
    const selectedValues = sortedValues.filter(v => v && v.isSelected);
    const listValues =
      selectedValues.length > 0 && !expanded && hasExpand
        ? [...selectedValues, ...sortedValues.filter(v => v && !v.isSelected)]
        : sortedValues;

    return (
      <div className={cx('multiselect-filter', !!disableSelection && 'disable-selection')}>
        <div className="filtertoggle noarrow">{title}</div>
        <div className={cx('filterlist', expanded && 'filtermore')}>
          {listValues.map(filterValue => {
            const ComponentName = filterValue.url ? 'a' : 'span';
            const props = {
              className: cx('destinationselect', filterValue.isSelected && 'active'),
              onClick: event => this.changeHandler(event, filterValue),
              href: filterValue.url,
              children: filterValue.title,
            };
            return <ComponentName {...props} key={filterValue.id} />;
          })}
        </div>
        {hasExpand && !disableExpansion && (
          <div className="more" onClick={this.toggleExpanded}>
            {expanded ? 'minder' : 'meer'}
          </div>
        )}
      </div>
    );
  }
}
