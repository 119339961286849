import { FunctionComponent } from 'react';

export interface IconProps {}

const HelpIcon: FunctionComponent<IconProps> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
    <path
      d="M13 26C1.417 26-4.381 11.997 3.808 3.808 11.998-4.38 26 1.418 26 13c0 7.18-5.82 13-13 13zm11-13c-16-8.667-8-4.333 0 0z"
      fill="#999"
    />
    <path d="M13 9a2 2 0 112-2 2 2 0 01-2 2zm0-2zM13 21a2 2 0 01-2-2v-6a2 2 0 014 0v6a2 2 0 01-2 2zm0-8z" fill="#fff" />
  </svg>
);

export default HelpIcon;
