import { FunctionComponent, useEffect, useState } from 'react';
import { CampersOverviewViewModel } from '../../models/CampersOverviewViewModel';
import CamperInformation from './CamperInformation';
import { CamperViewModel } from '../../models/CamperViewModel';
import MiniMenu from '../atoms/miniMenu/MiniMenu';
import { MenuItemViewModel } from '../../models/MenuItemViewModel';

const getMenuItems = (campers: CamperViewModel[], currentCamperId: string): MenuItemViewModel[] =>
  campers.map(
    (c: CamperViewModel): MenuItemViewModel => ({
      selected: c.id === currentCamperId,
      url: `#${c.id}`,
      id: c.id,
      title: c.name,
    })
  );

const CampersOverview: FunctionComponent<CampersOverviewViewModel> = ({
  campers,
  disclaimer,
}: CampersOverviewViewModel) => {
  const [currentCamper, setCamper] = useState<CamperViewModel>(campers[0]);
  useEffect(() => {
    function handleHashChange() {
      const hash = window.location.hash;
      let nextCamper = campers[0];

      if (hash) {
        const nextCamperId = hash.substring(1);
        const foundCamper = campers.find(c => c.id === nextCamperId);
        if (foundCamper) {
          nextCamper = foundCamper;
        }
      }

      setCamper(nextCamper);
    }

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [campers]);

  const menuItems = getMenuItems(campers, currentCamper.id);

  return (
    <div className="campersoverview">
      <MiniMenu items={menuItems} />
      <CamperInformation key={currentCamper.id} {...currentCamper} disclaimer={disclaimer} />
    </div>
  );
};

export default CampersOverview;
