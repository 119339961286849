import { FormEvent, FunctionComponent, PropsWithChildren, useCallback, useState } from 'react';
import cx from 'classnames';
import { ConsentDialogViewModel } from '../../models/ConsentDialogViewModel';
import { asEditable } from '../../helpers/sitecore';

interface ConsentLevelProps {
  level: number;
  currentLevel: number | undefined;
  title: string;
  onPickConsent: (e: FormEvent<HTMLInputElement | HTMLDivElement>) => void;
}

const ConsentLevel: FunctionComponent<PropsWithChildren<ConsentLevelProps>> = ({
  level,
  currentLevel,
  title,
  onPickConsent,
  children,
}) => (
  <div
    className={cx('consent__level', level === currentLevel && 'consent__level--active')}
    data-value={level}
    onClick={onPickConsent}
  >
    <input
      type="radio"
      name="consentlevel"
      value={level}
      id={`consentlevel${level}`}
      checked={level === currentLevel}
      onChange={onPickConsent}
    />
    <label htmlFor={`consentlevel${level}`}>{title}</label>
    {children}
  </div>
);

interface FullConsentFormProps {
  viewModel: ConsentDialogViewModel;
  initialLevel: number | undefined;
  onPickConsent: (level: number) => void;
}

const FullConsentForm: FunctionComponent<FullConsentFormProps> = ({ viewModel, initialLevel, onPickConsent }) => {
  const [level, setLevel] = useState<number | undefined>(initialLevel);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (level !== undefined) {
        onPickConsent(level);
      }
    },
    [level, onPickConsent]
  );

  const handleConsentChange = useCallback(({ currentTarget }: FormEvent<HTMLInputElement | HTMLDivElement>) => {
    setLevel(Number('value' in currentTarget ? currentTarget.value : currentTarget.dataset.value));
  }, []);

  return (
    <div className="popup consentdialog" role="dialog" aria-labelledby="fullconsenttitle">
      <h1 id="fullconsenttitle">Mijn cookie instellingen</h1>
      <p>
        <a href={viewModel.cookieInformationPath}>Meer informatie over cookies op Travelhome.nl</a>
      </p>
      <form onSubmit={handleSubmit}>
        <ConsentLevel level={2} title={viewModel.level3Title} currentLevel={level} onPickConsent={handleConsentChange}>
          <div dangerouslySetInnerHTML={asEditable(viewModel.level3Text)} />
        </ConsentLevel>
        <ConsentLevel level={1} title={viewModel.level2Title} currentLevel={level} onPickConsent={handleConsentChange}>
          <div dangerouslySetInnerHTML={asEditable(viewModel.level2Text)} />
        </ConsentLevel>
        <ConsentLevel level={0} title={viewModel.level1Title} currentLevel={level} onPickConsent={handleConsentChange}>
          <div dangerouslySetInnerHTML={asEditable(viewModel.level1Text)} />
        </ConsentLevel>
        <div className="consent__buttons">
          <button className="cta_button" type="submit" disabled={level === undefined}>
            Akkoord en opslaan
          </button>
        </div>
      </form>
    </div>
  );
};

export default FullConsentForm;
