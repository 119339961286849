import { FunctionComponent, PropsWithChildren } from 'react';
import cx from 'classnames';
import { Placeholder } from './Placeholder';
import { WidgetContainerViewModel } from '../../models/WidgetContainerViewModel';

const ArticleBlocks: FunctionComponent<PropsWithChildren<WidgetContainerViewModel>> = ({
  placeholders,
  children,
  extraMarginTop,
  lessMarginTop,
  extraMarginBottom,
  lessMarginBottom,
}) => {
  return (
    <div
      className={cx(
        'articleblocks',
        extraMarginTop && 'extra-margin-top',
        lessMarginTop && 'less-margin-top',
        extraMarginBottom && 'extra-margin-bottom',
        lessMarginBottom && 'less-margin-bottom'
      )}
    >
      <Placeholder children={children} placeholders={placeholders} />
    </div>
  );
};

const WidgetContainerWrapper: FunctionComponent<PropsWithChildren<WidgetContainerViewModel>> = (
  props: WidgetContainerViewModel
) => {
  const containerClass = props.alignWithSearchResultContainer
    ? 'article-search-container'
    : cx(
        'articlecontainer',
        `articlecontainer__${props.colorCombination || 'theme-senary'}`,
        props.isInEditMode && 'edit-mode'
      );

  return (
    <section className={containerClass}>
      <ArticleBlocks {...props} />
    </section>
  );
};

export default WidgetContainerWrapper;
