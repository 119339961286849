import { FunctionComponent } from 'react';
import { WidgetViewModel } from '../../models/WidgetViewModel';
import Button from '../atoms/button/Button';
import Text from '../atoms/text/Text';

const ActionWidgetBlog: FunctionComponent<WidgetViewModel> = ({
  buttonText,
  showButton,
  componentTitle,
  componentSubTitle,
  componentText,
  componentLink,
  colorCombination,
  textAlignment,
}) => {
  return (
    <div
      className={`custom_cta blog actionwidget--${colorCombination ? colorCombination : 'theme-secondary'} ${
        !!textAlignment ? `text--${textAlignment}` : 'left'
      }`}
    >
      <Text {...componentTitle} className="heading" />

      {componentSubTitle && <h1 className="subheading" dangerouslySetInnerHTML={{ __html: componentSubTitle }} />}
      {componentText && <p className="subheadingem" dangerouslySetInnerHTML={{ __html: componentText }} />}

      {showButton && (
        <Button
          text={buttonText}
          class="cta_button"
          url={componentLink ? componentLink.url : ''}
          target={componentLink ? componentLink.target : ''}
        />
      )}
    </div>
  );
};

export default ActionWidgetBlog;
