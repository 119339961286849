import { FunctionComponent } from 'react';
import { SpotlightHeaderViewModel } from '../../models/SpotlightHeaderViewModel';
import SpotlightHeader from './SpotlightHeader';
import { asEditable } from '../../helpers/sitecore';
import Stars from '../atoms/stars/Stars';
import Text from '../atoms/text/Text';
import ImageHtml from '../atoms/imageHtml/ImageHtml';

const SpotlightHeaderProduct: FunctionComponent<SpotlightHeaderViewModel> = props => {
  const { componentTitle, componentShortText, componentSubTitle, showRating, rating, relatedTourleaders } = props;

  return (
    <SpotlightHeader {...props} className="product">
      <div className="productwidget">
        <h2 dangerouslySetInnerHTML={asEditable(componentShortText)} />
        {showRating && <Stars count={rating} />}
        <span className="preheader" dangerouslySetInnerHTML={asEditable(componentSubTitle)} />
        <Text {...componentTitle} />

        {relatedTourleaders &&
          relatedTourleaders.map(tourleader => (
            <span className="tip" key={tourleader.id}>
              {tourleader.componentImageHtml && <ImageHtml {...tourleader.componentImageHtml} />}
              <span className="tip__title">{tourleader.componentText}</span>
            </span>
          ))}
      </div>
    </SpotlightHeader>
  );
};

export default SpotlightHeaderProduct;
