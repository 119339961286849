import { Children, Component, PropsWithChildren } from 'react';
import cx from 'classnames';
import { SpotlightHeaderViewModel } from '../../models/SpotlightHeaderViewModel';
import { VideoPlayerWrapper } from '../video/VideoPlayerWrapper';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import { BreakPoints } from '../../constants/breakPoints';
const withSizes = require('react-sizes');

export interface SpotlightHeaderState {
  isMobile?: boolean;
}

interface SpotlightHeaderProps extends SpotlightHeaderViewModel {
  className?: string;
  isMobile?: boolean;
}

class SpotlightHeader extends Component<PropsWithChildren<SpotlightHeaderProps>, SpotlightHeaderState> {
  constructor(props: SpotlightHeaderProps) {
    super(props);
    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    if (this.props.isMobile) {
      this.setState({ isMobile: true });
    }
  }

  componentDidUpdate(prevProps: SpotlightHeaderProps) {
    if (prevProps.isMobile !== this.props.isMobile) {
      this.setState({
        isMobile: this.props.isMobile,
      });
    }
  }

  render() {
    const { videoId, componentImageHtml, logoImages, isInEditMode, className, children } = this.props;
    const { isMobile } = this.state;

    const childrenAsArray = Children.toArray(children);

    return (
      <div className={cx('spotlight-header-wrapper', className)}>
        <section className={cx('spotlight-header', isInEditMode && 'spotlight-header-edit-mode')}>
          {childrenAsArray.length >= 1 ? childrenAsArray[0] : undefined}

          <div className="spotlight-header__media-background">
            {!isMobile && videoId && (
              <VideoPlayerWrapper videoId={videoId} playing={true} setMaxSize={true} isInEditMode={isInEditMode} />
            )}
            {componentImageHtml && <ImageHtml {...componentImageHtml} class="spotlight-header__media-image" />}
          </div>

          {logoImages && logoImages.length > 0 && (
            <div className="awards">
              <ul>
                {logoImages.map((logo, key) => (
                  <li key={key}>
                    {' '}
                    <ImageHtml {...logo} />{' '}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </section>

        {childrenAsArray.length >= 2 ? childrenAsArray[1] : undefined}
      </div>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < BreakPoints.screenSm,
});

export default withSizes.withSizes(mapSizesToProps)(SpotlightHeader);
