import { FunctionComponent } from 'react';
import { IntroTextViewModel } from '../../models/IntroTextViewModel';
import Text from '../atoms/text/Text';
import { asEditable } from '../../helpers/sitecore';

const IntroTextNewsArticle: FunctionComponent<IntroTextViewModel> = ({
  componentText,
  componentTitle,
  componentSubTitle,
}: IntroTextViewModel) => {
  return (
    <section className="introtext-news">
      {componentTitle && <Text {...componentTitle} className="heading" />}
      {componentSubTitle && <p className="introtext-date" dangerouslySetInnerHTML={asEditable(componentSubTitle)} />}
      <div className="quote quote--spacing-top">
        <div dangerouslySetInnerHTML={asEditable(componentText)} />
      </div>
    </section>
  );
};

export default IntroTextNewsArticle;
