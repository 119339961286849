import { FunctionComponent, PropsWithChildren } from 'react';
import cx from 'classnames';
import { Placeholder } from './Placeholder';
import { RegionBlockContainerViewModel } from '../../models/RegionBlockContainerViewModel';

const RegionBlockContainer: FunctionComponent<PropsWithChildren<RegionBlockContainerViewModel>> = ({
  title,
  children,
  placeholders,
  isInEditMode,
}) => (
  <section className={cx('regionblock-container', isInEditMode && 'edit-mode')}>
    {title && <h3 className="heading">{title}</h3>}
    <div>
      <Placeholder children={children} placeholders={placeholders} />
    </div>
  </section>
);

export default RegionBlockContainer;
