import { FunctionComponent } from 'react';
import { CamperViewModel } from '../../models/CamperViewModel';

const formatScore = (score: number): string => {
  if (score === 10) {
    return '10';
  }

  return score.toLocaleString().substring(0, 3);
};

interface ScoreProps {
  supplierName: string;
  score: NonNullable<CamperViewModel['supplier']['score']>;
}

const Score: FunctionComponent<ScoreProps> = ({ score, supplierName }) => {
  const numberOfRatings = score.numberOfRatings;
  return (
    <>
      <div>
        <div className="score__title">Kwaliteit camper</div>
        <div className="score__score">
          <span className="score__value">{formatScore(score.vehicle)}</span> / 10
        </div>
      </div>

      <div>
        <div className="score__title">Kwaliteit inventaris</div>
        <div className="score__score">
          <span className="score__value">{formatScore(score.inventory)}</span> / 10
        </div>
      </div>

      <div>
        <div className="score__title">Service verhuurder</div>
        <div className="score__score">
          <span className="score__value">{formatScore(score.service)}</span> / 10
        </div>
      </div>

      <div>
        <div className="score__title">Informatievoorziening</div>
        <div className="score__score">
          <span className="score__value">{formatScore(score.information)}</span> / 10
        </div>
      </div>

      <div>
        <div className="score__title">Totaalscore</div>
        <div className="score__score">
          <span className="score__value">{formatScore(score.total)}</span> / 10
        </div>
      </div>

      <p>
        {numberOfRatings === 1
          ? `Gebaseerd op ${numberOfRatings} beoordeling van klanten die met ${supplierName} gereisd hebben.`
          : `Gebaseerd op ${numberOfRatings} beoordelingen van klanten die met ${supplierName} gereisd hebben.`}
      </p>
    </>
  );
};

export default Score;
