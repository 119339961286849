import { Component } from 'react';
import { CustomerReviewViewModel } from '../../models/CustomerReviewViewModel';
import { VideoPlayerWrapper } from '../video/VideoPlayerWrapper';
import { BreakPoints } from '../../constants/breakPoints';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import { asEditable } from '../../helpers/sitecore';

export interface CustomerReviewState {
  isMobile: boolean;
}

const createMarkup = value => {
  return { __html: value };
};

interface CustomerReviewModel extends CustomerReviewViewModel {
  isMobile: boolean;
}

const withSizes = require('react-sizes');

class CustomerReview extends Component<CustomerReviewModel, CustomerReviewState> {
  constructor(props: CustomerReviewModel) {
    super(props);

    this.state = {
      isMobile: false,
    };
  }

  componentDidUpdate(prevProps: CustomerReviewModel) {
    if (prevProps.isMobile !== this.props.isMobile) {
      this.setState({
        isMobile: this.props.isMobile,
      });
    }
  }

  render() {
    const { text, videoId, rating, logos, image, isInEditMode } = this.props;
    const { isMobile } = this.state;

    return (
      <section className="customerreview">
        <div className="customerreview hasvideobackground player-wrapper">
          {!isMobile && videoId && (
            <VideoPlayerWrapper videoId={videoId} setMaxSize={true} playing={true} isInEditMode={isInEditMode} />
          )}
          {image && <ImageHtml {...image} class="reviewimage" />}
          <div className="reviewcontainer">
            {text && <div className="reviewcontent" dangerouslySetInnerHTML={createMarkup(text)} />}
            {rating && <div className="reviewgrade">{rating}</div>}
          </div>
          {logos && logos.length > 0 && (
            <div className="partnerlogos" dangerouslySetInnerHTML={asEditable(logos.join(''))} />
          )}
        </div>
      </section>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < BreakPoints.screenSm,
});

export default withSizes.withSizes(mapSizesToProps)(CustomerReview);
