import { Component } from 'react';
import createDecorator from 'final-form-focus';
import PartialScreenLoader from '../atoms/loader/PartialScreenLoader';
import ErrorMessage from '../atoms/message/ErrorMessage';
import SuccessMessage from '../atoms/message/SuccessMessage';
import { Field, withTypes } from 'react-final-form';
import SubmitButton from '../atoms/form/SubmitButton';
import { submitForm } from '../../helpers/form';
import { FormApi } from 'final-form';
import { BlogCommentsFormViewModel } from '../../models/BlogCommentsFormViewModel';
import TextField from '../atoms/form/TextField';
import TextArea from '../atoms/form/TextArea';
import { BlogCommentsFormInputModel } from '../../models/BlogCommentsFormInputModel';

const decorators = [createDecorator()];

const { Form } = withTypes<BlogCommentsFormInputModel>();

interface ExtendedModel extends BlogCommentsFormViewModel {
  activeBlogPostId: string;
  fetchComments: (activeBlogPostId: string) => void;
}

class BlogCommentsForm extends Component<ExtendedModel> {
  handleSubmit = (initialValues: BlogCommentsFormInputModel) => {
    const submitFunction = submitForm(this.props, initialValues);

    return (values: any, form: FormApi<BlogCommentsFormInputModel>) => {
      return submitFunction(values, form).then(errors => {
        if (errors) {
          return errors;
        }

        this.props.fetchComments(this.props.activeBlogPostId);
        return undefined;
      });
    };
  };

  render() {
    const { componentTitle, loadingText, fieldNames, successMessage, buttonText, activeBlogPostId } = this.props;
    const required = value => (value ? undefined : 'Verplicht');

    const initialValues: BlogCommentsFormInputModel = {
      author: '',
      componentText: '',
      currentBlogPostId: activeBlogPostId,
    };

    return (
      <section className="add_reaction">
        <p
          className="heading"
          dangerouslySetInnerHTML={{
            __html: componentTitle ? componentTitle : '',
          }}
        />
        {fieldNames && Object.keys(fieldNames).length > 0 ? (
          <Form
            onSubmit={this.handleSubmit(initialValues)}
            decorators={decorators}
            initialValues={initialValues}
            subscription={{
              pristine: true,
              invalid: true,
              submitFailed: true,
              submitError: true,
              submitErrors: true,
              submitSucceeded: true,
              submitting: true,
            }}
            render={({ handleSubmit, pristine, invalid, submitFailed, submitError, submitSucceeded, submitting }) => {
              return (
                <form onSubmit={handleSubmit} noValidate={true}>
                  {submitting && <PartialScreenLoader loadingText={loadingText} />}
                  {!submitting && invalid && submitFailed && (
                    <ErrorMessage>{this.props.submitErrorMessage}</ErrorMessage>
                  )}
                  {!submitting && submitError && <ErrorMessage scrollIntoView={false}>{submitError}</ErrorMessage>}
                  {!submitting && submitSucceeded && pristine && (
                    <SuccessMessage scrollIntoView={false}>
                      <div dangerouslySetInnerHTML={{ __html: successMessage }} />
                    </SuccessMessage>
                  )}

                  <div className="blog-editform">
                    <Field
                      validate={required}
                      component={TextField}
                      name="author"
                      required={true}
                      placeholder={fieldNames.author}
                    />
                    <Field
                      validate={required}
                      component={TextArea}
                      name="componentText"
                      required={true}
                      placeholder={fieldNames.componentText}
                    />
                  </div>
                  <div>
                    <SubmitButton disabled={pristine || invalid} buttonText={buttonText} />
                  </div>
                </form>
              );
            }}
          />
        ) : null}
      </section>
    );
  }
}

export default BlogCommentsForm;
