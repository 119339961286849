import { FunctionComponent, MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { PaneData, PaneDataItem, PaneState } from './types';

export interface FuelCalculatorPaneProps {
  state: PaneState;
  currentItemKey?: string;
  data?: PaneData<PaneDataItem>;
  onSelect: (item: PaneDataItem | undefined) => void;
}

interface FuelCalculatorPaneState {
  currentItem?: PaneDataItem;
}

const findParent = (
  itemKey: string,
  data: PaneData<PaneDataItem>,
  parent: PaneDataItem | null = null
): PaneDataItem | null => {
  const items = parent ? parent.children : data.items;
  if (!items) {
    return null;
  }

  for (const child of items) {
    if (child.key === itemKey) {
      return parent;
    }

    if (child.children) {
      const possibleParent = findParent(itemKey, data, child);
      if (possibleParent) {
        return possibleParent;
      }
    }
  }

  return null;
};

const handleItemClick = (
  event: ReactMouseEvent<HTMLButtonElement>,
  item: PaneDataItem | null,
  paneState: FuelCalculatorPaneState,
  setPaneState: (
    value: ((prevState: FuelCalculatorPaneState) => FuelCalculatorPaneState) | FuelCalculatorPaneState
  ) => void,
  paneData: PaneData<PaneDataItem>,
  onSelect: (item: PaneDataItem) => void,
  innerPane: HTMLDivElement | null
): void => {
  // Prevent bubbling to the document click handler.
  event.nativeEvent.stopImmediatePropagation();

  if (!item) {
    // Going back.
    if (!paneState.currentItem) {
      return;
    }

    const parent = findParent(paneState.currentItem.key, paneData);
    setPaneState({ ...paneState, currentItem: parent ? parent : undefined });
    if (innerPane) {
      innerPane.scrollTop = 0;
    }
    return;
  }

  if (item.children) {
    // Open children.
    setPaneState({ ...paneState, currentItem: item });
    if (innerPane) {
      innerPane.scrollTop = 0;
    }
  } else {
    onSelect(item);
  }
};

const FuelCalculatorPane: FunctionComponent<FuelCalculatorPaneProps> = ({ state, data, onSelect, currentItemKey }) => {
  // State handler.
  const [paneState, setPaneState] = useState<FuelCalculatorPaneState>(() => {
    if (!currentItemKey || !data) {
      return {};
    }

    const currentItem = findParent(currentItemKey, data);
    return {
      currentItem: currentItem || undefined,
    };
  });

  // Reference for scrolling up.
  const innerPaneReference = useRef<HTMLDivElement>(null);

  // Close on click.
  const paneReference = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleDocumentClick(e: MouseEvent) {
      if (paneReference.current && !paneReference.current.contains(e.target as Node)) {
        e.preventDefault();
        onSelect(undefined);
      }
    }

    // Prevent triggering on the initial click.
    setTimeout(() => {
      document.addEventListener('click', handleDocumentClick);
    }, 200);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [onSelect]);

  if (state === PaneState.Loading) {
    return (
      <div className="fuelcalculatorpane" ref={paneReference}>
        Bezig met laden ...
      </div>
    );
  }

  if (state === PaneState.Error) {
    return (
      <div className="fuelcalculatorpane" ref={paneReference}>
        Er is een fout opgetreden bij het laden van de gegevens. Probeer het later opnieuw.
      </div>
    );
  }

  if (state === PaneState.None || !data) {
    return null;
  }

  const items = paneState.currentItem && paneState.currentItem.children ? paneState.currentItem.children : data.items;

  return (
    <div className="fuelcalculatorpane" ref={paneReference}>
      <nav className="fuelcalculatorpane__inner" ref={innerPaneReference}>
        {paneState.currentItem && (
          <button
            type="button"
            className={cx('fuelcalculatorpane__item', 'fuelcalculatorpane__item--back')}
            key="back"
            onClick={e => handleItemClick(e, null, paneState, setPaneState, data, onSelect, innerPaneReference.current)}
          >
            Terug
          </button>
        )}

        {items.map(i => (
          <button
            type="button"
            className={cx('fuelcalculatorpane__item', i.children && 'fuelcalculatorpane__item--haschildren')}
            key={i.key}
            onClick={e => handleItemClick(e, i, paneState, setPaneState, data, onSelect, innerPaneReference.current)}
          >
            {i.title}
          </button>
        ))}
      </nav>
    </div>
  );
};

export default FuelCalculatorPane;
