import { FunctionComponent } from 'react';
import { asEditable } from '../../helpers/sitecore';
import { ImageHtml } from '../../models/ImageHtml';
import { Link } from '../../models/Link';

interface HeaderUserIconProps {
  userIcon: ImageHtml;
  userLink: Link;
}

const HeaderUserIcon: FunctionComponent<HeaderUserIconProps> = ({ userIcon, userLink }) => (
  <div className="headerusericon">
    <a
      dangerouslySetInnerHTML={asEditable(userIcon.html)}
      className="headerusericon__usericon"
      href={userLink.url}
      title={userLink.title}
    />
    <a className="headerusericon__userlink" href={userLink.url} title={userLink.title}>
      {userLink.text}
    </a>
  </div>
);

export default HeaderUserIcon;
