import { FunctionComponent } from 'react';
import { BlogHeadingViewModel } from '../../models/BlogHeadingViewModel';

const BlogHeading: FunctionComponent<BlogHeadingViewModel> = ({ componentTitle, componentSubTitle, componentText }) => (
  <div className="blog-info">
    <h1
      className="heading"
      dangerouslySetInnerHTML={{
        __html: componentTitle ? componentTitle : '',
      }}
    />
    {componentSubTitle && <h2 className="subheading" dangerouslySetInnerHTML={{ __html: componentSubTitle }} />}
    {componentText && <h3 className="subheadingem" dangerouslySetInnerHTML={{ __html: componentText }} />}
  </div>
);

export default BlogHeading;
