import { FunctionComponent, PropsWithChildren } from 'react';
import { Field } from 'react-final-form';

interface ConditionProps {
  when: string;
  is: unknown;
}

const Condition: FunctionComponent<PropsWithChildren<ConditionProps>> = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value || typeof value === is ? children : null)}
  </Field>
);

export default Condition;
