import { FormEvent, FunctionComponent, useRef, useState } from 'react';
import cx from 'classnames';

export interface SearchBoxProps {
  placeholderText: string;
  onSubmit?: (value: string) => void;
  value?: string;
  id: string;
  searchPageUrl?: string;
  collapsible: boolean;
}

const SearchBox: FunctionComponent<SearchBoxProps> = ({
  placeholderText,
  onSubmit,
  value,
  id,
  searchPageUrl,
  collapsible,
}) => {
  const [currentValue, setCurrentValue] = useState(value || '');
  const [collapsed, setCollapsed] = useState(true);
  const fieldRef = useRef<HTMLInputElement>(null);

  const handleSubmit =
    currentValue === '' && !collapsed
      ? (e: FormEvent) => {
          e.preventDefault();
          setCollapsed(true);
        }
      : onSubmit
      ? (e: FormEvent) => {
          e.preventDefault();
          onSubmit(currentValue);
        }
      : undefined;

  const handleButtonClick =
    collapsible && collapsed
      ? e => {
          e.preventDefault();
          setCollapsed(false);

          const inputElement = fieldRef.current;
          if (inputElement) {
            window.setTimeout(() => {
              inputElement.focus();
            }, 500);
          }
        }
      : undefined;

  return (
    <form
      method="get"
      action={searchPageUrl || '?'}
      className={cx(
        'searchbox',
        collapsible && 'searchbox--collapsible',
        collapsible && collapsed && 'searchbox--collapsed',
        collapsible && !collapsed && 'searchbox--open'
      )}
      onSubmit={handleSubmit}
    >
      <label htmlFor={id} className="sr-only searchbox__label">
        Zoek op tekst
      </label>
      <input
        type="search"
        id={id}
        name="t"
        className="searchbox__field"
        placeholder={placeholderText}
        value={currentValue}
        onChange={e => setCurrentValue(e.target.value)}
        required={!collapsible}
        ref={fieldRef}
      />
      <button type="submit" className="searchbox__button icon icon-search" onClick={handleButtonClick}>
        <span className="sr-only">Zoeken</span>
      </button>
    </form>
  );
};

export default SearchBox;
