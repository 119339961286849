import { FunctionComponent } from 'react';
import { FieldRenderProps } from 'react-final-form';
import FormItem from './atoms/FormItem';
import ValidationError from './atoms/ValidationError';
import RichTextEditor from './RichTextEditor';

export interface RichTextAreaProps extends FieldRenderProps<string> {
  label?: string;
  className?: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
}

const RichTextArea: FunctionComponent<RichTextAreaProps> = ({ input, meta, ...rest }) => (
  <FormItem meta={meta} {...rest}>
    {rest.label && (
      <label htmlFor={input.name} className={rest.required ? 'required' : ''}>
        {rest.label}:
      </label>
    )}
    <div className="form-item-value">
      <RichTextEditor initialValue={input.value} handleChange={input.onChange} id={input.name} />
    </div>
    <ValidationError meta={meta} />
  </FormItem>
);

export default RichTextArea;
