import { FunctionComponent } from 'react';

export interface MarkerProps {
  lat: number;
  lng: number;
  icon: string;
  onClick?: () => void;
  title?: string;
}

const Marker: FunctionComponent<MarkerProps> = ({ icon, onClick, title }) => (
  <div className="map-marker" onClick={onClick}>
    <img src={icon} alt={title} title={title} className="map-marker--image" />
  </div>
);

export default Marker;
