import { CamperViewModel, Range } from '../../models/CamperViewModel';

export const writeMaximumRecommendedPassengers = ({
  recommendedMaximumAdults: adults,
  recommendedMaximumChildren: children,
}: Pick<CamperViewModel, 'recommendedMaximumAdults' | 'recommendedMaximumChildren'>): string | undefined => {
  if (!adults) {
    return undefined;
  }

  return writeAdultsAndChildren(adults, children);
};

export const writeAdultsAndChildren = (adults: number, children: number | null | undefined): string => {
  let text = adults === 1 ? '1 volwassene' : `${adults} volwassenen`;
  if (children) {
    text += ' en ' + (children === 1 ? '1 kind' : `${children} kinderen`);
  }
  return text;
};

export const writeYears = (years: number): string => `${years} jaar`;

export const writeYearsAndMonths = (totalMonths: number | undefined | null): string | undefined => {
  if (!totalMonths || totalMonths <= 0) {
    return undefined;
  }

  const years = Math.floor(totalMonths);
  const months = 12 * (totalMonths - years);

  return (
    (years ? writeYears(years) : '') +
    (years && months ? ' en ' : '') +
    (months ? (months === 1 ? '1 maand' : `${months} maanden`) : '')
  );
};

export const writeDriverAge = (value: CamperViewModel['driverAge']): string | undefined => {
  if (!value) {
    return undefined;
  }

  const text: string[] = [];

  const { minimum, maximum, remarks } = value;

  if (value.minimum) {
    text.push(`minimaal ${minimum} jaar`);
  }

  if (value.maximum) {
    text.push(`maximaal ${maximum} jaar`);
  }

  return text.join(', ') + (remarks ? ' ' + remarks : '');
};

export const writeRange = (
  range: Range | undefined | null,
  suffix = '',
  suffixSingular: string | undefined = undefined
): string | undefined => {
  if (range === null || range === undefined) {
    return undefined;
  }
  if (range.minimum === range.maximum || !range.maximum) {
    return `${range.minimum} ${range.minimum === 1 && suffixSingular ? suffixSingular : suffix}`;
  }

  return `${range.minimum} - ${range.maximum} ${suffix}`;
};
