import { FunctionComponent } from 'react';
import { BlogOverviewContainerViewModel } from '../../models/BlogOverviewContainerViewModel';
import SearchResultBlogPost from '../searchResult/SearchResultBlogPost';

const BlogOverviewContainer: FunctionComponent<BlogOverviewContainerViewModel> = ({ blogPosts }) => (
  <section>
    <div className="results">
      {blogPosts && blogPosts.map(blog => <SearchResultBlogPost key={blog.id} {...blog} />)}
    </div>
  </section>
);

export default BlogOverviewContainer;
