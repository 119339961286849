import { Component } from 'react';
import { BlogEditViewModel } from '../../models/BlogEditViewModel';
import BlogHeading from './BlogHeading';
import BlogLogoff from './BlogLogoff';
import { Field, withTypes } from 'react-final-form';
import { FormApi } from 'final-form';
import createDecorator from 'final-form-focus';
import TextField from '../atoms/form/TextField';
import SubmitButton from '../atoms/form/SubmitButton';
import ErrorMessage from '../atoms/message/ErrorMessage';
import { submitBlogPost, deleteBlogPost } from '../../helpers/form';
import UploadButton from '../atoms/form/UploadButton';
import DocumentsField from '../atoms/form/DocumentsField';
import { asEditable } from '../../helpers/sitecore';
import SuccessMessage from '../atoms/message/SuccessMessage';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import MapBlock from '../mapBlock/MapBlock';
import { MapMarker } from '../../models/MapMarker';
import { BlogEditInputModel } from '../../models/BlogEditInputModel';
import BlogEditTable from './BlogEditTable';
import FullScreenLoader from '../atoms/loader/FullScreenLoader';
import BlogAutoSave from './atoms/BlogAutoSave';
import RichTextArea from '../atoms/form/RichTextArea';

const decorators = [createDecorator()];

const { Form } = withTypes<BlogEditInputModel>();

export interface ExtendedBlogEditViewModel extends BlogEditViewModel {
  location?: string;
  geoLocationLatitude?: string;
  geoLocationLongitude?: string;
}

export interface BlogEditFormState {
  location: string;
  locationMapMarker?: MapMarker;
  initialData?: BlogEditInputModel;
}

const getCacheKey = (currentItem: BlogEditInputModel | undefined): string => {
  if (!currentItem || !currentItem.currentBlogPostId) {
    return 'weblog-content';
  }

  return `weblog-content-${currentItem.currentBlogPostId}`;
};

class BlogEditForm extends Component<ExtendedBlogEditViewModel, BlogEditFormState> {
  state: BlogEditFormState = {
    location: '',
    locationMapMarker: undefined,
  };

  componentDidMount() {
    const { blogEditInputModel } = this.props;
    if (blogEditInputModel) {
      this.setState({
        initialData: {
          ...blogEditInputModel,
          firstImage: blogEditInputModel.firstImageSrc,
          secondImage: blogEditInputModel.secondImageSrc,
          thirdImage: blogEditInputModel.thirdImageSrc,
          fourthImage: blogEditInputModel.fourthImageSrc,
          fifthImage: blogEditInputModel.fifthImageSrc,
        },
        locationMapMarker: {
          id: 'new',
          title: blogEditInputModel.location,
          latitude: blogEditInputModel.geoLocationLatitude ? Number(blogEditInputModel.geoLocationLatitude) : 0,
          longitude: blogEditInputModel.geoLocationLongitude ? Number(blogEditInputModel.geoLocationLongitude) : 0,
        },
        location: blogEditInputModel.location || '',
      });
    }
  }

  handleSubmit = (values: BlogEditInputModel, form: FormApi) =>
    submitBlogPost<BlogEditInputModel>(this.props, values, form, this.state.location, this.state.locationMapMarker);

  handleChange = location => {
    this.setState({ location });
  };

  handleSelectChange = (latLng, location) => {
    if (latLng && Object.keys(latLng).length === 2) {
      this.setState({
        location: location,
        locationMapMarker: {
          id: 'new',
          title: location,
          latitude: latLng.lat,
          longitude: latLng.lng,
        },
      });
    }
  };

  handleSelect = location => {
    geocodeByAddress(location)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.handleSelectChange(latLng, location))
      .catch(error => console.error('Error', error));
  };

  cancelEdit = () => {
    window.location.href = this.props.cancellationDestinationUrl || '/';
  };

  render() {
    const {
      blogHeadingViewModel,
      fieldNames,
      componentTitle,
      fileUploadText,
      fileSelectedText,
      successMessage,
      locationPlaceholderText,
      autoSuggestLoadingText,
      blogEditInputModel,
      blogPostsTable,
      submitLoadingText,
    } = this.props;
    const { locationMapMarker } = this.state;

    const isNew = !blogEditInputModel;
    const cacheKey = getCacheKey(blogEditInputModel);

    return fieldNames && Object.keys(fieldNames).length > 0 ? (
      <>
        <div className="edit-blog-form">
          <div className="edit-blog-form-intro">
            <BlogHeading {...blogHeadingViewModel} />
            {this.props.blogLogOffViewModel && <BlogLogoff {...this.props} />}
          </div>
          <Form
            onSubmit={this.handleSubmit}
            initialValues={this.state.initialData}
            decorators={decorators}
            subscription={{
              pristine: true,
              invalid: true,
              submitFailed: true,
              submitError: true,
              submitErrors: true,
              submitSucceeded: true,
              submitting: true,
            }}
            render={({ handleSubmit, pristine, invalid, submitFailed, submitError, submitSucceeded, submitting }) => {
              return (
                <form onSubmit={handleSubmit} noValidate={true}>
                  {submitting && <FullScreenLoader loadingText={submitLoadingText} />}
                  {!submitting && invalid && submitFailed && (
                    <ErrorMessage>{this.props.submitErrorMessage}</ErrorMessage>
                  )}
                  {!submitting && submitError && <ErrorMessage scrollIntoView={true}>{submitError}</ErrorMessage>}
                  {!submitting && submitSucceeded && pristine && (
                    <SuccessMessage scrollIntoView={true}>
                      <div dangerouslySetInnerHTML={{ __html: successMessage }} />
                    </SuccessMessage>
                  )}

                  <BlogAutoSave cacheKey={cacheKey} debounce={500} />

                  {componentTitle && <h2>{componentTitle}</h2>}
                  <div className="blog-editform">
                    <Field
                      component={TextField}
                      name="subject"
                      label={fieldNames.subject}
                      required={true}
                      autoFocus={true}
                    />
                    <Field component={TextField} name="author" label={fieldNames.author} required={true} />
                    <Field
                      component={RichTextArea}
                      name="componentText"
                      label={fieldNames.componentText}
                      required={true}
                      className="form-item--componentText"
                    />

                    <Field component={DocumentsField} name="documents" label={fieldNames.documents}>
                      <div dangerouslySetInnerHTML={asEditable(fileUploadText)} />
                      <div className="image-fields">
                        <Field
                          component={UploadButton}
                          name="firstImage"
                          label={`${fieldNames.firstImage} 1`}
                          selectedText={fileSelectedText}
                          fileTypes={['image/jpeg']}
                        />
                        <Field
                          component={UploadButton}
                          name="secondImage"
                          label={`${fieldNames.secondImage} 2`}
                          selectedText={fileSelectedText}
                          fileTypes={['image/jpeg']}
                        />
                        <Field
                          component={UploadButton}
                          name="thirdImage"
                          label={`${fieldNames.thirdImage} 3`}
                          selectedText={fileSelectedText}
                          fileTypes={['image/jpeg']}
                        />
                        <Field
                          component={UploadButton}
                          name="fourthImage"
                          label={`${fieldNames.fourthImage} 4`}
                          selectedText={fileSelectedText}
                          fileTypes={['image/jpeg']}
                        />
                        <Field
                          component={UploadButton}
                          name="fifthImage"
                          label={`${fieldNames.fifthImage} 5`}
                          selectedText={fileSelectedText}
                          fileTypes={['image/jpeg']}
                        />
                      </div>
                    </Field>
                    <Field
                      component={TextField}
                      type="hidden"
                      name="geoLocationLatitude"
                      label={fieldNames.geoLocationLatitude}
                    />
                    <Field
                      component={TextField}
                      type="hidden"
                      name="geoLocationLongitude"
                      label={fieldNames.geoLocationLongitude}
                    />
                    <Field
                      component={TextField}
                      type="hidden"
                      name="location"
                      label={fieldNames.location}
                      required={true}
                    />
                    <div className="form-item">
                      <div className="form-item-value">
                        <PlacesAutocomplete
                          debounce={500}
                          value={this.state.location}
                          onChange={this.handleChange}
                          onSelect={this.handleSelect}
                          shouldFetchSuggestions={this.state.location.length >= 2}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: locationPlaceholderText,
                                  className: 'location-search-input',
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>{autoSuggestLoadingText}</div>}
                                {suggestions.map((suggestion, key) => {
                                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: '#426514',
                                        color: '#ffffff',
                                        padding: '0 0 0 10px',
                                        cursor: 'pointer',
                                      }
                                    : {
                                        backgroundColor: '#79a71e',
                                        color: '#ffffff',
                                        padding: '0 0 0 10px',
                                        cursor: 'pointer',
                                      };
                                  return (
                                    <div
                                      key={key}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        {locationMapMarker && locationMapMarker.title && (
                          <MapBlock locationMapMarker={locationMapMarker} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <SubmitButton disabled={pristine || invalid} />
                    {!isNew && (
                      <SubmitButton
                        disabled={pristine || invalid}
                        type="button"
                        buttonText="Verwijderen"
                        onClick={() => deleteBlogPost(this.props)}
                      />
                    )}
                    <SubmitButton
                      disabled={pristine || invalid}
                      type="button"
                      buttonText="Annuleren"
                      onClick={this.cancelEdit}
                    />
                  </div>
                </form>
              );
            }}
          />
        </div>
        <BlogEditTable {...blogPostsTable} />
      </>
    ) : null;
  }
}

export default BlogEditForm;
