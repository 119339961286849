import { FunctionComponent, PropsWithChildren, useState } from 'react';
import TooltipButton from './TooltipButton';

interface TooltipButtonAutoProps {
  small?: boolean;
  tooltipClass?: string;
}

const TooltipButtonAuto: FunctionComponent<PropsWithChildren<TooltipButtonAutoProps>> = ({
  small,
  tooltipClass,
  children,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState<string>();

  return (
    <TooltipButton
      id="tooltip"
      open={tooltipOpen !== undefined}
      setOpen={setTooltipOpen}
      small={small}
      tooltipClass={tooltipClass}
    >
      {children}
    </TooltipButton>
  );
};

export default TooltipButtonAuto;
