import { FunctionComponent } from 'react';
import { IntroTextViewModel } from '../../models/IntroTextViewModel';
import ImageHtml from '../atoms/imageHtml/ImageHtml';
import Text from '../atoms/text/Text';

const IntroTextCamping: FunctionComponent<IntroTextViewModel> = (props: IntroTextViewModel) => {
  const { componentText, componentImage, componentTitle, componentSubTitle } = props;

  return (
    <section className="introtext-camping">
      {componentTitle && <Text {...componentTitle} className="heading" />}
      {componentSubTitle && (
        <p
          className="introtext-address"
          dangerouslySetInnerHTML={{
            __html: componentSubTitle ? componentSubTitle : '',
          }}
        />
      )}
      <div className="quote quote--spacing-top">
        <div>{componentImage && <ImageHtml {...componentImage} class="introtext-camping__image" />}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: componentText ? componentText : '',
          }}
        />
      </div>
    </section>
  );
};

export default IntroTextCamping;
