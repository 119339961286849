import { FunctionComponent, PropsWithChildren } from 'react';
import cx from 'classnames';
import Tooltip from './Tooltip';
import HelpIcon from '../icons/HelpIcon';

interface TooltipButtonProps {
  id: string;
  open: boolean;
  small?: boolean;
  setOpen: (id: string | undefined) => void;
  tooltipClass?: string;
}

const TooltipButton: FunctionComponent<PropsWithChildren<TooltipButtonProps>> = ({
  id,
  open,
  small,
  setOpen,
  tooltipClass,
  children,
}) => (
  <div className="tooltip__button">
    <button
      className={cx('tooltip__icon', small && 'tooltip__iconsmall')}
      onClick={() => setOpen(open ? undefined : id)}
    >
      <HelpIcon />
    </button>
    {open && <Tooltip className={cx('tooltip__tooltipinbutton', tooltipClass)}>{children}</Tooltip>}
  </div>
);

export default TooltipButton;
