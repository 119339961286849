import { FunctionComponent } from 'react';
import cx from 'classnames';
import { asEditable } from '../../helpers/sitecore';
import Button from '../atoms/button/Button';

export interface PopupInnerProps {
  title?: string;
  text: string;
  onClose: () => void;
}

const PopupInner: FunctionComponent<PopupInnerProps> = ({ title, text, onClose }) => (
  <div className="popup">
    {title && <p className="heading">{title}</p>}
    <div dangerouslySetInnerHTML={asEditable(text)} />
    <Button onClick={onClose} class={cx('cta_button', 'cta_button-theme-primary')} text="Sluiten" />
  </div>
);
export default PopupInner;
