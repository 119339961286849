import { FunctionComponent } from 'react';
import TravelPartContent from './TravelPartContent';
import { asEditable } from '../../helpers/sitecore';
import Link from '../atoms/link/Link';
import { BlogPostsViewModel } from '../../models/BlogPostsViewModel';
import { TravelPartsViewModel } from '../../models/TravelPartsViewModel';

const EditBlogContent: FunctionComponent<BlogPostsViewModel> = ({ componentText, editBlogLink }) => {
  return (
    <div>
      <div dangerouslySetInnerHTML={asEditable(componentText)} />
      <Link rel="nofollow" class="edit-blog" {...editBlogLink} />
    </div>
  );
};

const TravelPartContentBlog: FunctionComponent<TravelPartsViewModel> = props => {
  return props.travelParts && props.travelParts.length > 0 ? (
    <TravelPartContent {...props} className="blogcontent" />
  ) : (
    <EditBlogContent {...props.blogPostsViewModel} />
  );
};

export default TravelPartContentBlog;
