import { FunctionComponent } from 'react';
import { InclusiveViewModel } from '../../models/InclusiveViewModel';

const Inclusive: FunctionComponent<InclusiveViewModel> = ({
  inclusiveList,
  isInEditMode,
  editModeText,
}: InclusiveViewModel) => {
  const showEditModeHint = isInEditMode && inclusiveList && inclusiveList.length > 1;
  const classValue = showEditModeHint ? 'quote-edit-mode' : '';
  return (
    <div className="subinclusief subpage">
      {inclusiveList && inclusiveList.length > 0 && (
        <>
          {showEditModeHint && <h3 className="quote-edit-mode-text"> {editModeText} </h3>}
          {inclusiveList.map((componentText, key) => {
            return (
              <div
                className={`quote ${classValue}`}
                dangerouslySetInnerHTML={{
                  __html: componentText ? componentText : '',
                }}
                key={key}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default Inclusive;
