import { Component } from 'react';
import { Config } from 'react-player';
import YouTubePlayer from 'react-player/lib/players/YouTube';
import VimeoPlayer from 'react-player/lib/players/Vimeo';
import Preview from 'react-player/lib/Preview';
import cx from 'classnames';

const getVideoUrl = (videoId: string) => {
  // Vimeo
  if (/^\d+$/.test(videoId)) {
    return `https://vimeo.com/${videoId}`;
  }

  // Youtube.
  if (/^[a-zA-Z0-9\-_]{9,14}$/.test(videoId)) {
    return `https://www.youtube.com/watch?v=${videoId}`;
  }

  return videoId;
};

const getVideoConfig = (playing: boolean, interactive: boolean): Config => {
  return {
    vimeo: {
      playerOptions: {
        background: !interactive && playing,
      },
    },
    youtube: {
      playerVars: {
        controls: interactive ? 1 : 0,
        autohide: 1,
        showinfo: 0,
        rel: 0,
      },
    },
  };
};

const maxSizeStyling = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

const widgetStyling = {
  position: 'absolute',
  top: 0,
  left: 0,
};

interface VideoPlayerWrapperProps {
  videoId: string;
  setMaxSize?: boolean;
  playing?: boolean;
  isInEditMode?: boolean;
  onReady?: () => void;
  className?: string;
  light?: boolean;
  interactive: boolean;
}

interface VideoPlayerWrapperState {
  videoReady: boolean;
  videoStarted: boolean;
}

export class VideoPlayerWrapper extends Component<VideoPlayerWrapperProps, VideoPlayerWrapperState> {
  static defaultProps = {
    interactive: false,
  };

  private readyTimeout: number | undefined;
  private startedTimeout: number | undefined;

  constructor(props: VideoPlayerWrapperProps) {
    super(props);

    this.state = {
      videoReady: false,
      videoStarted: false,
    };
  }

  onVideoReady() {
    this.readyTimeout = window.setTimeout(
      () => {
        this.setState({
          videoReady: true,
        });
        this.readyTimeout = undefined;
      },
      400 // give vimeo some time to start the video before the class is set
    );
  }

  onVideoStarted() {
    this.startedTimeout = window.setTimeout(
      () => {
        this.setState({
          videoStarted: true,
        });
        this.startedTimeout = undefined;
      },
      500 // give vimeo some time to start the video before the class is set
    );
  }

  componentWillUnmount() {
    if (this.readyTimeout) {
      window.clearTimeout(this.readyTimeout);
    }
    if (this.startedTimeout) {
      window.clearTimeout(this.startedTimeout);
    }
  }

  render() {
    const { videoId, setMaxSize, playing, isInEditMode, className, interactive } = this.props;
    const { videoReady, videoStarted } = this.state;
    const url = getVideoUrl(videoId);
    const Player = url.indexOf('vimeo') === -1 ? YouTubePlayer : VimeoPlayer;

    if (playing === false) {
      return (
        <div className={cx('react-player', 'react-player--preview', className)}>
          <Preview url={url} />
        </div>
      );
    }

    return (
      <Player
        className={cx(
          'react-player',
          className,
          videoReady && 'react-player--video-loaded',
          videoStarted && 'react-player--video-started'
        )}
        url={url}
        style={setMaxSize ? maxSizeStyling : widgetStyling}
        config={getVideoConfig((!isInEditMode && playing) || false, interactive)}
        playing={!isInEditMode && playing}
        loop={!interactive}
        muted={!interactive}
        controls={interactive}
        width={setMaxSize ? '100%' : undefined}
        height={setMaxSize ? '100%' : undefined}
        onReady={() => this.onVideoReady()}
        onStart={() => this.onVideoStarted()}
      />
    );
  }
}
