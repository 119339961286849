import { FunctionComponent } from 'react';
import { SpotlightHeaderViewModel } from '../../models/SpotlightHeaderViewModel';
import SpotlightHeader from './SpotlightHeader';
import Text from '../atoms/text/Text';
import { asEditable } from '../../helpers/sitecore';

const SpotlightHeaderBasic: FunctionComponent<SpotlightHeaderViewModel> = props => (
  <SpotlightHeader {...props} className="basic">
    <div className="headercountryinner">
      <Text {...props.componentTitle} />
      <h2 dangerouslySetInnerHTML={asEditable(props.componentSubTitle)} />
      <p className="headercountryinner__text" dangerouslySetInnerHTML={asEditable(props.componentShortText)} />
    </div>
  </SpotlightHeader>
);

export default SpotlightHeaderBasic;
