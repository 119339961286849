import { FunctionComponent } from 'react';
import { FieldRenderProps } from 'react-final-form';
import FormItem from './atoms/FormItem';
import ValidationError from './atoms/ValidationError';

export interface TextFieldProps extends FieldRenderProps<string> {
  label?: string;
  className?: string;
  type?: string;
  required?: boolean;
  size?: number;
  placeholder?: string;
  autoFocus?: boolean;
}

const TextField: FunctionComponent<TextFieldProps> = ({ input, meta, ...rest }) => (
  <FormItem meta={meta} {...rest}>
    {rest.label && (
      <label htmlFor={input.name} className={rest.required ? 'required' : ''}>
        {rest.label}:
      </label>
    )}
    <div className="form-item-value">
      <input
        {...input}
        required={rest.required}
        type={input.type || 'text'}
        size={rest.size}
        placeholder={rest.placeholder}
        autoFocus={rest.autoFocus}
      />
    </div>
    <ValidationError meta={meta} />
  </FormItem>
);

export default TextField;
