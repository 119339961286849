import { Component } from 'react';
import { BlogEditViewModel } from '../../models/BlogEditViewModel';
import { logOff } from '../../helpers/form';

class BlogLogoff extends Component<BlogEditViewModel> {
  handleLogOff = () => logOff(this.props);

  render() {
    if (!this.props.blogLogOffViewModel) {
      return null;
    }

    return (
      <button className="cta_button" onClick={this.handleLogOff}>
        {this.props.blogLogOffViewModel.logoffButtonText}
      </button>
    );
  }
}

export default BlogLogoff;
