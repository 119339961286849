import Cookies from 'js-cookie';

export const getIsOpen = (id: string): boolean => {
  const cookieValue = Cookies.get('contacts');
  if (!cookieValue) {
    return true;
  }

  return !cookieValue.includes(`|${id}|`);
};

export const saveClosed = (id: string): void => {
  let cookieValue = Cookies.get('contacts');
  if (!cookieValue) {
    cookieValue = '|';
  }
  cookieValue = `${cookieValue}${id}|`;
  Cookies.set('contacts', cookieValue, { expires: 1 });
};
