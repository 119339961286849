import { FunctionComponent } from 'react';

export interface RoadTrippersLinkProps {
  roadTrippersCode: string;
  text?: string;
}

const RoadTrippersLink: FunctionComponent<RoadTrippersLinkProps> = ({ roadTrippersCode, text }) => (
  <a
    href={`https://maps.roadtrippers.com/?a2=t!${roadTrippersCode}`}
    className="roadtripperslink"
    target="_blank"
    rel="noopener noreferrer"
  >
    <svg width="54.67" height="66" viewBox="0 0 54.67 66" version="1.1">
      <path d="M35.113,52H2.001c-1.104,0-2-0.896-2-2s0.896-2,2-2h33.112c1.104,0,2,0.896,2,2S36.218,52,35.113,52z" />
      <path d="M21.661,9h-6.209c-1.104,0-2-0.896-2-2s0.896-2,2-2h6.209c1.104,0,2,0.896,2,2S22.766,9,21.661,9z" />
      <path d="M18.558,60.832c-2.245,0-4.071-1.824-4.071-4.065c0-2.242,1.826-4.066,4.071-4.066c2.243,0,4.068,1.824,4.068,4.066C22.626,59.008,20.801,60.832,18.558,60.832z" />
      <path d="M35.112,13H2c-1.104,0-2-0.896-2-2s0.896-2,2-2h33.112c1.104,0,2,0.896,2,2S36.217,13,35.112,13z" />
      <path d="M30.973,66H6.139c-3.385,0-6.138-2.752-6.138-6.135V6.134C0.001,2.751,2.754,0,6.139,0h24.834c3.386,0,6.141,2.751,6.141,6.134v9.479c0,1.104-0.896,2-2,2s-2-0.896-2-2V6.134c0-1.177-0.96-2.134-2.141-2.134H6.139C4.96,4,4.001,4.957,4.001,6.134v53.731C4.001,61.042,4.96,62,6.139,62h24.834c1.181,0,2.141-0.958,2.141-2.135v-13.61c0-1.104,0.896-2,2-2s2,0.896,2,2v13.61C37.113,63.248,34.358,66,30.973,66z" />
      <path d="M52.67,33H17.486c-1.104,0-2-0.896-2-2s0.896-2,2-2H52.67c1.104,0,2,0.896,2,2S53.774,33,52.67,33z" />
      <path d="M28.812,43.223c-0.452,0-0.907-0.152-1.281-0.465L15.205,32.464c-0.455-0.38-0.718-0.942-0.718-1.535s0.263-1.155,0.718-1.535l12.417-10.372c0.848-0.708,2.109-0.595,2.817,0.253s0.595,2.109-0.253,2.817l-10.579,8.836l10.488,8.759c0.848,0.708,0.961,1.97,0.253,2.817C29.953,42.979,29.385,43.223,28.812,43.223z" />
    </svg>
    {text || 'Take Trip'}
  </a>
);

export default RoadTrippersLink;
