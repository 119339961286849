import { Component, MouseEvent } from 'react';
import cx from 'classnames';
import Link from '../atoms/link/Link';
import { MenuItem } from '../../models/MenuItem';
import { BreakPoints } from '../../constants/breakPoints';
const withSizes = require('react-sizes');

interface DropdownMenuItemProps {
  menuItem: MenuItem;
  isScreenSm: boolean;
}

interface DropdownMenuItemState {
  open: boolean;
  collapseTimer?: number;
  // Timer to prevent click and hover to interfere on touch devices.
  preventEventsTimer?: number;
  preventEvents: boolean;
}

class DropdownMenuItem extends Component<DropdownMenuItemProps, DropdownMenuItemState> {
  constructor(props: DropdownMenuItemProps) {
    super(props);

    this.state = {
      open: false,
      preventEvents: false,
    };
  }

  openDropDown = () => {
    if (this.state.preventEvents) {
      return;
    }

    if (!this.props.isScreenSm) {
      const { collapseTimer } = this.state;
      if (collapseTimer) {
        window.clearTimeout(collapseTimer);
      }
      this.setState({
        open: true,
        collapseTimer: undefined,
        preventEvents: true,
        preventEventsTimer: window.setTimeout(this.enableEvents, 50),
      });
    }
  };

  closeDropdown = () => {
    if (!this.props.isScreenSm) {
      this.setState({
        collapseTimer: window.setTimeout(() => this.setState({ open: false, collapseTimer: undefined }), 500),
      });
    }
  };

  preventDropdownClose = () => {
    if (!this.props.isScreenSm) {
      const { collapseTimer } = this.state;
      if (collapseTimer) {
        window.clearTimeout(collapseTimer);
        this.setState({ collapseTimer: undefined });
      }
    }
  };

  toggleDropdown = () => {
    if (this.state.preventEvents) {
      return;
    }

    this.setState((state: DropdownMenuItemState) => ({
      open: !state.open,
      preventEvents: true,
      preventEventsTimer: window.setTimeout(this.enableEvents, 50),
      collapseTimer: undefined,
    }));
  };

  enableEvents = () => {
    this.setState(() => ({
      preventEvents: false,
      preventEventsTimer: undefined,
    }));
  };

  handleNavigationClick = (event: MouseEvent<HTMLAnchorElement>, menuItem: MenuItem) => {
    if (menuItem.children && menuItem.children.length > 0) {
      event.preventDefault();
    }
  };

  render() {
    const { menuItem } = this.props;
    const { open } = this.state;

    return (
      <ul onMouseLeave={this.closeDropdown} onMouseEnter={this.preventDropdownClose}>
        <li
          className={cx(open && 'menu-item-open', menuItem.children && menuItem.children.length > 0 && 'has-dropdown')}
          onClick={this.toggleDropdown}
        >
          <a
            href={menuItem.url && menuItem.url.url}
            className="main-menu-item"
            onClick={event => this.handleNavigationClick(event, menuItem)}
            onMouseEnter={this.openDropDown}
          >
            {menuItem.navigationTitle}
          </a>
        </li>
        {menuItem.children && menuItem.children.length > 0 && (
          <div className={`subnav-dropdown${open ? '--show' : ''}`}>
            <div className="subnav-menu">
              <ul>
                {menuItem.children.map(
                  listItem =>
                    listItem.url &&
                    listItem.navigationTitle && (
                      <li key={listItem.id}>
                        <Link {...listItem.url} text={listItem.navigationTitle} />
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>
        )}
      </ul>
    );
  }
}
const mapSizesToProps = ({ width }) => ({
  isScreenSm: width < BreakPoints.screenSm,
});

export default withSizes.withSizes(mapSizesToProps)(DropdownMenuItem);
